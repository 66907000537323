import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import moment from 'moment-timezone'
import { Howl } from 'howler'
import { useLocation } from 'react-router-dom'

import { useSite } from './SiteContext'
import { api } from 'services/api'

interface OrderContextData {
  period: {
    startDate: moment.Moment
    endDate: moment.Moment
  }
  setPeriod(period: { startDate: moment.Moment; endDate: moment.Moment }): void
  selectedDate: string
  setSelectedDate(date: string): void
}

const OrderContext = createContext({} as OrderContextData)

const orderSound = new Howl({
  src: ['/audio/orders.mp3'],
  volume: 1
})

const OrderProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { site } = useSite()

  const audioRef = useRef<HTMLAudioElement>(null)
  const [period, setPeriod] = useState({
    startDate: moment.tz('America/Bahia'),
    endDate: moment.tz('America/Bahia')
  })
  const [selectedDate, setSelectedDate] = useState('today')

  const handleSound = async () => {
    if (pathname.includes('dashboard')) {
      const { data } = await api('user-api').get(
        `orders/${site.id}/count/placed`,
        {
          headers: {
            startDate: period.startDate.format('YYYY-MM-DD'),
            endDate: period.endDate.tz('America/Bahia').format('YYYY-MM-DD')
          }
        }
      )

      if (data > 0) {
        orderSound.play()
      }
    }
  }

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(false)

    if (isLoading) {
      document.getElementById('audio').click()

      setInterval(() => {
        if (document.getElementById('audio')) {
          document.getElementById('audio').click()
        }
      }, 2000)
    }
  }, [isLoading])

  return (
    <OrderContext.Provider
      value={{
        period,
        setPeriod,
        selectedDate,
        setSelectedDate
      }}
    >
      <button id="audio" style={{ display: 'none' }} onClick={handleSound} />

      <audio src="/audio/orders.mp3" ref={audioRef} autoPlay muted loop />

      {children}
    </OrderContext.Provider>
  )
}

function useOrder(): OrderContextData {
  const context = useContext(OrderContext)

  if (!context) {
    throw new Error('useOrder must be used within a OrderContext')
  }

  return context
}

export { OrderContext, OrderProvider, useOrder }
