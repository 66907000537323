import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { BannerProps, SiteProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'
import { api } from 'services/api'

import BannerInput from './BannerInput'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: BannerProps | null): void
  banner?: BannerProps
  banners: BannerProps[]
  setBanners(banners: BannerProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const BannerModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  banner,
  banners,
  setBanners
}) => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const [bannerImage, setBannerImage] = useState(null)
  const [previewBanner, setPreviewBanner] = useState(null)
  const [available, setAvailable] = useState(true)

  const handleResetStats = useCallback(() => {
    reset()
    setAvailable(true)
    setPreviewBanner(null)
    setBannerImage(null)
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (banner) {
      setValue('name', banner.name, { shouldValidate: true })
      setBannerImage(banner.image)
      setPreviewBanner(banner.image)
      setAvailable(banner.available)
    }
  }, [setValue, banner])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleAvailableRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setAvailable(value === 'true')
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      let bannerImageUrl = typeof bannerImage === 'string' ? bannerImage : null

      if (bannerImage && typeof bannerImage !== 'string') {
        const data = new FormData()
        data.append('file', bannerImage)

        const response = await api('user-api').post('files/images', data)

        const { url } = response.data
        bannerImageUrl = url
      }

      const bannerData = {
        image: bannerImageUrl,
        name: data.name,
        available
      }

      const newBanners = [...banners]

      if (banner) {
        const bannerIndex = banners.findIndex((c) => c.id === banner.id)

        newBanners[bannerIndex] = {
          ...bannerData,
          id: banner.id
        } as BannerProps
      } else {
        newBanners.push({
          ...bannerData,
          id: uuidv4()
        } as BannerProps)
      }

      try {
        const { data: response } = await api('user-api').put<SiteProps>(
          `sites/${site.id}`,
          {
            banners: newBanners
          }
        )

        setBanners(response.banners)
        setSite({
          ...site,
          banners: response.banners
        })

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      bannerImage,
      available,
      banners,
      banner,
      site,
      setBanners,
      setSite,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo banner'}
          </h1>
          <p>Detalhes do banner</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Table>
            <S.Col>
              <BannerInput
                image={bannerImage}
                setImage={setBannerImage}
                previewImage={previewBanner}
                setPreviewImage={setPreviewBanner}
              />
            </S.Col>

            <S.Col>
              <S.Label htmlFor="name">Nome do banner</S.Label>
              <S.Input
                id="name"
                name="name"
                placeholder="Ex: Promoção do Dia das Mães"
                className={errors.name && 'is-invalid'}
                {...register('name')}
              />
            </S.Col>

            <S.Col>
              <S.Label>Disponibilidade</S.Label>

              <S.RadioGroup>
                <label>
                  <input
                    name="available"
                    type="radio"
                    value="true"
                    onChange={handleAvailableRadio}
                    defaultChecked={available}
                  />
                  <span>
                    <div>
                      <div>Disponível</div>
                      <div>O banner ficará visível no site</div>
                    </div>
                  </span>
                </label>
                <label>
                  <input
                    name="available"
                    type="radio"
                    value="false"
                    onChange={handleAvailableRadio}
                    defaultChecked={!available}
                  />
                  <span>
                    <div>
                      <div>Indisponível</div>
                      <div>O banner não ficará visível no site</div>
                    </div>
                  </span>
                </label>
              </S.RadioGroup>
            </S.Col>
          </S.Table>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                !bannerImage ||
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default BannerModal
