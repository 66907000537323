import React, { useCallback, useContext, useState, useRef } from 'react'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'
import readXlsxFile from 'read-excel-file'
import { save } from 'save-file'
import * as XLSX from 'xlsx'

import { AreaProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import AreaModal from './AreaModal'

import * as S from './styles'

const DeliveryAreas: React.FC = () => {
  const { showToast } = useToast()
  const { site, setSite } = useSite()
  const themeContext = useContext(ThemeContext)

  const [deliveryAreas, setDeliveryAreas] = useState<AreaProps[]>(() => {
    if (site.deliveryAreas.length > 0) {
      return site.deliveryAreas.sort((a, b) =>
        a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
      )
    }

    return [
      {
        id: uuidv4(),
        name: 'Área',
        deliveryFee: 5,
        available: true
      }
    ]
  })
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [areaSelected, setAreaSelected] = useState<AreaProps>(null)
  const ref = useRef()
  let time: NodeJS.Timeout = null

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleUpdateArea = useCallback(
    async (updatedAreas: AreaProps[]) => {
      try {
        clearTimeout(time)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = setTimeout(async () => {
          const { data } = await api('user-api').put(
            `sites/${site.id}/delivery-areas`,
            {
              deliveryAreas: updatedAreas.sort((a, b) =>
                a.name
                  .toLocaleUpperCase()
                  .localeCompare(b.name.toLocaleUpperCase())
              )
            }
          )

          setSite({
            ...site,
            deliveryAreas: updatedAreas
          })

          showToast({
            message: 'Áreas atualizadas com sucesso!',
            type: 'success'
          })
        }, 1000)
      } catch (err) {
        showToast({
          message: 'Aconteceu um erro!',
          type: 'error'
        })
      }
    },
    [setSite, showToast, site]
  )

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, area: AreaProps) => {
      setAreaSelected(area)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggleModal = useCallback((area: AreaProps = null) => {
    setAreaSelected(area)
    setIsOpen((prevState: boolean) => !prevState)
  }, [])

  const handleModalAreaEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleModal(areaSelected)
  }, [handleMenuOptionsClose, handleToggleModal, areaSelected])

  const handleAreaDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir a área "${areaSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const areaIndex = deliveryAreas.findIndex(
            (c) => c.id === areaSelected.id
          )

          const updatedAreas = [...deliveryAreas]
          const filteredAreas = updatedAreas.filter((_, i) => i !== areaIndex)

          handleUpdateArea(filteredAreas)
          setDeliveryAreas(filteredAreas)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    areaSelected,
    deliveryAreas,
    handleMenuOptionsClose,
    handleUpdateArea,
    showToast
  ])

  const handleChangeDeliveryFeeValue = useCallback(
    (
      e: React.FormEvent<HTMLInputElement>,
      maskedValue: number,
      area: AreaProps
    ) => {
      const updatedAreas = deliveryAreas.map((item) => {
        if (item.id === area.id) {
          return {
            ...item,
            deliveryFee: maskedValue
          }
        }

        return item
      })

      setDeliveryAreas(updatedAreas)
      handleUpdateArea(updatedAreas)
    },
    [deliveryAreas, handleUpdateArea]
  )

  const handleUpdateStatusArea = useCallback(
    ({ id }) => {
      const updatedAreas = deliveryAreas.map((item) => {
        if (item.id === id) {
          return { ...item, available: !item.available }
        }

        return item
      })

      setDeliveryAreas(updatedAreas)
      handleUpdateArea(updatedAreas)
    },
    [deliveryAreas, handleUpdateArea]
  )

  const handleUploadFileDeliveryAreas = useCallback(
    (e, ref) => {
      readXlsxFile(e.target.files[0]).then(
        (data) => {
          if (data.length > 0) {
            Swal.fire({
              title: 'O que deseja fazer?',
              icon: 'question',
              showCancelButton: true,
              reverseButtons: false,
              confirmButtonColor: '#50A773',
              cancelButtonColor: '#EA1D2C',
              confirmButtonText: 'Apagar todos e deixar somente os do arquivo',
              cancelButtonText: 'Não apagar e adicionar os do arquivo'
            }).then((result) => {
              const newDeliveryAreas = [] as AreaProps[]

              const capitalizeFirstLetter = (string: string) => {
                const separateWord = string.toLowerCase().split(' ')
                for (let i = 0; i < separateWord.length; i++) {
                  separateWord[i] =
                    separateWord[i].charAt(0).toUpperCase() +
                    separateWord[i].substring(1)
                }
                return separateWord.join(' ')
              }

              if (result.value) {
                data.forEach((area) => {
                  const name = area[0] as string
                  const deliveryFee = area[1] as string

                  if (area[0] === 'Nome' && area[1] === 'Preco') return

                  newDeliveryAreas.push({
                    id: uuidv4(),
                    name: capitalizeFirstLetter(name),
                    deliveryFee: parseFloat(deliveryFee),
                    available: true
                  })
                })
              } else {
                newDeliveryAreas.push(...deliveryAreas)

                data.forEach((area) => {
                  const name = area[0] as string
                  const deliveryFee = area[1] as string

                  if (area[0] === 'Nome' && area[1] === 'Preco') return

                  newDeliveryAreas.push({
                    id: uuidv4(),
                    name: capitalizeFirstLetter(name),
                    deliveryFee: parseFloat(deliveryFee),
                    available: true
                  })
                })
              }

              setDeliveryAreas(newDeliveryAreas)
              handleUpdateArea(newDeliveryAreas)
            })
          } else {
            showToast({
              type: 'error',
              message: 'Erro ao analisar o arquivo Excel.'
            })
          }
        },
        () => {
          showToast({
            type: 'error',
            message: 'Erro ao analisar o arquivo Excel.'
          })
        }
      )

      ref.current.value = ''
    },
    [deliveryAreas, handleUpdateArea, showToast]
  )

  const handleDownloadFileDeliveryAreas = async () => {
    const newDeliveryAreas = []

    deliveryAreas.forEach((area) => {
      newDeliveryAreas.push({
        Nome: area.name,
        Preco: area.deliveryFee
      })
    })

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

    const ws = XLSX.utils.json_to_sheet(
      newDeliveryAreas.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    )

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    await save(data, 'bairros.xlsx')
  }

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalAreaEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleAreaDelete}>Excluir</StyledMenuItem>
        {/* <StyledMenuItem
          onClick={handleCategoryCopy}
        >
          Duplicar
        </StyledMenuItem> */}
      </StyledMenu>

      <AreaModal
        isOpen={isOpen}
        area={areaSelected}
        areas={deliveryAreas}
        setDeliveryAreas={setDeliveryAreas}
        handleToggle={handleToggleModal}
        handleUpdateArea={handleUpdateArea}
      />

      <S.Container>
        <S.Title>Áreas de entrega</S.Title>

        <S.Options>
          <S.ButtonAdd type="button" onClick={() => handleToggleModal()}>
            <FiPlus /> Adicionar área
          </S.ButtonAdd>

          <S.ButtonUpload htmlFor="file">
            <span>Importar listas de bairro</span>

            <input
              type="file"
              id="file"
              accept=".xlsx, .xls, .csv"
              ref={ref}
              onChange={(e) => handleUploadFileDeliveryAreas(e, ref)}
            />
          </S.ButtonUpload>

          <S.ButtonDownload
            type="button"
            onClick={handleDownloadFileDeliveryAreas}
          >
            Exportar listas de bairro
          </S.ButtonDownload>
        </S.Options>

        <S.Areas>
          {deliveryAreas.map((item) => (
            <S.Area key={item.id}>
              <S.AreaHeader>
                <S.AreaHeaderLeft>
                  <label htmlFor={item.name}>{item.name}</label>
                </S.AreaHeaderLeft>

                <S.AreaHeaderRight>
                  <S.AreaStatus
                    onChange={() => handleUpdateStatusArea({ id: item.id })}
                    checked={item.available}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={60}
                    handleDiameter={12}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <button
                    type="button"
                    title="Opções"
                    onClick={(e) => handleMenuOptionsOpen(e, item)}
                  >
                    <FiMoreVertical />
                  </button>
                </S.AreaHeaderRight>
              </S.AreaHeader>

              <S.InputMoney
                id={item.name}
                money
                currency="BRL"
                config={{
                  locale: 'pt-BR',
                  formats: {
                    number: {
                      BRL: {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    }
                  }
                }}
                value={item.deliveryFee}
                onChange={(
                  e: React.FormEvent<HTMLInputElement>,
                  maskedValue: number
                ) => handleChangeDeliveryFeeValue(e, maskedValue, item)}
              />
            </S.Area>
          ))}
        </S.Areas>
      </S.Container>
    </>
  )
}

export default DeliveryAreas
