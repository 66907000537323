import React, { memo } from 'react'

import { ServiceProps } from 'utils/props'

import * as S from './styles'

interface Props {
  service: ServiceProps
}

const ServiceCard: React.FC<Props> = ({ service }) => {
  return (
    <S.Container>
      <img src="/comandante.png" alt="Site" width={30} />

      <div>
        <h1>Ir até o site {service.name}</h1>
      </div>
    </S.Container>
  )
}

export default memo(ServiceCard)
