import styled from 'styled-components'
import media from 'styled-media-query'
import { Modal } from 'react-responsive-modal'
import InputCurrency from 'react-intl-currency-input'
import ReactSwitch from 'react-switch'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Header = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }

  p {
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const Options = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  button {
    font-weight: 500;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.text.cards};

    padding-bottom: 0.5rem;
    border-bottom: 2px solid transparent;

    &.active {
      color: ${(props) => props.theme.colors.blue};
      border-bottom: 2px solid ${(props) => props.theme.colors.blue};
    }
  }
`

export const Form = styled.form`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Table = styled.div`
  display: grid;
  gap: 2rem;
  grid-template:
    'category category' 100px
    'desc img' auto
    'unit unit' auto
    'stock stock' auto;

  ${media.lessThan('medium')`
      gap: 0rem;
      grid-template:
        "item-id item-id" auto
        "img img" auto
        "category category" auto
        "desc desc" auto
        "unit unit" auto
        "stock stock" auto;
  `}
`

export const ColWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`

export const Label = styled.label`
  margin-bottom: 1rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Select = styled.select`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const TextArea = styled.textarea`
  resize: none;
  display: block;
  width: 100%;
  min-height: 16rem;
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Switch = styled(ReactSwitch)``

export const Buttons = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
`

interface ButtonProps {
  cancel?: boolean
}

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.theme.colors.text.cards};
  background-color: ${(props) =>
    props.cancel ? props.theme.colors.red : props.theme.colors.green};
  border-color: ${(props) => props.theme.colors.green};

  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  width: 12rem;

  font-weight: 600;
  text-align: center;

  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover:not(:disabled) {
    color: #f7fdff;
    opacity: 0.9;
  }
`

export const Price = styled.div`
  display: flex;
  gap: 2rem;
  align-items: flex-end;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
    }
  `}
`
