import React from 'react'
import { RouteComponentProps } from 'react-router'

import { useModule } from 'contexts/ModuleContext'

import * as S from './styles'

interface Props extends RouteComponentProps {}

const Modules: React.FC<Props> = ({ history }) => {
  const { module } = useModule()

  return (
    <S.Container>
      <S.Title>Módulos</S.Title>

      <S.Cards>
        <button
          type="button"
          onClick={() => history.push('/dashboard/modules/colors/')}
        >
          <S.Card>
            <img src="/img/icons/modules/colors.svg" alt="Colors" />

            <div>
              <h1>Cores</h1>
              <span>Personalize de acordo com as cores do seu sistema</span>
            </div>
          </S.Card>
        </button>

        <button
          type="button"
          onClick={() => history.push('/dashboard/modules/integrations/')}
        >
          <S.Card>
            <img src="/img/icons/modules/ifood.svg" alt="Integrations" />

            <div>
              <h1>Integrações</h1>
              <span>
                Permitem que parceiros utilizem diversos recursos da plataforma
                como gerenciar suas lojas e seus pedidos.
              </span>
            </div>
          </S.Card>
        </button>

        {/* <button
          type="button"
          onClick={() => history.push('/dashboard/modules/bot/')}
        >
          <S.Card>
            <img src="/img/icons/modules/bot.svg" alt="Bot" />

            <div>
              <h1>Bot Whatsapp</h1>
              <span>
                Envie mensagem para todos clientes que fizeram pedido em sua
                loja
              </span>
            </div>
          </S.Card>
        </button> */}

        <button
          type="button"
          onClick={() => history.push('/dashboard/modules/analytics/')}
        >
          <S.Card>
            <img src="/img/icons/modules/analytics.svg" alt="Analytics" />

            <div>
              <h1>Pixel de rastreamento</h1>
              <span>
                Monitore o comportamento dos seus consumidores e dos seus
                anúncios.
              </span>
            </div>
          </S.Card>
        </button>

        {/* <button
          type="button"
          onClick={() => history.push('/dashboard/modules/faithfulness/')}
        >
          <S.Card>
            <img src="/img/icons/modules/faithfulness.svg" alt="Fidelidade" />

            <div>
              <h1>Fidelidade</h1>
              <span>Sistema de fidelidade para seus clientes</span>
            </div>
          </S.Card>
        </button> */}

        <button
          type="button"
          onClick={() => history.push('/dashboard/modules/printers/')}
        >
          <S.Card>
            <img src="/img/icons/modules/printers.png" alt="Impressoras" />

            <div>
              <h1>Impressoras</h1>
              <span>
                Conecte suas impressoras térmicas com os sistema da Açaí
                Comandante
              </span>
            </div>
          </S.Card>
        </button>

        <button
          type="button"
          onClick={() => history.push('/dashboard/modules/whatsapp/')}
        >
          <S.Card>
            <img src="/img/icons/modules/whatsapp.svg" alt="Whatsapp" />

            <div>
              <h1>Whatsapp</h1>
              <span>
                Envie mensagens de texto para seus clientes assim que o status
                do pedido mudar
              </span>
            </div>
          </S.Card>
        </button>

        {module?.mercadopagoModule?.available && (
          <button
            type="button"
            onClick={() => history.push('/dashboard/modules/mercadopago/')}
          >
            <S.Card>
              <img
                src="/img/icons/modules/mercadopago.svg"
                alt="Mercado Pago"
              />

              <div>
                <h1>Mercado Pago</h1>
                <span>Utilize a forma de pagamento PIX em seu site.</span>
              </div>
            </S.Card>
          </button>
        )}

        {module?.picpayModule?.available && (
          <button
            type="button"
            onClick={() => history.push('/dashboard/modules/picpay/')}
          >
            <S.Card>
              <img src="/img/icons/modules/picpay.svg" alt="PicPay" />

              <div>
                <h1>PicPay</h1>
                <span>Utilize a forma de pagamento PicPay em seu site.</span>
              </div>
            </S.Card>
          </button>
        )}

        {module?.pagarmeModule?.available && (
          <button
            type="button"
            onClick={() => history.push('/dashboard/modules/pagarme/')}
          >
            <S.Card>
              <img src="/img/icons/modules/pagarme.svg" alt="Pagarme" />

              <div>
                <h1>Pagarme</h1>
                <span>
                  Utilize a forma de pagamento Cartão de Crédito em seu site.
                </span>
              </div>
            </S.Card>
          </button>
        )}
      </S.Cards>
    </S.Container>
  )
}

export default Modules
