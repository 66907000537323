import React, { createContext, useState, useContext } from 'react'

import { TotemSelfServiceProps } from 'utils/props'

interface TotemProviderProps {
  totemItem?: TotemSelfServiceProps
  children: React.ReactNode
}

interface TotemContextData {
  totem: TotemSelfServiceProps | null
  setTotem(totem: TotemSelfServiceProps): void
}

const TotemContext = createContext({} as TotemContextData)

const TotemProvider: React.FC<TotemProviderProps> = ({
  totemItem,
  children
}) => {
  const [totem, setTotem] = useState(totemItem || null)

  return (
    <TotemContext.Provider
      value={{
        totem,
        setTotem
      }}
    >
      {children}
    </TotemContext.Provider>
  )
}

function useTotem(): TotemContextData {
  const context = useContext(TotemContext)

  if (!context) {
    throw new Error('useTotem must be used within a TotemContext')
  }

  return context
}

export { TotemContext, TotemProvider, useTotem }
