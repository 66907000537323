import { api } from 'services/api'
import { IFoodModuleProps } from 'utils/props'

interface PollingProps {
  module: string
  siteid: string
}

const getUserCode = async (): Promise<IFoodModuleProps> => {
  const { data } = await api('user-api').post<IFoodModuleProps>(
    'services/integrations/ifood/userCode'
  )

  return data
}

const getToken = async (
  body: IFoodModuleProps,
  module: string
): Promise<IFoodModuleProps> => {
  const headers = {
    headers: {
      module
    }
  }

  const { data } = await api('user-api').post<IFoodModuleProps>(
    'services/integrations/ifood/token',
    body,
    headers
  )

  return data
}

const polling = async ({ module, siteid }: PollingProps): Promise<any[]> => {
  const headers = {
    headers: {
      module,
      siteid
    }
  }

  const { data } = await api('user-api').get(
    'services/integrations/ifood/polling',
    headers
  )

  return data
}

const open = async (module: string): Promise<IFoodModuleProps> => {
  const headers = {
    headers: {
      module
    }
  }

  const { data } = await api('user-api').post<IFoodModuleProps>(
    'services/integrations/ifood/open',
    null,
    headers
  )

  return data
}

const close = async (module: string): Promise<IFoodModuleProps> => {
  const headers = {
    headers: {
      module
    }
  }

  const { data } = await api('user-api').post<IFoodModuleProps>(
    'services/integrations/ifood/close',
    null,
    headers
  )

  return data
}

const disconnect = async (module: string): Promise<IFoodModuleProps> => {
  const headers = {
    headers: {
      module
    }
  }

  const { data } = await api('user-api').post<IFoodModuleProps>(
    'services/integrations/ifood/disconnect',
    null,
    headers
  )

  return data
}

export { getUserCode, getToken, polling, open, close, disconnect }
