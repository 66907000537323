import styled, { css } from 'styled-components'

export const Container = styled.main`
  ${({ theme }) => css`
    display: flex;
    gap: 1rem;
    flex-direction: column;

    min-width: 35rem;

    padding: 2rem;

    background: ${theme.name === 'dark'
      ? theme.colors.primary
      : theme.colors.secondary};
    box-shadow: 0 6px 18px rgb(0 0 0 / 6%);
    border-radius: 0.5rem;

    .chart-tooltip-title {
      display: flex;
      justify-content: center;
      align-items: center;

      color: ${theme.colors.text.cards};
    }

    .chart-tooltip-content {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }

    .chart-tooltip {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        gap: 1rem;
      }

      span {
        color: ${theme.colors.text.cards};

        &.text-green {
          color: ${theme.colors.green};
        }

        &.text-red {
          color: ${theme.colors.red};
        }
      }
    }

    .chart-tooltip-color {
      width: 20px;
      height: 20px;

      border-radius: 50%;
    }
  `}
`
