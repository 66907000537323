import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  button + button {
    margin-top: 1rem;
  }
`

export const Card = styled.button`
  position: relative;
  display: flex;
  gap: 2.5rem;
  align-items: center;

  min-width: 0;
  word-wrap: break-word;
  background-color: #1b242f;
  background-clip: initial;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 1rem;

  box-shadow: 0 6px 18px rgb(0 0 0 / 6%);
  transition: all 0.15s ease;

  position: relative;
  width: 100%;
  height: 10rem;

  padding: 1rem 2rem;

  ${media.lessThan('medium')`
    width: auto;
    padding: 2rem;
  `}

  &.create-category-card:hover,
  &.selected-category-card {
    border: 2px solid #2d81d3;
  }

  > img {
    width: 5rem;
    height: 5rem;
  }

  > div {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;

    ${({ theme }) => css`
      h1 {
        font-weight: bold;
        font-size: 1.8rem;
        text-align: left;

        color: ${theme.colors.text.cards};
      }

      p {
        font-size: 1.5rem;
        text-align: left;

        color: ${theme.colors.text.cards};
      }
    `}
  }

  > svg {
    position: absolute;
    color: #2d81d3;
    right: 12px;
    top: 12px;
  }
`
