import React, { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'rc-drawer/assets/index.css'
import { ArrowsLeftRight } from 'phosphor-react'

import { useSite } from 'contexts/SiteContext'
import { useAuth } from 'contexts/UserContext'
import routes from 'routes/routes'
import { logout } from 'services/auth'

import * as S from './styles'

interface Props {
  logo?: string
  title?: string

  layout: string
  isSidebarOpen: boolean
  handleCloseSidebar(): void
}

const SidebarMobile: React.FC<Props> = ({
  logo = null,
  title = null,

  layout,
  isSidebarOpen = false,
  handleCloseSidebar
}) => {
  const { site } = useSite()
  const { user } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const handleOnSelect = useCallback(
    (selected) => {
      const to = `/${layout}${selected}`

      if (location.pathname !== to) {
        history.push(to)
      }

      handleCloseSidebar()
    },
    [handleCloseSidebar, history, layout, location?.pathname]
  )

  const handleNavigateToServices = () => history.push('/services/')

  const handleLogout = () => {
    logout()
    history.push('/auth/login/')
  }

  const navItems = useMemo(() => {
    const navRoutes = routes[layout].map((paths) => {
      return paths.map((prop) => {
        if (typeof prop.icon === 'string') {
          return (
            <S.Item
              key={prop.path}
              title={prop.name}
              active={prop.mobileActive.includes(location.pathname)}
              onClick={() => handleOnSelect(prop.path)}
            >
              <img src={prop.icon} alt={prop.name} />
              <span>{prop.name}</span>
            </S.Item>
          )
        }

        const Icon = prop.icon

        return (
          <S.Item
            key={prop.path}
            title={prop.name}
            active={prop.mobileActive.includes(location.pathname)}
            onClick={() => handleOnSelect(prop.path)}
          >
            <Icon />
            <span>{prop.name}</span>
          </S.Item>
        )
      })
    })

    return navRoutes
  }, [handleOnSelect, layout, location?.pathname])

  return (
    <S.Drawer
      level={null}
      open={isSidebarOpen}
      handler={false}
      onClose={handleCloseSidebar}
      width="290px"
    >
      <S.Container>
        <S.Info>
          <img src={logo || '/favicon.png'} alt={title} />

          {title && <h1>{title}</h1>}
        </S.Info>
        <S.List>
          {!location.pathname.includes('/services') && user.user && (
            <li>
              <S.Item title="Serviços" onClick={handleNavigateToServices}>
                <ArrowsLeftRight size={24} />
                <span> Voltar para os serviços</span>
              </S.Item>
            </li>
          )}

          {navItems.map((paths, index) => (
            <li key={`paths_${index}`}>{paths.map((path) => path)}</li>
          ))}

          <li>
            <S.Item title="Sair" onClick={handleLogout}>
              <img src="/img/icons/sidebar/person.svg" alt="Sair" />
              <span>Sair</span>
            </S.Item>
          </li>
        </S.List>
      </S.Container>
    </S.Drawer>
  )
}

export default SidebarMobile
