import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { OrderProps } from 'utils/props'
import { api } from 'services/api'
import { formatPrice } from 'utils/utils'
import { useSite } from 'contexts/SiteContext'
import errorHandler from 'services/errorHandler'
import { socket } from 'services/socket'
import { useOrder } from 'contexts/OrderContext'

import Spinner from 'components/Spinner'
import OrdersTable from 'components/OrdersTable'
import DatePicker from 'components/DatePicker'

import * as S from './styles'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const Orders: React.FC = () => {
  const { site } = useSite()
  const { period, setPeriod, selectedDate, setSelectedDate } = useOrder()
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState<OrderProps[]>([])
  const [visibilityValues, setVisibilityValues] = useState(false)
  const total = useMemo(
    () =>
      orders
        .filter(
          (order) =>
            order.orderStatus !== 'PLACED' && order.orderStatus !== 'CANCELLED'
        )
        .reduce((totalAmount, i) => totalAmount + i.total.orderAmount, 0),
    [orders]
  )

  const onDate = useCallback(
    ({ startDate, endDate }) => {
      if (!startDate || !endDate) return

      if (!startDate.isValid() || !endDate.isValid()) return

      setPeriod({ startDate, endDate })
    },
    [setPeriod]
  )

  const loadOrders = useCallback(async () => {
    try {
      const { data } = await api('user-api').get<OrderProps[]>('orders', {
        headers: {
          siteId: site.id,
          startDate: period.startDate.format('YYYY-MM-DD'),
          endDate: period.endDate.tz('America/Bahia').format('YYYY-MM-DD')
        }
      })

      const newData = data.map((order, index, array) => ({
        ...order,
        index: array.length - index
      }))

      setOrders(newData)
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsLoading(false)
    }
  }, [site, period])

  const handleVisibilityValues = () => {
    setVisibilityValues((prevState) => !prevState)
  }

  useEffect(() => {
    loadOrders()
  }, [loadOrders])

  useEffect(() => {
    socket.on('newOrder', (data: OrderProps) => {
      if (data.siteId === site.id) {
        setOrders((prevState) => [
          {
            ...data,
            index: prevState.length + 1
          },
          ...prevState
        ])
      }
    })

    // socket.on('orderChange', (data: OrderProps) => {
    //   if (data.siteId === site.id) {
    //     setOrders((prevState) =>
    //       prevState.map((order) => {
    //         if (order.id === data.id) {
    //           return data
    //         }

    //         return order
    //       })
    //     )
    //   }
    // })
  }, [site.id])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <S.Container>
      <S.DatePicker>
        <S.TopCards>
          <S.Card>
            <span>Pedidos</span>

            <strong>
              {visibilityValues
                ? orders.filter((order) => order.orderStatus !== 'CANCELLED')
                    .length
                : '*'}
            </strong>
          </S.Card>

          <S.Card>
            <span>Cancelados</span>

            <strong className="red">
              {visibilityValues
                ? orders.filter((order) => order.orderStatus === 'CANCELLED')
                    .length
                : '*'}
            </strong>
          </S.Card>

          <S.Card>
            <span>Total</span>

            <strong>{visibilityValues ? formatPrice(total) : '*'}</strong>
          </S.Card>

          <S.Card>
            <span>Ticket médio</span>

            <strong className="blue">
              {visibilityValues
                ? formatPrice(
                    total /
                      (orders.filter(
                        (order) => order.orderStatus !== 'CANCELLED'
                      ).length > 0
                        ? orders.filter(
                            (order) => order.orderStatus !== 'CANCELLED'
                          ).length
                        : 1)
                  )
                : '*'}
            </strong>
          </S.Card>

          <S.Card className="top-showValues">
            <span>Exibir valores</span>
            <button
              type="button"
              title={visibilityValues ? 'Esconder valores' : 'Mostrar valores'}
              onClick={handleVisibilityValues}
            >
              {visibilityValues ? <FaEyeSlash /> : <FaEye />}
            </button>
          </S.Card>
        </S.TopCards>

        <S.Card style={{ display: 'none' }} className="showValues">
          <span>Exibir valores</span>
          <button
            type="button"
            title={visibilityValues ? 'Esconder valores' : 'Mostrar valores'}
            onClick={handleVisibilityValues}
          >
            {visibilityValues ? <FaEyeSlash /> : <FaEye />}
          </button>
        </S.Card>

        <DatePicker
          startDate={period.startDate}
          endDate={period.endDate}
          onDate={onDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </S.DatePicker>

      <OrdersTable orders={orders} setOrders={setOrders} />
    </S.Container>
  )
}

export default Orders
