import React, { createContext, useContext, useState } from 'react'

import { SiteProps } from 'utils/props'

interface SiteProviderProps {
  siteItem?: SiteProps
  children: React.ReactNode
}

interface SiteContextData {
  site: SiteProps | null
  setSite(site: SiteProps): void
}

const SiteContext = createContext({} as SiteContextData)

const SiteProvider: React.FC<SiteProviderProps> = ({ siteItem, children }) => {
  const [site, setSite] = useState(siteItem || null)

  return (
    <SiteContext.Provider
      value={{
        site,
        setSite
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

function useSite(): SiteContextData {
  const context = useContext(SiteContext)

  if (!context) {
    throw new Error('useSite must be used within a SiteContext')
  }

  return context
}

export { SiteContext, SiteProvider, useSite }
