import React, { useRef, useCallback, useState } from 'react'
import moment from 'moment-timezone'

import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useBot } from 'contexts/BotContext'

import Spinner from 'components/Spinner'
import BotStatus from './BotStatus'
import DatePicker from 'components/DatePicker'
import SendMessage from './SendMessage'

import * as S from './styles'

const BotModule: React.FC = () => {
  const { site } = useSite()
  const { isConnected, handleCloseTheBot } = useBot()
  const [period, setPeriod] = useState({
    startDate: moment(),
    endDate: moment()
  })
  const [selectedDate, setSelectedDate] = useState('today')
  const [selectedOption, setSelectedOption] = useState('send')

  const numberRef = useRef(null)
  const textRef = useRef(null)

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return

    if (!startDate.isValid() || !endDate.isValid()) return

    setPeriod({ startDate, endDate })
  }, [])

  if (!isConnected) {
    return <BotStatus />
  }

  return (
    <S.Container>
      <S.DatePicker>
        <DatePicker
          startDate={period.startDate}
          endDate={period.endDate}
          onDate={onDate}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />

        <S.ButtonClose type="button" onClick={handleCloseTheBot}>
          Desligar bot
        </S.ButtonClose>
      </S.DatePicker>

      {/* <S.Options>
        <button
          type="button"
          className={`${selectedOption === 'send' ? 'selected' : undefined}`}
        >
          Enviar mensagem
        </button>

        <button
          type="button"
          className={`predefined-dates ${
            selectedOption === 'settings' ? 'selected' : undefined
          }`}
        >
          Configurar mensagens
        </button>
      </S.Options> */}

      {selectedOption === 'send' && <SendMessage />}
    </S.Container>
  )
}

export default BotModule
