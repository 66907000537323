import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { CouponProps, MenuProps } from 'utils/props'
import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'

import InputComponent from 'components/Input'
import Select from 'components/Select'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: CouponProps | null): void
  coupon: CouponProps
  coupons: CouponProps[]
  setCoupons(coupon: CouponProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const StockModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  coupon,
  coupons,
  setCoupons
}) => {
  const { site } = useSite()
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')

  const [categories, setCategories] = useState({})
  const [categoriesSelectList, setCategoriesSelectList] = useState([])
  const [categoriesDisabled, setCategoriesDisabled] = useState([])
  const couponTypes = [
    {
      label: 'Porcentagem',
      value: 'PERCENTAGE'
    },
    {
      label: 'Valor fixo',
      value: 'MONEY'
    }
  ]

  const [couponType, setCouponType] = useState('PERCENTAGE')
  const [discountValue, setDiscountValue] = useState(0)
  const [minimumValue, setMinimumValue] = useState(0)
  const [coupondisabledCategories, setCouponDisabledCategories] = useState({})
  const [firstUseCoupon, setFirstUseCoupon] = useState(false)
  const [active, setActive] = useState(true)

  const loadMenusData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data: deliveryMenuId } = await api('user-api').get<MenuProps>(
        `menus/${site.deliveryMenuId}`
      )

      const { data: takeoutMenuId } = await api('user-api').get<MenuProps>(
        `menus/${site.takeoutMenuId}`
      )

      const { data: indoorMenuId } = await api('user-api').get<MenuProps>(
        `menus/${site.indoorMenuId}`
      )

      const listCategories = { deliveryMenuId, takeoutMenuId, indoorMenuId }

      setCategories(listCategories)
      setCategoriesSelectList(
        Object.values(listCategories).map((item: MenuProps, index) => {
          const categoryMenu =
            item.menuId === site.deliveryMenuId
              ? 'Entrega'
              : item.menuId === site.takeoutMenuId
              ? 'Retirada'
              : 'Mesa'

          return {
            label: categoryMenu,
            options: item.categories.map((category) => ({
              label: `${categoryMenu} | ${category.name}`,
              value: {
                id: category.id,
                categoryId: Object.keys(listCategories)[index]
              }
            }))
          }
        })
      )
    } finally {
      setIsLoading(false)
    }
  }, [site])

  useEffect(() => {
    loadMenusData()
  }, [loadMenusData])

  const handleResetStats = useCallback(() => {
    reset()
    setActive(true)
    setFirstUseCoupon(false)
    setCouponDisabledCategories({})
    setCategoriesDisabled([])
    setMinimumValue(0)
    setDiscountValue(0)
    setCouponType('PERCENTAGE')
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (coupon) {
      setValue('name', coupon.name.toLocaleUpperCase(), {
        shouldValidate: true
      })
      setCouponType(coupon.couponType)
      setDiscountValue(coupon.discountValue)
      setCouponDisabledCategories(coupon.disabledCategories)
      setMinimumValue(coupon.minimumValue)
      setFirstUseCoupon(coupon.firstUseCoupon)

      setActive(coupon.active)
    }
  }, [setValue, coupon])

  const loadDisabledCategoriesData = useCallback(() => {
    if (coupon && Object.values(categories).length > 0) {
      const listSelectedCategories = []

      Object.keys(coupon.disabledCategories).forEach((key) =>
        Object.values(coupon.disabledCategories[key]).forEach((item) => {
          const category = categories[key].categories.find((c) => c.id === item)
          const categoryMenu =
            categories[key].menuId === site.deliveryMenuId
              ? 'Entrega'
              : categories[key].menuId === site.takeoutMenuId
              ? 'Retirada'
              : 'Mesa'
          const categoryName = category.name

          if (category) {
            listSelectedCategories.push({
              label: `${categoryMenu} | ${categoryName}`,
              value: {
                id: item,
                categoryId: key
              }
            })
          }
        })
      )

      setCategoriesDisabled(listSelectedCategories)
    }
  }, [coupon, categories, site])

  useEffect(() => {
    loadDisabledCategoriesData()
  }, [loadDisabledCategoriesData])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleChangeSelectedCouponType = useCallback((value: string) => {
    setCouponType(value)
  }, [])

  const handleChangeDiscountValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = parseFloat(e.target.value)

      if (val < 0) {
        setDiscountValue(0)
        return
      } else if (val > 100) {
        setDiscountValue(100)
        return
      }

      setDiscountValue(val)
    },
    []
  )

  const handleChangeSelectedCategories = useCallback(
    (
      values: {
        label: string
        value: {
          id: string
          categoryId: string
        }
      }[]
    ) => {
      const categories = values.reduce((prevValue, item) => {
        prevValue[item.value.categoryId] =
          prevValue[item.value.categoryId] || []
        prevValue[item.value.categoryId].push(item.value.id)

        return prevValue
      }, {})

      setCouponDisabledCategories(categories)
    },
    []
  )

  const handleActiveFirstUseCoupon = useCallback(() => {
    setFirstUseCoupon((prevState) => !prevState)
  }, [])

  const handleActiveRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setActive(value === 'true')
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const couponData = {
        ...data,
        name: data.name.toLocaleUpperCase(),
        couponType,
        discountValue,
        minimumValue,
        disabledCategories: coupondisabledCategories,
        firstUseCoupon,
        active,
        serviceId: site.service.id
      }

      const newCoupons = [...coupons]

      if (coupon) {
        const response = await api('user-api').put<CouponProps>(
          `coupons/${coupon.id}`,
          couponData
        )

        const couponIndex = coupons.findIndex((c) => c.id === coupon.id)

        newCoupons[couponIndex] = {
          ...couponData,
          id: coupon.id
        } as CouponProps
      } else {
        const { data: response } = await api('user-api').post<CouponProps>(
          'coupons',
          couponData
        )

        newCoupons.push({
          ...couponData,
          id: response.id
        } as CouponProps)
      }

      try {
        setCoupons(newCoupons)

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      couponType,
      discountValue,
      minimumValue,
      coupondisabledCategories,
      firstUseCoupon,
      active,
      site,
      coupons,
      coupon,
      setCoupons,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo cupom'}
          </h1>
          <p>Detalhes do cupom</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="name">Nome do cupom</S.Label>

            <S.Input
              id="name"
              name="name"
              placeholder="Ex: PROMOCAO"
              className={errors.name && 'is-invalid'}
              {...register('name')}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="couponType">Tipo do cupom</S.Label>

            <Select
              id="couponType"
              placeholder="Categorias"
              options={couponTypes}
              defaultValue={{
                label: couponTypes.find((item) => item.value === couponType)
                  .label,
                value: couponTypes.find((item) => item.value === couponType)
                  .value
              }}
              onChange={({ value }) => handleChangeSelectedCouponType(value)}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="discountValue">
              Valor de desconto {couponType === 'PERCENTAGE' ? '%' : 'R$'}
            </S.Label>

            {couponType === 'PERCENTAGE' ? (
              <S.Input
                id="discountValue"
                name="discountValue"
                type="number"
                min="0"
                max="100"
                placeholder="20%"
                className={
                  (errors.name ||
                    !discountValue ||
                    discountValue < 0 ||
                    discountValue > 100) &&
                  'is-invalid'
                }
                value={discountValue}
                onChange={(e) => handleChangeDiscountValue(e)}
              />
            ) : (
              <S.InputMoney
                id="discountValue"
                name="discountValue"
                money
                currency="BRL"
                config={{
                  locale: 'pt-BR',
                  formats: {
                    number: {
                      BRL: {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    }
                  }
                }}
                placeholder="R$ 00,00"
                className={errors.name && 'is-invalid'}
                value={discountValue}
                onChange={(_, maskedValue: number) =>
                  setDiscountValue(maskedValue)
                }
              />
            )}
          </S.Col>

          <S.Col>
            <S.Label htmlFor="minimumValue">Valor mínimo</S.Label>

            <S.InputMoney
              id="minimumValue"
              name="minimumValue"
              money
              currency="BRL"
              config={{
                locale: 'pt-BR',
                formats: {
                  number: {
                    BRL: {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                }
              }}
              placeholder="R$ 00,00"
              className={errors.name && 'is-invalid'}
              value={minimumValue}
              onChange={(_, maskedValue: number) =>
                setMinimumValue(maskedValue)
              }
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="firstUseCoupon">Para o primeiro uso</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  id="firstUseCoupon"
                  name="firstUseCoupon"
                  type="checkbox"
                  onChange={handleActiveFirstUseCoupon}
                  defaultChecked={firstUseCoupon}
                />
                <span>
                  <div>
                    <div>
                      O cupom estará disponível apenas na primeira compra
                    </div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Col>
            <S.Label htmlFor="disabledCategories">
              Categorias desativadas
            </S.Label>

            <Select
              id="disabledCategories"
              placeholder="Categorias"
              options={categoriesSelectList}
              defaultValue={categoriesDisabled}
              isMultiSelect
              inputProps={{ readOnly: true }}
              isSearchable={false}
              // isOptionDisabled={(option) => {
              //   const optionExists = categoriesDisabled.some(
              //     (c) => c.value.id === option.value.id
              //   )

              //   return optionExists
              // }}
              onChange={(
                values: {
                  label: string
                  value: {
                    id: string
                    categoryId: string
                  }
                }[]
              ) => handleChangeSelectedCategories(values)}
            />
          </S.Col>

          <S.Col>
            <S.Label>Disponibilidade</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  name="active"
                  type="radio"
                  value="true"
                  onChange={handleActiveRadio}
                  defaultChecked={active}
                />
                <span>
                  <div>
                    <div>Disponível</div>
                    <div>O cupom estará disponível para ser usado</div>
                  </div>
                </span>
              </label>
              <label>
                <input
                  name="active"
                  type="radio"
                  value="false"
                  onChange={handleActiveRadio}
                  defaultChecked={!active}
                />
                <span>
                  <div>
                    <div>Indisponível</div>
                    <div>O cupom estará indisponível para ser usado</div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0 ||
                !discountValue ||
                discountValue < 0 ||
                discountValue > 100
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default StockModal
