import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FiTrash2 } from 'react-icons/fi'
import { v4 as uuidv4 } from 'uuid'

import { StockProps } from 'utils/props'
import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useStock } from 'contexts/StockContext'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'
import { taxableUnits } from 'utils/taxableUnits'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: StockProps | null): void
  stock: StockProps
}

interface FormDataProps {
  code: string
  name: string

  quantityInStockUN: number
  minimumQuantityInStockUN: number
  quantityInStockCX: number
  minimumQuantityInStockCX: number
  quantityInStockKG: number
  minimumQuantityInStockKG: number
}

const StockModal: React.FC<Props> = ({ isOpen, handleToggle, stock }) => {
  const { site } = useSite()
  const { showToast } = useToast()
  const { stocks, setStocks } = useStock()
  const [width, height] = useWindowSize()

  const schema = yup.object().shape({
    code: yup.string().required('Este campo é obrigatório'),
    name: yup.string().required('Este campo é obrigatório'),

    quantityInStockUN: stock
      ? yup.number()
      : yup.number().required('Este campo é obrigatório'),
    minimumQuantityInStockUN: yup.number().required('Este campo é obrigatório'),
    quantityInStockCX: stock
      ? yup.number()
      : yup.number().required('Este campo é obrigatório'),
    minimumQuantityInStockCX: yup.number().required('Este campo é obrigatório')
  })

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const codeValue = watch('code')
  const previewName = watch('name')
  const [isConvertStockModalOpen, setIsConvertStockModalOpen] = useState(false)
  const [methodsToAdd, setMethodsToAdd] = useState([
    {
      id: uuidv4(),
      type: 'CX',
      quantity: 1,
      quantityToConvert: {
        type: 'UN',
        amountToAdd: 1
      }
    }
  ])
  const [available, setAvailable] = useState(true)

  const [stockSelected, setStockSelected] = useState(null)
  const [methodToAddSelected, setMethodToAddSelected] = useState<{
    id: string
    type: string
    quantity: number
    quantityToConvert: {
      type: string
      amountToAdd: number
    }
  }>(null)

  const handleResetStats = useCallback(() => {
    reset()
    setAvailable(true)
    setMethodsToAdd([
      {
        id: uuidv4(),
        type: 'CX',
        quantity: 1,
        quantityToConvert: {
          type: 'UN',
          amountToAdd: 1
        }
      }
    ])
    setStockSelected(null)
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (stock && isOpen) {
      setValue('name', stock.name, { shouldValidate: true })
      setValue('code', stock.code.toLocaleUpperCase(), { shouldValidate: true })

      taxableUnits.forEach((taxableUnit) => {
        setValue(
          taxableUnit.quantityInStock,
          stock[taxableUnit.quantityInStock],
          {
            shouldValidate: true
          }
        )
        setValue(
          taxableUnit.minimumQuantityInStock,
          stock[taxableUnit.minimumQuantityInStock],
          {
            shouldValidate: true
          }
        )
      })
      setMethodsToAdd(stock.methodsToAdd)
      setAvailable(stock.available)
    }
  }, [isOpen, setValue, stock])

  useEffect(() => {
    if (stock && isOpen) {
      const updatedStock = stocks.find((s) => s.code === stock.code)
      if (!updatedStock) return

      setStockSelected(updatedStock)

      taxableUnits.forEach((taxableUnit) => {
        setValue(
          taxableUnit.quantityInStock,
          updatedStock[taxableUnit.quantityInStock],
          {
            shouldValidate: true
          }
        )
        setValue(
          taxableUnit.minimumQuantityInStock,
          updatedStock[taxableUnit.minimumQuantityInStock],
          {
            shouldValidate: true
          }
        )
      })
    }
  }, [isOpen, setValue, stock, stocks])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleChangeCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toLocaleUpperCase()

      setValue('code', value, { shouldValidate: true })
    },
    [setValue]
  )

  const handleKeyPressCode = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const char = e.key
      const pattern = '[a-zA-Z0-9]'

      if (!char.match(pattern)) e.preventDefault()
    },
    []
  )

  const handleRemoveMethods = useCallback((id: string) => {
    setMethodsToAdd((prevState) =>
      prevState.filter((method) => method.id !== id)
    )
  }, [])

  const handleAddMethods = useCallback(() => {
    setMethodsToAdd((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        type: 'CX',
        quantity: 1,
        quantityToConvert: {
          type: 'UN',
          amountToAdd: 1
        }
      }
    ])
  }, [])

  const handleChangeMethodType = useCallback((id: string, value: string) => {
    setMethodsToAdd((prevState) =>
      prevState.map((method) => {
        if (method.id === id) {
          return { ...method, type: value }
        }

        return method
      })
    )
  }, [])

  const handleChangeMethodQuantityToConvert = useCallback(
    (id: string, key: string, value: string) => {
      setMethodsToAdd((prevState) =>
        prevState.map((method) => {
          if (method.id === id) {
            return {
              ...method,
              quantity:
                key === 'amountToRemove' ? parseFloat(value) : method.quantity,
              quantityToConvert: {
                ...method.quantityToConvert,
                [key]: key === 'amountToAdd' ? parseFloat(value) : value
              }
            }
          }

          return method
        })
      )
    },
    []
  )

  const handleAvailableRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setAvailable(value === 'true')
    },
    []
  )

  const handleToggleConvertStockModal = useCallback((methodToAdd = null) => {
    setMethodToAddSelected(methodToAdd)
    setIsConvertStockModalOpen((prevState: boolean) => !prevState)
  }, [])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const stockData = {
        ...data,
        methodsToAdd: methodsToAdd.map((method) => {
          return { id: method.id ? method.id : uuidv4(), ...method }
        }),
        available
      }

      const newStocks = [...stocks]

      if (stock) {
        const response = await api('user-api').put<StockProps>(
          `stocks/${stock.id}`,
          {
            ...stockData,
            serviceId: site.serviceId
          }
        )

        const stockIndex = stocks.findIndex((c) => c.id === stock.id)

        newStocks[stockIndex] = {
          ...stockData,
          id: stock.id
        } as unknown as StockProps
      } else {
        const { data: response } = await api('user-api').post<StockProps>(
          'stocks',
          {
            ...stockData,
            serviceId: site.serviceId
          }
        )

        newStocks.push({
          ...stockData,
          id: response.id
        } as unknown as StockProps)
      }

      try {
        setStocks(newStocks)

        showToast({
          message: 'Estoque atualizado com sucesso!',
          type: 'success'
        })

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      methodsToAdd,
      available,
      stocks,
      stock,
      site.serviceId,
      setStocks,
      showToast,
      handleToggleModal
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo estoque'}
          </h1>
          <p>Detalhes do estoque</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="code">Código do estoque</S.Label>
            <S.Input
              id="code"
              name="code"
              placeholder="Ex: 1000"
              className={errors.code && 'is-invalid'}
              value={codeValue}
              onChange={handleChangeCode}
              onKeyPress={handleKeyPressCode}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="name">Nome do estoque</S.Label>
            <S.Input
              id="name"
              name="name"
              placeholder="Ex: Uva"
              className={errors.name && 'is-invalid'}
              {...register('name')}
            />
          </S.Col>

          {taxableUnits.map((taxableUnit) => (
            <S.ColWrapper key={taxableUnit.unit}>
              <S.Col>
                <S.Label htmlFor={taxableUnit.quantityInStock}>
                  {taxableUnit.unit} - Quantia no estoque
                </S.Label>
                <S.Input
                  id={taxableUnit.quantityInStock}
                  type="number"
                  step="any"
                  min="0"
                  name={taxableUnit.quantityInStock}
                  placeholder={`Ex: 1 ${taxableUnit.unit}`}
                  readOnly={!!stock}
                  className={
                    errors[taxableUnit.quantityInStock] && 'is-invalid'
                  }
                  {...register(taxableUnit.quantityInStock)}
                />
              </S.Col>

              <S.Col>
                <S.Label htmlFor={taxableUnit.minimumQuantityInStock}>
                  {taxableUnit.unit} - Quantia mínima no estoque
                </S.Label>
                <S.Input
                  id={taxableUnit.minimumQuantityInStock}
                  type="number"
                  step="any"
                  min="0"
                  name={taxableUnit.minimumQuantityInStock}
                  placeholder={`Ex: 1 ${taxableUnit.unit}`}
                  className={
                    errors[taxableUnit.minimumQuantityInStock] && 'is-invalid'
                  }
                  {...register(taxableUnit.minimumQuantityInStock)}
                />
              </S.Col>

              {/* <S.Col>
                <S.Label>Converter</S.Label>

                <S.ButtonConvert
                  type="button"
                  disabled={
                    !methodsToAdd.some(
                      (method) => method.type === taxableUnit.unit
                    ) ||
                    (stockSelected
                      ? stockSelected[taxableUnit.quantityInStock]
                      : 0) <= 0
                  }
                  onClick={() =>
                    console.log(
                      methodsToAdd.find(
                        (method) => method.type === taxableUnit.unit
                      )
                    )
                  }
                >
                  <FiPackage />
                </S.ButtonConvert>
              </S.Col> */}
            </S.ColWrapper>
          ))}

          <S.Col>
            <S.Label htmlFor="methodOfAddingStock">
              Método de adição de estoque
            </S.Label>

            <S.MethodAdding>
              <S.Methods>
                {methodsToAdd.map((method, index) => (
                  <S.Method key={method.id}>
                    <S.Col>
                      <S.Label htmlFor={`type_${method.id}`}>
                        Converter de
                      </S.Label>

                      <S.Select
                        id={method.id}
                        onChange={(e) =>
                          handleChangeMethodType(method.id, e.target.value)
                        }
                      >
                        <option value="UN" selected={method.type === 'UN'}>
                          Unidade
                        </option>
                        <option value="CX" selected={method.type === 'CX'}>
                          Caixa
                        </option>
                        <option
                          value="KG"
                          selected={method.type === 'KG'}
                          disabled
                        >
                          Quilograma
                        </option>
                        <option
                          value="G"
                          selected={method.type === 'G'}
                          disabled
                        >
                          Grama
                        </option>
                        <option
                          value="L"
                          selected={method.type === 'L'}
                          disabled
                        >
                          Litro
                        </option>
                      </S.Select>

                      {index !== 0 && (
                        <S.ButtonRemove
                          type="button"
                          onClick={() => handleRemoveMethods(method.id)}
                        >
                          <FiTrash2 />
                        </S.ButtonRemove>
                      )}
                    </S.Col>

                    <div>
                      <S.Col>
                        <S.Label
                          htmlFor={`convertQuantityToConvert_${method.id}`}
                        >
                          Converter para
                        </S.Label>

                        <S.Select
                          id={`convertQuantityToConvert_${method.id}`}
                          onChange={(e) =>
                            handleChangeMethodQuantityToConvert(
                              method.id,
                              'type',
                              e.target.value
                            )
                          }
                        >
                          <option
                            value="UN"
                            selected={method.quantityToConvert.type === 'UN'}
                            disabled={method.type === 'UN'}
                          >
                            Unidade
                          </option>
                          <option
                            value="CX"
                            selected={method.quantityToConvert.type === 'CX'}
                            disabled={method.type === 'CX'}
                          >
                            Caixa
                          </option>
                          <option
                            value="KG"
                            selected={method.quantityToConvert.type === 'KG'}
                            disabled
                          >
                            Quilograma
                          </option>
                          <option
                            value="G"
                            selected={method.quantityToConvert.type === 'G'}
                            disabled
                          >
                            Grama
                          </option>
                          <option
                            value="L"
                            selected={method.quantityToConvert.type === 'L'}
                            disabled
                          >
                            Litro
                          </option>
                        </S.Select>
                      </S.Col>

                      <S.Col>
                        <S.Label htmlFor={`convertToAmount_${method.id}`}>
                          Quantidade
                        </S.Label>

                        <S.Input
                          id={`convertToAmount_${method.id}`}
                          type="text"
                          placeholder="Quantidade para adicionar"
                          value={method.quantityToConvert.amountToAdd}
                          onChange={(e) =>
                            handleChangeMethodQuantityToConvert(
                              method.id,
                              'amountToAdd',
                              e.target.value
                            )
                          }
                        />
                      </S.Col>
                    </div>
                  </S.Method>
                ))}
              </S.Methods>

              <button type="button" onClick={handleAddMethods}>
                Adicionar novo método
              </button>
            </S.MethodAdding>
          </S.Col>

          <S.Col>
            <S.Label>Disponibilidade</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  name="available"
                  type="radio"
                  value="true"
                  onChange={handleAvailableRadio}
                  defaultChecked={available}
                />
                <span>
                  <div>
                    <div>Disponível</div>
                    <div>
                      O estoque estará disponível para que possa ser selecionado
                    </div>
                  </div>
                </span>
              </label>
              <label>
                <input
                  name="available"
                  type="radio"
                  value="false"
                  onChange={handleAvailableRadio}
                  defaultChecked={!available}
                />
                <span>
                  <div>
                    <div>Indisponível</div>
                    <div>
                      O estoque estará indisponível para que possa ser
                      selecionado
                    </div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default StockModal
