import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { createMemoryHistory } from 'history'
import PrivateRoute from 'components/PrivateRouter'

import Auth from 'pages/Auth'
import Services from 'pages/Services'
import Dashboard from 'pages/Dashboard'
import TotemSelfService from 'pages/TotemSelfService'

export const Routes = () => {
  const history = createMemoryHistory()

  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/services/" />} />
        <Route path="/auth" render={(props) => <Auth {...props} />} />
        <PrivateRoute
          path="/services"
          component={(props) => <Services {...props} />}
          owner
        />
        <PrivateRoute
          path="/dashboard"
          component={(props) => <Dashboard {...props} />}
        />
        <PrivateRoute
          path="/totem"
          component={(props) => <TotemSelfService {...props} />}
        />
      </Switch>
    </BrowserRouter>
  )
}
