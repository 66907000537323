import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MaterialMenu from '@material-ui/core/Menu'
import MaterialMenuItem from '@material-ui/core/MenuItem'

interface Props {
  anchorEl: null | HTMLElement
  handleMenuOptionsClose(): void
}

const Menu = withStyles({
  paper: {
    backgroundColor: '#FFFFFF'
  }
})((props: Props) => (
  <MaterialMenu
    anchorEl={props.anchorEl}
    keepMounted
    open={Boolean(props.anchorEl)}
    onClose={props.handleMenuOptionsClose}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const MenuItem = withStyles(() => ({
  root: {
    fontSize: '1.8rem'
  }
}))(MaterialMenuItem)

export { Menu, MenuItem }
