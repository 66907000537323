import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  gap: 4rem;
  flex-direction: column;

  .gpw {
    padding: 0;
    text-align: initial !important;
    width: min-content;

    .popover {
      background: #ffffff;
    }
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Cards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(5, 1fr);
    column-gap: 4rem;
  `}
`

export const Card = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  .change-mode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 1rem;
    }
  }

  .color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }

  .swatch {
    width: min-content;
    padding: 5px;
    background: rgb(255, 255, 255);
    border-radius: 1px;
    box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px;
    display: inline-block;
    cursor: pointer;
  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`

export const MobileFrame = styled.div`
  position: relative;
  width: 40rem;
  margin-left: 5rem;
  transition: all 0.2s ease-in-out;

  > img {
    position: relative;
    width: 40rem;
    margin: 0 auto;
  }

  .frame-box {
    position: absolute;
    top: 102px;
    left: 47px;
    overflow-y: hidden;

    iframe {
      width: 31rem;
      height: 52rem;
    }
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const Button = styled.button`
  color: ${(props) => props.theme.colors.text.cards};
  background-color: ${(props) => props.theme.colors.blue};
  border-color: ${(props) => props.theme.colors.blue};

  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  min-width: 12rem;

  font-weight: 600;
  text-align: center;

  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover:not(:disabled) {
    color: #f7fdff;
    opacity: 0.9;
  }
`
