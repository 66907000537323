import { ItemOrderProps } from 'utils/props'

const itemRecipeOptions = (item: ItemOrderProps) => {
  let recipeOptions = item.recipe
  const options = []

  recipeOptions.forEach((option) => {
    const optionData = {
      name: option.name,
      options: recipeOptions
        .filter((o) => o.id === option.id)
        .map((o) => ({
          name: o.name,
          quantity: o.quantity
        }))
    }

    options.push(optionData)

    recipeOptions = recipeOptions.filter((o) => o.id !== option.id)
  })

  return options
}

const itemCategoryOptions = (item: ItemOrderProps) => {
  let categoryOptions = item.categoryOptions
  const options = []

  categoryOptions.forEach((option) => {
    const optionData = {
      name: option.groupName,
      options: categoryOptions
        .filter((o) => o.groupId === option.groupId)
        .map((o) => ({
          name: o.name,
          quantity: o.quantity,
          price: o.price
        }))
    }

    options.push(optionData)

    categoryOptions = categoryOptions.filter(
      (o) => o.groupId !== option.groupId
    )
  })

  return options
}

const itemOptions = (item: ItemOrderProps) => {
  let itemOptions = item.options
  const options = []

  itemOptions.forEach((option) => {
    const optionData = {
      name: option.groupName,
      options: itemOptions
        .filter((o) => o.groupId === option.groupId)
        .map((o) => ({
          name: o.name,
          quantity: o.quantity,
          price: o.price
        }))
    }

    options.push(optionData)

    itemOptions = itemOptions.filter((o) => o.groupId !== option.groupId)
  })

  return options
}

export { itemRecipeOptions, itemCategoryOptions, itemOptions }
