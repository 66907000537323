import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

export const Container = styled.main``

export const Title = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

interface ButtonProps {
  primary?: boolean
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    props.primary ? props.theme.colors.primary : props.theme.colors.secondary};
  color: white;
  padding: 1.5rem 2rem;
  border: none;
  font-size: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 3rem;
`

export const Message = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`

export const MessageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 1rem;
  border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
`

export const MessageHeaderLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1rem;
    border-right: 3px solid ${(props) => props.theme.colors.secondary};

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }

  h1 {
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const MessageHeaderRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const MessageTitle = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    display: none;
  `};

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1rem;
    border-right: 3px solid ${(props) => props.theme.colors.secondary};

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }

  label {
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const MessageTitleLeft = styled.div`
  margin-left: 4rem;

  display: flex;
  gap: 10rem;
  align-items: center;
  justify-content: space-between;
`

export const MessageTitleRight = styled.div`
  display: flex;
  gap: 12rem;
  align-items: center;
  justify-content: space-between;
`

export const MessageStatus = styled(Switch)``

export const MessageText = styled.textarea`
  display: block;
  width: 100%;
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;
  resize: vertical;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Snippets = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
