import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import React, { useCallback, useContext, useState } from 'react'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'

import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'
import { PaymentMethodProps } from 'utils/props'

import PaymentModal from './PaymentModal'

import * as S from './styles'

const PaymentMethods: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const [paymentMethods, setPaymentMethods] = useState(() => {
    if (site.paymentMethods.paymentMethods.length > 0) {
      return site.paymentMethods.paymentMethods
    }

    return [
      {
        id: uuidv4(),
        name: 'Dinheiro',
        haveChangeOption: true,
        available: true
      }
    ]
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [paymentSelected, setPaymentSelected] =
    useState<PaymentMethodProps>(null)
  let time: NodeJS.Timeout = null

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleUpdatePayment = useCallback(
    async (updatedPayments: any[]) => {
      try {
        clearTimeout(time)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = setTimeout(async () => {
          const { data } = await api('user-api').put(
            `sites/${site.id}/payment-methods`,
            {
              paymentMethods: updatedPayments
            }
          )

          showToast({
            message: 'Formas atualizadas com sucesso!',
            type: 'success'
          })
        }, 1000)
      } catch (err) {
        showToast({
          message: 'Aconteceu um erro!',
          type: 'error'
        })
      }
    },
    [setSite, showToast, site]
  )

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, payment: any) => {
      setPaymentSelected(payment)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggleModal = useCallback(
    (payment: PaymentMethodProps = null) => {
      setPaymentSelected(payment)
      setIsOpen((prevState: boolean) => !prevState)
    },
    []
  )

  const handleModalPaymentEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleModal(paymentSelected)
  }, [handleMenuOptionsClose, handleToggleModal, paymentSelected])

  const handlePaymentDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir a forma de pagamento "${paymentSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const paymentIndex = paymentMethods.findIndex(
            (c) => c.id === paymentSelected.id
          )

          const updatedPayments = [...paymentMethods]
          const filteredPayments = updatedPayments.filter(
            (_, i) => i !== paymentIndex
          )

          handleUpdatePayment(filteredPayments)
          setPaymentMethods(filteredPayments)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    paymentSelected,
    paymentMethods,
    handleMenuOptionsClose,
    handleUpdatePayment,
    showToast
  ])

  const handleUpdateStatusArea = useCallback(
    ({ id }) => {
      const updatedPayments = paymentMethods.map((item) => {
        if (item.id === id) {
          return { ...item, available: !item.available }
        }

        return item
      })

      setPaymentMethods(updatedPayments)
      handleUpdatePayment(updatedPayments)
    },
    [paymentMethods, handleUpdatePayment]
  )

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalPaymentEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handlePaymentDelete}>Excluir</StyledMenuItem>
        {/* <StyledMenuItem
          onClick={handleCategoryCopy}
        >
          Duplicar
        </StyledMenuItem> */}
      </StyledMenu>

      <PaymentModal
        isOpen={isOpen}
        payment={paymentSelected}
        payments={paymentMethods}
        setPaymentMethods={setPaymentMethods}
        handleToggle={handleToggleModal}
        handleUpdatePayment={handleUpdatePayment}
      />

      <S.Container>
        <S.Title>Formas de pagamentos</S.Title>

        <S.Options>
          <S.ButtonAdd type="button" onClick={() => handleToggleModal()}>
            <FiPlus /> Adicionar forma
          </S.ButtonAdd>
        </S.Options>

        <S.Payments>
          {paymentMethods.map((item) => (
            <S.Payment key={item.id}>
              <S.PaymentLeft>
                <label htmlFor={item.name}>{item.name}</label>
              </S.PaymentLeft>

              <S.PaymentRight>
                <S.PaymentStatus
                  onChange={() => handleUpdateStatusArea({ id: item.id })}
                  checked={item.available}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />

                <button
                  type="button"
                  title="Opções"
                  onClick={(e) => handleMenuOptionsOpen(e, item)}
                >
                  <FiMoreVertical />
                </button>
              </S.PaymentRight>
            </S.Payment>
          ))}
        </S.Payments>
      </S.Container>
    </>
  )
}

export default PaymentMethods
