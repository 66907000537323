import React from 'react'

import { numberBrFormatter } from 'utils/utils'

import * as S from './styles'

const ChartTooltip = ({ data: { color, data } }) => (
  <S.Container className="shadow chart-tooltip">
    <div className="chart-tooltip-title">
      <span className="font-weight-bold">{data.date}</span>
    </div>

    <div className="chart-tooltip-content">
      <div className="chart-tooltip">
        <div>
          <div
            className="chart-tooltip-color"
            style={{ backgroundColor: color.revenue }}
          />
          <span>Faturamento:</span>
        </div>

        <span>
          R$
          {numberBrFormatter(data.revenue, 2)}
        </span>
      </div>
      <div className="chart-tooltip">
        <div>
          <div
            className="chart-tooltip-color"
            style={{ backgroundColor: color.cogs }}
          />
          <span>Custo dos Produtos:</span>
        </div>
        <span>
          R$
          {numberBrFormatter(data.cogs, 2)}
        </span>
      </div>
      <div className="chart-tooltip">
        <div>
          <div
            className="chart-tooltip-color"
            style={{ backgroundColor: color.transactionTax }}
          />
          <span>Lucro dos adicionais:</span>
        </div>
        <span>
          R$
          {numberBrFormatter(data.transactionTax, 2)}
        </span>
      </div>
    </div>
    <div className="chart-tooltip-profit">
      <div className="chart-tooltip">
        <div>
          <div
            className="chart-tooltip-color"
            style={{ backgroundColor: color.profit }}
          />
          <span className={`text-${data.profit > 0 ? 'green' : 'red'}`}>
            Lucro Líquido:
          </span>
        </div>

        <span className={`text-${data.profit > 0 ? 'green' : 'red'}`}>
          R$ {numberBrFormatter(data.profit, 2)}
        </span>
      </div>
    </div>
  </S.Container>
)

export default ChartTooltip
