import React, { useCallback, useState } from 'react'

import { DayShiftProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import * as S from './styles'

const OpeningHours: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()
  const [dayShifts, setDayShifts] = useState(() => {
    if (site.openingHours.length > 0) {
      return site.openingHours
    }

    return [
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Domingo',
        dayOfWeek: 0,
        checked: true
      },
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Segunda',
        dayOfWeek: 1,
        checked: true
      },
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Terça',
        dayOfWeek: 2,
        checked: true
      },
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Quarta',
        dayOfWeek: 3,
        checked: true
      },
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Quinta',
        dayOfWeek: 4,
        checked: true
      },
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Sexta',
        dayOfWeek: 5,
        checked: true
      },
      {
        openingTimeAndClosingTime: '00:00 - 23:59',
        weekday: 'Sábado',
        dayOfWeek: 6,
        checked: true
      }
    ]
  })
  let time: NodeJS.Timeout = null

  const handleUpdateShift = useCallback(
    async (updateDayShift: DayShiftProps[]) => {
      try {
        clearTimeout(time)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = setTimeout(async () => {
          const { data } = await api('user-api').put(
            `sites/${site.id}/opening-hours`,
            {
              dayShifts: updateDayShift
            }
          )

          setSite({
            ...site,
            openingHours: updateDayShift
          })

          showToast({
            message: 'Dados atualizados com sucesso!',
            type: 'success'
          })
        }, 1000)
      } catch (err) {
        showToast({
          message: 'Aconteceu um erro!',
          type: 'error'
        })
      }
    },
    [setSite, showToast, site]
  )

  const handleStatusDay = useCallback(
    (dayOfWeek: number) => {
      try {
        const updatedDayShift = dayShifts.map((item) => {
          if (item.dayOfWeek === dayOfWeek) {
            return { ...item, checked: !item.checked }
          }

          return item
        })

        setDayShifts(updatedDayShift)
        handleUpdateShift(updatedDayShift)
      } finally {
        // setLoading(false)
      }
    },
    [dayShifts, handleUpdateShift]
  )

  const handleChangeDay = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, dayOfWeek: number) => {
      try {
        e.currentTarget.maxLength = 13
        let { value } = e.target

        if (!value.match(/^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/)) {
          value = value.replace(/\D/g, '')
          value = value.replace(/(\d{2})(\d)/, '$1:$2')
          value = value.replace(/(\d{2})(\d)/, '$1 - $2')
          value = value.replace(/(\d{2})(\d)/, '$1:$2')
          e.currentTarget.value = value
        }

        const updatedDayShift = dayShifts.map((item) => {
          if (item.dayOfWeek === dayOfWeek) {
            return {
              ...item,
              openingTimeAndClosingTime: value
            }
          }

          return item
        })

        setDayShifts(updatedDayShift)

        if (value.length === 13) {
          handleUpdateShift(updatedDayShift)
        }
      } finally {
        // setLoading(false)
      }
    },
    [dayShifts, handleUpdateShift]
  )

  return (
    <S.Container>
      <S.Title>Horários</S.Title>

      <S.ListDays>
        <h1>Escolha os dias e horários</h1>

        <div>
          {dayShifts.map((item) => (
            <button
              key={item.dayOfWeek}
              type="button"
              onClick={() => handleStatusDay(item.dayOfWeek)}
              className={item.checked ? 'checked' : ''}
            >
              {item.weekday.substring(0, 3)}
            </button>
          ))}
        </div>
      </S.ListDays>

      <S.Days>
        {dayShifts
          .filter((item) => item.checked)
          .map((item) => (
            <div key={item.dayOfWeek}>
              <label htmlFor={item.weekday}>{item.weekday}</label>
              <input
                type="text"
                id={item.weekday}
                value={item.openingTimeAndClosingTime}
                onChange={(e) => handleChangeDay(e, item.dayOfWeek)}
                maxLength={13}
              />
            </div>
          ))}
      </S.Days>
    </S.Container>
  )
}

export default OpeningHours
