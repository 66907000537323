import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FiMenu } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'

import { SiteProps } from 'utils/props'
import { api } from 'services/api'
import { socket } from 'services/socket'
import { useSite } from 'contexts/SiteContext'
import { useBot } from 'contexts/BotContext'
import { useIFood } from 'contexts/IFoodContext'
import errorHandler from 'services/errorHandler'

import * as S from './styles'

interface Props {
  service: string
  handleOpenSidebar(): void
}

interface OpenChangeSocketProps {
  siteId: string
  open: boolean
}

const Navbar: React.FC<Props> = ({ handleOpenSidebar }) => {
  const history = useHistory()
  const { site, setSite } = useSite()
  const { isConnected } = useBot()
  const { ifood, handleOpenIFood, handleCloseIFood } = useIFood()
  const [isLoading, setIsLoading] = useState(false)

  const [isOpen, setIsOpen] = useState(site.open)

  const [isDeliveryActive, setIsDeliveryActive] = useState(site.delivery)
  const [isTakeoutActive, setIsTakeoutActive] = useState(site.takeout)
  const [isIndoorActive, setIsIndoorActive] = useState(site.indoor)

  const [deliveryTime, setDeliveryTime] = useState(site.deliveryTime)
  const [takeoutTime, setTakeoutTime] = useState(site.takeoutTime)
  const [indoorTime, setIndoorTime] = useState(site.indoorTime)

  const themeContext = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const StyledMenu = withStyles({
    paper: {
      marginTop: '1rem'
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  useEffect(() => {
    socket.on('openChange', (data: OpenChangeSocketProps) => {
      console.log(data)
    })
  }, [])

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleChangeOpen = useCallback(async () => {
    setIsLoading(true)

    try {
      if (isOpen) {
        const { data } = await api('user-api').put(`sites/${site.id}/close`)
      } else {
        const { data } = await api('user-api').put(`sites/${site.id}/open`)
      }

      setIsOpen((prevState) => !prevState)
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsLoading(false)
    }
  }, [site, isOpen])

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.maxLength = 5
    let { value } = e.currentTarget
    if (!value.match(/^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/)) {
      value = value.replace(/\D/g, '')
      value = value.replace(/(\d{2})(\d)/, '$1-$2')
      e.currentTarget.value = value
    }

    return e
  }

  const handleChangeStatus = useCallback(
    async (name: string) => {
      switch (name) {
        case 'delivery':
          const isDeliveryOpening = !isDeliveryActive

          await api('user-api').put(`sites/${site.id}`, {
            delivery: isDeliveryOpening
          })

          setIsDeliveryActive(isDeliveryOpening)
          break
        case 'takeout':
          const isTakeoutOpening = !isTakeoutActive

          await api('user-api').put(`sites/${site.id}`, {
            takeout: isTakeoutOpening
          })

          setIsTakeoutActive(isTakeoutOpening)
          break
        case 'indoor':
          const isIndoorOpening = !isIndoorActive

          await api('user-api').put(`sites/${site.id}`, {
            indoor: isIndoorOpening
          })

          setIsIndoorActive(isIndoorOpening)
          break
      }
    },
    [isDeliveryActive, isTakeoutActive, isIndoorActive, site.id]
  )

  const handleChangeOpenAndCloseAutomatically = async () => {
    const { data } = await api('user-api').put<SiteProps>(`sites/${site.id}`, {
      openAndCloseAutomatically: !site.openAndCloseAutomatically
    })

    setSite({
      ...site,
      ...data
    })
  }

  const handleChangeSchedule = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name } = e.currentTarget
    const value = handleChangeTime(e).currentTarget.value

    switch (name) {
      case 'delivery':
        if (value.length === 5) {
          await api('user-api').put(`sites/${site.id}`, {
            deliveryTime: value
          })
          setDeliveryTime(value)
        }

        break
      case 'takeout':
        if (value.length === 5) {
          await api('user-api').put(`sites/${site.id}`, {
            takeoutTime: value
          })
          setTakeoutTime(value)
        }

        break
      case 'indoor':
        if (value.length === 5) {
          await api('user-api').put(`sites/${site.id}`, {
            indoorTime: value
          })
          setIndoorTime(value)
        }

        break
    }
  }

  const handleChangeIFoodOpen = useCallback(async () => {
    try {
      if (ifood?.active) {
        handleCloseIFood()
      } else {
        handleOpenIFood()
      }
    } catch (err) {
      errorHandler(err)
    }
  }, [handleCloseIFood, handleOpenIFood, ifood?.active])

  return (
    <S.Container height={!!ifood?.accessToken}>
      <S.Buttons>
        <button type="button" onClick={handleOpenSidebar}>
          <FiMenu />
        </button>
      </S.Buttons>

      <div className="right">
        <div className="open">
          {ifood?.accessToken && (
            <S.Open isOpen={ifood?.active} onClick={handleChangeIFoodOpen}>
              <img src="/img/icons/ifood.svg" alt="IFood" />
              IFood {ifood?.active ? 'aberto' : 'fechado'}
            </S.Open>
          )}

          <S.Open
            onClick={handleMenuOptionsOpen}
            isOpen={isOpen}
            disabled={isLoading}
          >
            {isOpen ? (
              <>
                <img src="/img/icons/check.svg" alt="Loja aberta" />
                Loja aberta
              </>
            ) : (
              <>
                <img src="/img/icons/warning.svg" alt="Loja fechada" />
                Loja fechada
              </>
            )}
          </S.Open>

          <StyledMenu>
            <S.MenuOpen>
              <S.Open
                onClick={handleChangeOpen}
                isOpen={isOpen}
                disabled={isLoading}
              >
                {isOpen ? (
                  <>
                    <img src="/img/icons/check.svg" alt="Loja aberta" />
                    Loja aberta
                  </>
                ) : (
                  <>
                    <img src="/img/icons/warning.svg" alt="Loja fechada" />
                    Loja fechada
                  </>
                )}
              </S.Open>

              {/* <S.Option>
                <div>
                  <S.Status
                    checked={site.openAndCloseAutomatically}
                    onChange={handleChangeOpenAndCloseAutomatically}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={15}
                    width={40}
                    handleDiameter={10}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <span>abrir e fechar automatico</span>
                </div>
              </S.Option> */}

              <S.Option>
                <div>
                  <S.Status
                    checked={isDeliveryActive}
                    onChange={() => handleChangeStatus('delivery')}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={15}
                    width={40}
                    handleDiameter={10}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <span>Entrega</span>
                </div>

                <S.InputTime
                  name="delivery"
                  type="text"
                  placeholder="00-00 min"
                  defaultValue={deliveryTime}
                  maxLength={5}
                  onChange={handleChangeSchedule}
                />
              </S.Option>

              <S.Option>
                <div>
                  <S.Status
                    checked={isTakeoutActive}
                    onChange={() => handleChangeStatus('takeout')}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={15}
                    width={40}
                    handleDiameter={10}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <span>Retirada</span>
                </div>

                <S.InputTime
                  name="takeout"
                  type="text"
                  placeholder="00-00 min"
                  defaultValue={takeoutTime}
                  maxLength={5}
                  onChange={handleChangeSchedule}
                />
              </S.Option>

              <S.Option>
                <div>
                  <S.Status
                    checked={isIndoorActive}
                    onChange={() => handleChangeStatus('indoor')}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={15}
                    width={40}
                    handleDiameter={10}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <span>Mesa</span>
                </div>

                <S.InputTime
                  name="indoor"
                  type="text"
                  placeholder="00-00 min"
                  defaultValue={indoorTime}
                  maxLength={5}
                  onChange={handleChangeSchedule}
                />
              </S.Option>
            </S.MenuOpen>
          </StyledMenu>
        </div>

        <button
          type="button"
          onClick={() => history.push('/dashboard/modules/bot')}
        >
          <img
            src={isConnected ? '/favicon.png' : '/favicon.png'}
            width={40}
            alt="Navegar até configuração do bot"
            title="Navegar até configuração do bot"
          />
        </button>
      </div>
    </S.Container>
  )
}

export default Navbar
