import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ReactSortable } from 'react-sortablejs'
import Switch from 'react-switch'

export const Container = styled(ReactSortable)`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Option = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  padding: 1.5rem;

  ${({ theme }) => css`
    background: ${theme.colors.secondary};
    border-radius: 1rem;
  `}
`

export const OptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const OptionHeaderLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  > div {
    gap: 10rem;
    align-items: center;
    justify-content: space-between;
  }

  ${({ theme }) => css`
    > button {
      display: flex;
      justify-content: center;
      align-items: center;

      padding-right: 1rem;
      border-right: 3px solid ${theme.colors.secondary};

      svg {
        width: 1.5rem;
        height: 1.5rem;

        color: ${theme.colors.text.cards};
      }
    }

    h1 {
      font-weight: 500;
      font-size: 1.8rem;

      color: ${theme.colors.text.cards};
    }
  `}
`

export const OptionHeaderRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  > div {
    gap: 10rem;
    align-items: center;
    justify-content: space-between;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const OptionHeaderStatus = styled(Switch)``

export const OptionsButtons = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('small')`
    flex-direction: column;
    justify-content: center;
  `};
`

export const ButtonAdd = styled.button`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  height: 5rem;

  padding: 1.5rem;

  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 1rem;

  font-weight: 600;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const ButtonCopy = styled.button`
  display: flex;
  gap: 1rem;
  align-items: center;

  ${({ theme }) => css`
    font-weight: 600;
    font-size: 1.8rem;

    color: ${theme.colors.text.cards};
  `}
`
