import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ServiceProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { useAuth } from 'contexts/UserContext'
import { api } from 'services/api'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(): void
  loadServices(): void
}

interface FormDataProps {
  name: string
  tipo: 'FRANQUEADO' | 'REVENDEDOR'
  email: string
  password: string
  confirmPassword: string
}

const NewServiceModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  loadServices
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToast()
  const { user } = useAuth()

  const schema = yup.object().shape({
    name: yup.string().required('Este campo é obrigatório'),
    tipo: yup.string().required(),
    email: yup.string().email().required('Este campo é obrigatório'),
    password: yup.string().min(6).required('Este campo é obrigatório!'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        'Senha e confirmar senha não são iguais!'
      )
  })

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      setIsLoading(true)

      try {
        const { data: responseEmployee } = await api(
          'user-api'
        ).post<ServiceProps>('employees', data)

        await api('user-api').post<ServiceProps>('services', {
          name: watch('name'),
          employeeId: responseEmployee.id
        })

        showToast({
          message: 'Serviço adicionado com sucesso!',
          type: 'success'
        })

        loadServices()
        handleToggle()
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [watch, loadServices, handleToggle, showToast]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => {
        if (isLoading) return
        handleToggle()
      }}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: '#293949',
          borderRadius: '1.5rem',
          maxWidth: '800px'
        }
      }}
    >
      <S.Content>
        <h1>Informe alguns dados</h1>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="name">Digite o nome do serviço</S.Label>
            <S.Input
              id="name"
              name="name"
              placeholder="Digite aqui"
              className={errors.name && 'is-invalid'}
              disabled={isLoading}
              {...register('name')}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="name">Digite o e-mail do serviço</S.Label>
            <S.Input
              id="email"
              name="email"
              placeholder="Digite aqui"
              className={errors.email && 'is-invalid'}
              disabled={isLoading}
              {...register('email')}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="password">Crie uma senha para o serviço</S.Label>
            <S.Input
              id="password"
              name="password"
              type="password"
              placeholder="Digite aqui"
              className={errors.password && 'is-invalid'}
              disabled={isLoading}
              {...register('password')}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="confirmPassword">
              Confirme a senha do serviço
            </S.Label>
            <S.Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Digite aqui"
              className={errors.confirmPassword && 'is-invalid'}
              disabled={isLoading}
              {...register('confirmPassword')}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="tipo">Revendedor / Franqueado</S.Label>

            <S.Select
              required
              id="tipo"
              className={errors.tipo && 'is-invalid'}
              {...register('tipo')}
            >
              <option value="REVENDEDOR">Revendedor</option>
              <option value="FRANQUEADO" selected>
                Franqueado
              </option>
            </S.Select>
          </S.Col>

          {/* <S.Col>
            <S.Label htmlFor="employeeId">Selecione o funcionario</S.Label>
            <S.Select
              id="employeeId"
              name="employeeId"
              className={errors.employeeId && 'is-invalid'}
              {...register('employeeId')}
            >
              {user.user.employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </S.Select>
          </S.Col> */}

          <S.Button type="submit" disabled={isLoading}>
            {isLoading ? (
              <div className="divLoading">
                <span className="spanLoading" />
                <span className="spanLoading" />
                <span className="spanLoading" />
                <span className="spanLoading" />
              </div>
            ) : (
              'Cadastrar'
            )}
          </S.Button>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default NewServiceModal
