import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;

  width: 100%;
  /* height: 9rem; */

  padding: 2rem;

  background: #293949;
  border-radius: 1rem;

  ${media.greaterThan('medium')`
    min-width: 50rem;
  `}

  > img {
    width: 6rem;
    height: 6rem;
  }

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    h1 {
      font-weight: bold;
      font-size: 2rem;

      color: #f7fdff;
    }

    p {
      font-weight: 400;
      font-size: 2rem;

      color: #f7fdff;
    }
  }
`

export const Cards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Card = styled.button`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 9.5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.secondary};
  border-radius: 1rem;

  img {
    // width: 10rem;
    height: 10rem;
  }

  div {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }

    span {
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`
