import React, { useCallback } from 'react'
import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { v4 as uuidv4 } from 'uuid'

import { ItemProps, SizeProductProps } from 'utils/props'

import InputImage from './InputImage'

import * as S from './styles'
import { FaArrowsAlt } from 'react-icons/fa'

interface Props {
  product: ItemProps

  sizes: SizeProductProps[]
  setSizes(sizes: SizeProductProps[]): void
}

const Sizes: React.FC<Props> = ({ product, sizes, setSizes }) => {
  const handleSizeAdd = useCallback(() => {
    const id = uuidv4()

    setSizes([
      ...sizes,
      {
        id,
        image: product?.image || null,
        name: '',
        price: {
          currency: 'BRL',
          value: 0
        },
        available: true
      }
    ])
  }, [product?.image, setSizes, sizes])

  const handleChangeSizeImage = useCallback(
    (sizeId: string, value: File) => {
      const newSizes = [...sizes]
      const sizeIndex = newSizes.findIndex(({ id }) => id === sizeId)

      Object.assign(newSizes[sizeIndex], {
        image: value
      })

      setSizes(newSizes)
    },
    [setSizes, sizes]
  )

  const handleSizeRemove = useCallback(
    (sizeIndex: number) => {
      const updatedSizes = [...sizes]
      const filteredSizes = updatedSizes.filter((_, i) => i !== sizeIndex)

      setSizes(filteredSizes)
    },
    [setSizes, sizes]
  )

  const handleChangeSizeName = useCallback(
    (sizeIndex: number, value: string) => {
      const newSizes = [...sizes]

      Object.assign(newSizes[sizeIndex], {
        name: value
      })

      setSizes(newSizes)
    },
    [setSizes, sizes]
  )

  const handleChangeSizePrice = useCallback(
    (sizeIndex: number, maskedValue: number) => {
      const newSizes = [...sizes]

      Object.assign(newSizes[sizeIndex].price, {
        value: maskedValue
      })

      setSizes(newSizes)
    },
    [setSizes, sizes]
  )

  return (
    <S.Container>
      <span>Tamanhos</span>

      <S.Sizes
        handle=".handle"
        animation={150}
        list={sizes}
        setList={(newState: SizeProductProps[]) => setSizes(newState)}
      >
        {sizes.map((item, index) => (
          <S.Size key={item.id}>
            <S.Sortable>
              <button type="button" className="handle">
                <FaArrowsAlt />
              </button>
            </S.Sortable>

            <div>
              <InputImage
                id={item.id}
                image={item.image}
                handleChange={handleChangeSizeImage}
              />
            </div>

            <div>
              <label htmlFor={`${item.id}_name`}>Nome</label>

              <S.Input
                id={`${item.id}_name`}
                type="text"
                value={item.name}
                className={item.name.trim().length <= 0 ? 'is-invalid' : ''}
                onChange={(e) =>
                  handleChangeSizeName(index, e.currentTarget.value)
                }
              />
            </div>

            <div>
              <label htmlFor={`${item.id}_price`}>Preço</label>

              <S.InputMoney
                id={`${item.id}_price`}
                money
                currency="BRL"
                config={{
                  locale: 'pt-BR',
                  formats: {
                    number: {
                      BRL: {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    }
                  }
                }}
                value={item.price.value}
                onChange={(_, maskedValue: number) =>
                  handleChangeSizePrice(index, maskedValue)
                }
              />
            </div>

            <button
              type="button"
              title="Remover"
              onClick={() => handleSizeRemove(index)}
            >
              <FiTrash2 />
            </button>
          </S.Size>
        ))}
      </S.Sizes>

      <button type="button" onClick={handleSizeAdd}>
        <FiPlus /> Novo tamanho
      </button>
    </S.Container>
  )
}

export default Sizes
