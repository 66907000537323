import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'

import { MotoboyProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import * as S from './styles'
import ModalAddMotoboy from './ModalAddMotoboy'
import { masks } from 'utils/utils'

const Motoboys: React.FC = () => {
  const { showToast } = useToast()
  const { site, setSite } = useSite()
  const themeContext = useContext(ThemeContext)

  const [motoboys, setMotoboys] = useState<MotoboyProps[]>(
    site.motoboys.sort((a, b) =>
      a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
    )
  )
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [motoboySelected, setMotoboySelected] = useState<MotoboyProps>(null)
  let time: NodeJS.Timeout = null

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const carregarDados = useCallback(async () => {
    if (!site.motoboys) {
      const { data } = await api('user-api').get<MotoboyProps[]>(
        `/motoboys/${site.serviceId}/list`
      )
      setMotoboys(data)
    }
  }, [site.motoboys, site.serviceId])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  const handleUpdateMotoboys = useCallback(
    async (updatedMotoboy: MotoboyProps) => {
      try {
        clearTimeout(time)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = setTimeout(async () => {
          const { data } = await api('user-api').put(
            `motoboys/${updatedMotoboy.id}`,
            updatedMotoboy
          )

          setSite({
            ...site,
            motoboys: motoboys.map((motoboy) =>
              motoboy.id === updatedMotoboy.id ? updatedMotoboy : motoboy
            )
          })

          showToast({
            message: 'Status do motoboy atualizado com sucesso!',
            type: 'success'
          })
        }, 1000)
      } catch (err) {
        showToast({
          message: 'Aconteceu um erro!',
          type: 'error'
        })
      }
    },
    [setSite, showToast, site]
  )

  const handleUpdateStatusMotoboy = useCallback(
    ({ id }) => {
      const updatedMotoboy = motoboys.find((item) => item.id === id)
      updatedMotoboy.available = !updatedMotoboy.available

      setMotoboys((prevMotoboys) =>
        prevMotoboys.map((item) => (item.id === id ? updatedMotoboy : item))
      )
      handleUpdateMotoboys(updatedMotoboy)
    },
    [motoboys, handleUpdateMotoboys]
  )

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, motoboy: MotoboyProps) => {
      setMotoboySelected(motoboy)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggleModal = useCallback((motoboy: MotoboyProps = null) => {
    setMotoboySelected(motoboy)
    setIsOpen((prevState: boolean) => !prevState)
  }, [])

  const handleModalAreaEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleModal(motoboySelected)
  }, [handleMenuOptionsClose, handleToggleModal, motoboySelected])

  const handleDeleteMotoboy = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir o motoboy "${motoboySelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const motoboyIndex = motoboys.findIndex(
            (c) => c.id === motoboySelected.id
          )
          const updatedMotoboys = [...motoboys]
          const filteredMotoboys = updatedMotoboys.filter(
            (_, i) => i !== motoboyIndex
          )

          setMotoboys(filteredMotoboys)
          await api('user-api').delete(`motoboys/${motoboySelected.id}`)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [motoboySelected, motoboys, handleMenuOptionsClose, showToast])

  return (
    <>
      <ModalAddMotoboy
        isOpen={isOpen}
        motoboy={motoboySelected}
        motoboys={motoboys}
        setMotoboys={setMotoboys}
        handleToggle={handleToggleModal}
      />

      <StyledMenu>
        <StyledMenuItem onClick={handleModalAreaEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteMotoboy}>Excluir</StyledMenuItem>
      </StyledMenu>

      <S.Container>
        <S.Title>Motoboys</S.Title>

        <S.Options>
          <S.ButtonAdd type="button" onClick={() => handleToggleModal()}>
            <FiPlus /> Adicionar motoboy
          </S.ButtonAdd>
        </S.Options>

        <S.Areas>
          {motoboys.map((item) => (
            <S.Area key={item.id}>
              <S.AreaHeader>
                <S.AreaHeaderLeft>
                  <label htmlFor={item.name}>{item.name}</label>
                </S.AreaHeaderLeft>

                <S.AreaHeaderRight>
                  <S.AreaStatus
                    onChange={() => handleUpdateStatusMotoboy({ id: item.id })}
                    checked={item.available}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={60}
                    handleDiameter={12}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <button
                    type="button"
                    title="Opções"
                    onClick={(e) => handleMenuOptionsOpen(e, item)}
                  >
                    <FiMoreVertical />
                  </button>
                </S.AreaHeaderRight>
              </S.AreaHeader>

              <S.MotoboyDescription>
                <p>CPF: {masks.cpf(item.cpf)}</p>
                <p>Telefone: {masks.phone(item.phone)}</p>
                <p>Placa: {item.placa}</p>
              </S.MotoboyDescription>
            </S.Area>
          ))}
        </S.Areas>
      </S.Container>
    </>
  )
}

export default Motoboys
