import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { api } from 'services/api'
import { ServiceProps } from 'utils/props'
import { logout } from 'services/auth'
import { useAuth } from 'contexts/UserContext'

import Spinner from 'components/Spinner'
import Service from 'components/Service'
import NewServiceModal from './NewServiceModal'

import * as S from './styles'

interface Props extends RouteComponentProps {}

const Services: React.FC<Props> = ({ history }) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [services, setServices] = useState<ServiceProps[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const loadServices = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await api('user-api').get<ServiceProps[]>('services')

      setServices(data)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((prevState) => !prevState)
  }, [])

  useEffect(() => {
    loadServices()
  }, [loadServices])

  const handleLogout = useCallback(() => {
    logout()
    history.push('/auth/login/')
  }, [history])

  const onOpenService = useCallback(
    ({ type, serviceId }) => {
      localStorage.setItem('last-active-service-type', type)
      localStorage.setItem('last-active-service-id', serviceId)

      if (type === 'site') {
        history.push('/dashboard/orders/')
      } else if (type === 'pdv') {
      } else if (type === 'totemsselfservice') {
        history.push('/totem/')
      }
      // history.push(`/${serviceId}/dashboard/`);
    },
    [history]
  )

  if (loading) return <Spinner />

  return (
    <S.Container>
      <NewServiceModal
        isOpen={isModalOpen}
        handleToggle={handleToggleModal}
        loadServices={loadServices}
      />

      <S.Content>
        <h1>Olá {user.name}!</h1>

        <p>
          <span>Qual serviço deseja acessar?</span>

          <button type="button" onClick={handleLogout}>
            Sair
          </button>
        </p>

        {services?.length > 0 ? (
          <S.Services>
            {services?.map((service) => (
              <Service
                key={service.id}
                service={service}
                handleClick={onOpenService}
              />
            ))}
          </S.Services>
        ) : (
          <S.Empty>
            <h1>Nenhum serviço encontrado</h1>
          </S.Empty>
        )}

        <S.Button type="button" onClick={handleToggleModal}>
          Criar novo serviço
        </S.Button>
      </S.Content>
    </S.Container>
  )
}

export default Services
