import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'
import InputCurrency from 'react-intl-currency-input'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Options = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  margin-top: 2rem;

  ${media.lessThan('small')`
    flex-direction: column;
    justify-content: center;
  `};
`

export const ButtonAdd = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.blue};
  border-radius: 1rem;

  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};

  svg {
    margin-right: 1rem;
  }
`

export const ButtonUpload = styled.label`
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 1.8rem;
    font-weight: 500;
    font-style: normal;
  }

  input {
    display: none;
  }
`

export const ButtonDownload = styled.button`
  font-size: 1.8rem;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
`

export const Areas = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  margin-top: 3rem;

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  padding: 1.5rem;
`

export const AreaHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AreaHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 2rem;
    text-align: left;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const AreaHeaderRight = styled.div`
  display: flex;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};

    margin-left: 1rem;
  }
`

export const AreaStatus = styled(Switch)``

export const MotoboyDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  text-align: left;

  > p {
    font-size: 1.4rem;
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }

  margin-top: 1rem;
`
