import React, { useCallback, useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { FiCopy, FiMoreVertical, FiPlus } from 'react-icons/fi'
import { FaArrowsAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'

import { RecipeGroupProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'

import { Menu, MenuItem } from 'components/Menu'
import OptionModal from './RecipeModal'

import * as S from './styles'

interface Props {
  optionGroup: RecipeGroupProps[]
  setOptionGroup(newState: RecipeGroupProps[]): void
}

const Recipe: React.FC<Props> = ({ optionGroup, setOptionGroup }) => {
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [optionSelected, setOptionSelected] = useState(null)

  const handleToggle = useCallback((option = null) => {
    setOptionSelected(option)
    setIsOpen((prevState: boolean) => !prevState)
  }, [])

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, option: RecipeGroupProps) => {
      setOptionSelected(option)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleUpdateStatusOption = useCallback(
    (id: string) => {
      const newState = optionGroup.map((option) => {
        if (option.id === id) {
          return { ...option, available: !option.available }
        }

        return option
      })

      setOptionGroup(newState)
    },
    [optionGroup, setOptionGroup]
  )

  const handleModalOptionEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggle(optionSelected)
  }, [handleMenuOptionsClose, handleToggle, optionSelected])

  const handleOptionDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir o complemento "${optionSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const optionIndex = optionGroup.findIndex(
            (c) => c.id === optionSelected.id
          )

          const updatedOptionGroup = [...optionGroup]
          const filteredOption = updatedOptionGroup.filter(
            (_, i) => i !== optionIndex
          )

          setOptionGroup(filteredOption)
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({
        type: 'error',
        message: message
      })
    }
  }, [
    handleMenuOptionsClose,
    optionSelected,
    optionGroup,
    setOptionGroup,
    showToast
  ])

  return (
    <>
      <Menu anchorEl={anchorEl} handleMenuOptionsClose={handleMenuOptionsClose}>
        <MenuItem onClick={handleModalOptionEdit}>Editar</MenuItem>
        <MenuItem onClick={handleOptionDelete}>Excluir</MenuItem>
      </Menu>

      <OptionModal
        isOpen={isOpen}
        handleToggle={handleToggle}
        optionGroup={optionGroup}
        setOptionGroup={setOptionGroup}
        option={optionSelected}
      />

      <S.OptionsButtons>
        <S.ButtonAdd type="button" onClick={() => handleToggle(null)}>
          <FiPlus /> Adicionar item
        </S.ButtonAdd>

        <S.ButtonCopy type="button" onClick={() => console.log('Copy')}>
          <FiCopy /> Copiar de outro produto
        </S.ButtonCopy>
      </S.OptionsButtons>

      <S.Container
        handle=".handle"
        animation={150}
        list={optionGroup}
        setList={(newState: RecipeGroupProps[]) => setOptionGroup(newState)}
      >
        {optionGroup.map((option, index) => (
          <S.Option key={index}>
            <S.OptionHeader key={option.id}>
              <S.OptionHeaderLeft>
                <button type="button" className="handle">
                  <FaArrowsAlt />
                </button>

                <div>
                  <h1>{option.name}</h1>
                </div>
              </S.OptionHeaderLeft>

              <S.OptionHeaderRight>
                <S.OptionHeaderStatus
                  onChange={() => handleUpdateStatusOption(option.id)}
                  checked={option.available}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />

                <button
                  type="button"
                  title="Opções"
                  onClick={(e) => handleMenuOptionsOpen(e, option)}
                >
                  <FiMoreVertical />
                </button>
              </S.OptionHeaderRight>
            </S.OptionHeader>
          </S.Option>
        ))}
      </S.Container>
    </>
  )
}

export default Recipe
