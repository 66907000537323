import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Banners = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  padding: 1.5rem;
`

export const BannerHeader = styled.header`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
`

export const BannerHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 2rem;
    text-align: left;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const BannerHeaderRight = styled.div`
  display: flex;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};

    margin-left: 1rem;
  }
`

export const BannerStatus = styled(Switch)``

export const Image = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  max-height: 20rem;
  /* width: 40rem; */
  /* height: 20rem; */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    padding: 0.8rem;

    border-radius: 1.5rem;

    ${media.lessThan('small')`
      width: 100%;
      height: 100%;
      max-height: 20rem;
    `};
  }

  svg {
    width: 10rem;
    height: 10rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`
