import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import 'react-responsive-modal/styles.css'

import { ServiceProps } from 'utils/props'
import { api } from 'services/api'
import { useAuth } from 'contexts/UserContext'
import { logout } from 'services/auth'

import ServiceCard from 'components/ServiceCard'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(): void
  setLastActiveServiceId(newLastActiveServiceId: string): void
}

const ListServicesModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  setLastActiveServiceId
}) => {
  const { user } = useAuth()
  const history = useHistory()
  const [services, setServices] = useState<ServiceProps[]>([])

  const onOpenService = useCallback(
    ({ serviceId }) => {
      localStorage.setItem('last-active-service-id', serviceId)
      setLastActiveServiceId(serviceId)
    },
    [setLastActiveServiceId]
  )

  const loadServices = useCallback(async () => {
    const { data } = await api('user-api').get<ServiceProps[]>(
      'services/employees'
    )

    setServices(data)
  }, [])

  useEffect(() => {
    loadServices()
  }, [loadServices])

  const handleLogout = useCallback(() => {
    logout()
    history.push('/auth/login/')
  }, [history])

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={handleToggle}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: '#1B242F',
          borderRadius: '1.5rem',
          maxWidth: '800px'
        }
      }}
    >
      <S.Content>
        <h1>Olá {user.name}!</h1>
        <p>
          <span>Qual serviço deseja acessar?</span>

          <button type="button" onClick={handleLogout}>
            Sair
          </button>
        </p>

        <S.Services>
          {services
            ?.filter((item) => item.site)
            .map((service) => (
              <button
                key={service.id}
                type="button"
                onClick={() => onOpenService({ serviceId: service.site.id })}
              >
                <ServiceCard service={service} />
              </button>
            ))}

          {user.user && (
            <button type="button" onClick={() => history.push('/services/')}>
              <S.ManagerServices>
                <div>
                  <h1>Ir para o painel de serviços</h1>
                </div>
              </S.ManagerServices>
            </button>
          )}
        </S.Services>
      </S.Content>
    </S.Container>
  )
}

export default ListServicesModal
