import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { MenuProps, PrinterProps, PrintersModuleProps } from 'utils/props'
import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'

import Select from 'components/Select'

import * as S from './styles'

interface Props {
  isOpen: boolean
  printer?: string
  handleToggle(printer: PrinterProps | null): void
  printersModule: PrintersModuleProps
  setPrintersModule(printersModule: PrintersModuleProps): void
  handleUpdatePrinters(printers: PrinterProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const PrinterModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  printer,
  printersModule,
  setPrintersModule,
  handleUpdatePrinters
}) => {
  const { site } = useSite()
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const printerExists = useMemo(
    () => printersModule.printers.find((p) => p.name === printer),
    [printer, printersModule]
  )
  const previewName = watch('name')
  const manufacturers = [
    {
      label: 'Bematech',
      value: 'Bematech'
    },
    {
      label: 'Brother',
      value: 'Brother'
    },
    {
      label: 'Daruma',
      value: 'Daruma'
    },
    {
      label: 'Diebold',
      value: 'Diebold'
    },
    {
      label: 'Elgin',
      value: 'Elgin'
    },
    {
      label: 'Epson',
      value: 'Epson'
    },
    {
      label: 'Gainscha',
      value: 'Gainscha'
    },
    {
      label: 'Sweda',
      value: 'Sweda'
    },
    {
      label: 'Tanca',
      value: 'Tanca'
    }
  ]

  const [activatedOrder, setActivatedOrder] = useState(false)
  const [categories, setCategories] = useState({})
  const [printerCategories, setPrinterCategories] = useState({})
  const [manufacturer, setManufacturer] = useState(null)
  const [columnSize, setColumnSize] = useState(45)
  const [fontSize, setFontSize] = useState(9)

  const [categoriesSelectList, setCategoriesSelectList] = useState([])
  const [categoriesSelected, setCategoriesSelected] = useState([])

  const loadMenusData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data: deliveryMenuId } = await api('user-api').get<MenuProps>(
        `menus/${site.deliveryMenuId}`
      )

      const { data: takeoutMenuId } = await api('user-api').get<MenuProps>(
        `menus/${site.takeoutMenuId}`
      )

      const { data: indoorMenuId } = await api('user-api').get<MenuProps>(
        `menus/${site.indoorMenuId}`
      )

      const listCategories = { deliveryMenuId, takeoutMenuId, indoorMenuId }

      setCategories(listCategories)
      setCategoriesSelectList(
        Object.values(listCategories).map((item: MenuProps, index) => {
          const categoryMenu =
            item.menuId === site.deliveryMenuId
              ? 'Entrega'
              : item.menuId === site.takeoutMenuId
              ? 'Retirada'
              : 'Mesa'

          return {
            label: categoryMenu,
            options: item.categories.map((category) => ({
              label: `${categoryMenu} | ${category.name}`,
              value: {
                id: category.id,
                categoryId: Object.keys(listCategories)[index]
              }
            }))
          }
        })
      )
    } finally {
      setIsLoading(false)
    }
  }, [site])

  useEffect(() => {
    loadMenusData()
  }, [loadMenusData])

  const handleResetStats = useCallback(() => {
    reset()
    setColumnSize(45)
    setManufacturer(null)
    setPrinterCategories({})
    setCategoriesSelected([])
    setActivatedOrder(false)
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (printer) {
      setValue('name', printer, { shouldValidate: true })

      if (printerExists) {
        setActivatedOrder(printerExists.activatedOrder)
        setPrinterCategories(printerExists.categories)
        setManufacturer(printerExists.manufacturer)
        setColumnSize(printerExists.columnSize)
        setFontSize(printerExists.fontSize)
      }
    }
  }, [setValue, printer, printerExists])

  const loadSelectedCategoriesData = useCallback(() => {
    if (printerExists && Object.values(categories).length > 0) {
      const listSelectedCategories = []

      Object.keys(printerExists?.categories || []).forEach((key) =>
        Object.values(printerExists.categories[key]).forEach((item) => {
          const category = categories[key].categories.find((c) => c.id === item)
          const categoryMenu =
            categories[key].menuId === site.deliveryMenuId
              ? 'Entrega'
              : categories[key].menuId === site.takeoutMenuId
              ? 'Retirada'
              : 'Mesa'
          const categoryName = category.name

          if (category) {
            listSelectedCategories.push({
              label: `${categoryMenu} | ${categoryName}`,
              value: {
                id: item,
                categoryId: key
              }
            })
          }
        })
      )

      setCategoriesSelected(listSelectedCategories)
    }
  }, [printerExists, categories, site])

  useEffect(() => {
    loadSelectedCategoriesData()
  }, [loadSelectedCategoriesData])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleToggle, handleResetStats]
  )

  const handleActivatedOrderRadio = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target

    setActivatedOrder(value === 'true')
  }

  const handleChangeSelectedCategories = (
    values: {
      label: string
      value: {
        id: string
        categoryId: string
      }
    }[]
  ) => {
    const categories = values.reduce((prevValue, item) => {
      prevValue[item.value.categoryId] = prevValue[item.value.categoryId] || []
      prevValue[item.value.categoryId].push(item.value.id)

      return prevValue
    }, {})

    setPrinterCategories(categories)
  }

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const printerData = {
        id: printerExists ? printerExists.id : uuidv4(),
        name: data.name,
        activatedOrder,
        categories: printerCategories,
        manufacturer,
        columnSize,
        fontSize
      }

      const updatedPrinters = [...printersModule.printers]

      if (printerExists) {
        const printerIndex = printersModule.printers.findIndex(
          (c) => c.id === printerExists.id
        )

        updatedPrinters[printerIndex] = printerData as PrinterProps
      } else {
        updatedPrinters.push(printerData as PrinterProps)
      }

      try {
        setPrintersModule({
          ...printersModule,
          printers: updatedPrinters
        })
        handleUpdatePrinters(updatedPrinters)

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      printerExists,
      activatedOrder,
      printerCategories,
      manufacturer,
      columnSize,
      fontSize,
      printersModule,
      setPrintersModule,
      handleUpdatePrinters,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>{previewName && previewName}</h1>
          <p>Detalhes da impressora</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label>Pedidos</S.Label>

            <S.RadioGroup>
              <label>
                <input
                  name="activatedOrder"
                  type="radio"
                  value="true"
                  onChange={handleActivatedOrderRadio}
                  defaultChecked={activatedOrder}
                />
                <span>
                  <div>
                    <div>Ativa</div>
                    <div>A impressora irá imprimir seus pedidos</div>
                  </div>
                </span>
              </label>
              <label>
                <input
                  name="activatedOrder"
                  type="radio"
                  value="false"
                  onChange={handleActivatedOrderRadio}
                  defaultChecked={!activatedOrder}
                />
                <span>
                  <div>
                    <div>Desativada</div>
                    <div>A impressora não irá imprimir seus pedidos</div>
                  </div>
                </span>
              </label>
            </S.RadioGroup>
          </S.Col>

          <S.Col>
            <S.Label htmlFor="disabledCategories">
              Impressão por categoria
            </S.Label>

            <S.Description>
              Se você deseja que os produtos que se enquadram na categoria
              abaixo também sejam impressos, selecione as categorias
              <br />
              <b>
                (Funciona mesmo se a impressão de pedidos estiver desativada)
              </b>
            </S.Description>

            <Select
              id="disabledCategories"
              placeholder="Categorias"
              options={categoriesSelectList}
              defaultValue={categoriesSelected}
              isMultiSelect
              inputProps={{ readOnly: true }}
              isSearchable={false}
              // isOptionDisabled={(option) => {
              //   const optionExists = categoriesDisabled.some(
              //     (c) => c.value.id === option.value.id
              //   )

              //   return optionExists
              // }}
              onChange={(
                values: {
                  label: string
                  value: {
                    id: string
                    categoryId: string
                  }
                }[]
              ) => handleChangeSelectedCategories(values)}
            />
          </S.Col>

          <S.Col>
            <S.Label htmlFor="printerManufacturer">
              Fabricante da impressora
            </S.Label>

            <S.Select
              id="manufacturer"
              placeholder="Fabricantes"
              onChange={(e) => setManufacturer(e.target.value)}
            >
              <option
                defaultValue={null}
                selected={!manufacturer}
                disabled
                hidden
              >
                Selecione uma fabricante
              </option>
              {manufacturers.map((m) => (
                <option
                  key={m.value}
                  selected={manufacturer === m.value}
                  value={m.value}
                >
                  {m.label}
                </option>
              ))}
            </S.Select>
          </S.Col>

          <S.Col>
            <S.Label htmlFor="columnSize">
              Quantidade de coluna na impressão
            </S.Label>

            <S.Select
              id="columnSize"
              placeholder="Colunas"
              onChange={(e) => setColumnSize(parseInt(e.target.value))}
            >
              <option
                defaultValue={null}
                selected={!columnSize}
                disabled
                hidden
              >
                Selecione uma quantidade
              </option>
              {Array(46)
                .fill(0)
                .map((_, index) => {
                  const columnSizeAmount = 35 + index

                  return (
                    <option
                      key={columnSize}
                      selected={columnSize === columnSizeAmount}
                      value={columnSizeAmount}
                    >
                      {columnSizeAmount}
                    </option>
                  )
                })}
            </S.Select>
          </S.Col>

          <S.Col>
            <S.Label htmlFor="fontSize">Tamanho da fonte na impressão</S.Label>

            <S.Select
              id="fontSize"
              placeholder="Fonte"
              onChange={(e) => setFontSize(parseInt(e.target.value))}
            >
              <option defaultValue={null} selected={!fontSize} disabled hidden>
                Selecione uma fonte
              </option>
              <option selected={fontSize === 9} value={9}>
                Pequena
              </option>
              <option selected={fontSize === 18} value={18}>
                Média
              </option>
              <option selected={fontSize === 28} value={28}>
                Grande
              </option>
            </S.Select>
          </S.Col>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default PrinterModal
