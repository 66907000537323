import React, { useCallback } from 'react'
import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { v4 as uuidv4 } from 'uuid'

import { SizeProps } from 'utils/props'

import * as S from './styles'

interface Props {
  categorySizes: SizeProps[]
  setCategorySizes(newSizes: SizeProps[]): void
}

const SizesCategoryCard: React.FC<Props> = ({
  categorySizes,
  setCategorySizes
}) => {
  const handleSizeAdd = useCallback(() => {
    const id = uuidv4()

    setCategorySizes([
      ...categorySizes,
      {
        id,
        name: '',
        posCode: `t${String(`0${categorySizes.length + 1}`).slice(-2)}`,
        multipleToppingsSizeIds: {
          oneToppingSizeId: null,
          twoToppingsSizeId: null,
          threeToppingsSizeId: null,
          fourToppingsSizeId: null
        },
        available: true
      }
    ])
  }, [categorySizes, setCategorySizes])

  const handleSizeRemove = useCallback(
    (sizeIndex: number) => {
      const updatedCategorySizes = [...categorySizes]
      const filteredCategorySizes = updatedCategorySizes.filter(
        (_, i) => i !== sizeIndex
      )

      setCategorySizes(filteredCategorySizes)
    },
    [categorySizes, setCategorySizes]
  )

  const handleSizeName = useCallback(
    (sizeIndex: number, value: string) => {
      const newSizes = [...categorySizes]

      Object.assign(newSizes[sizeIndex], {
        name: value
      })

      setCategorySizes(newSizes)
    },
    [categorySizes, setCategorySizes]
  )

  const handleSizeActive = useCallback(
    (sizeIndex: number, size: string, finalId: number) => {
      const newSizes = [...categorySizes]

      Object.assign(newSizes[sizeIndex], {
        multipleToppingsSizeIds: {
          ...newSizes[sizeIndex].multipleToppingsSizeIds,
          [size]: newSizes[sizeIndex].multipleToppingsSizeIds[size]
            ? null
            : `${newSizes[sizeIndex].id}${finalId > 1 ? finalId : ''}`
        }
      })

      setCategorySizes(newSizes)
    },
    [categorySizes, setCategorySizes]
  )

  return (
    <S.Container>
      <span>Tamanhos</span>

      {categorySizes.map((item, index) => {
        const {
          oneToppingSizeId,
          twoToppingsSizeId,
          threeToppingsSizeId,
          fourToppingsSizeId
        } = item.multipleToppingsSizeIds

        return (
          <div key={index}>
            <div>
              <label htmlFor={item.id}>Nome</label>
              <input
                id={item.id}
                type="text"
                value={item.name}
                className={item.name.trim().length <= 0 ? 'is-invalid' : ''}
                onChange={(e) => handleSizeName(index, e.currentTarget.value)}
              />
            </div>

            <div>
              <span>Qtd. Sabores</span>

              <div className="sizes">
                <button
                  type="button"
                  className={oneToppingSizeId ? 'active' : ''}
                  onClick={() => handleSizeActive(index, 'oneToppingSizeId', 1)}
                >
                  1
                </button>

                <button
                  type="button"
                  className={twoToppingsSizeId ? 'active' : ''}
                  onClick={() =>
                    handleSizeActive(index, 'twoToppingsSizeId', 2)
                  }
                >
                  2
                </button>

                <button
                  type="button"
                  className={threeToppingsSizeId ? 'active' : ''}
                  onClick={() =>
                    handleSizeActive(index, 'threeToppingsSizeId', 3)
                  }
                >
                  3
                </button>

                <button
                  type="button"
                  className={fourToppingsSizeId ? 'active' : ''}
                  onClick={() =>
                    handleSizeActive(index, 'fourToppingsSizeId', 4)
                  }
                >
                  4
                </button>
              </div>
            </div>

            <button
              type="button"
              title="Remover"
              onClick={() => handleSizeRemove(index)}
            >
              <FiTrash2 />
            </button>
          </div>
        )
      })}

      <button type="button" onClick={handleSizeAdd}>
        <FiPlus /> Novo tamanho
      </button>
    </S.Container>
  )
}

export default SizesCategoryCard
