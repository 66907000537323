import styled, { css } from 'styled-components'
import { Modal } from 'react-responsive-modal'
import media from 'styled-media-query'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  ${media.greaterThan('medium')`
    max-width: 700px;
    min-width: 700px;
    width: 100%;
  `}
`

export const Header = styled.header`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  ${({ theme }) => css`
    h1 {
      font-weight: bold;
      font-size: 2rem;

      color: ${theme.colors.text.cards};
    }

    p {
      font-size: 1.8rem;

      color: ${theme.colors.text.cards};
    }
  `}
`

export const Cards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  gap: 2rem;

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`
