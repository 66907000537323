import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { CategoryProps, RecipeGroupProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { useStock } from 'contexts/StockContext'
import { useWindowSize } from 'hooks/useWindowSize'
import { taxableUnits } from 'utils/taxableUnits'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: CategoryProps | null): void
  optionGroup: RecipeGroupProps[]
  setOptionGroup(newState: RecipeGroupProps[]): void
  option: RecipeGroupProps
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  posCode: yup.string()
})

interface FormDataProps {
  name: string
  posCode: string
}

const RecipeModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  optionGroup,
  setOptionGroup,
  option
}) => {
  const { showToast } = useToast()
  const { stocks } = useStock()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const previewPosCode = watch('posCode')

  const [unit, setUnit] = useState<'UN' | 'KG' | 'G'>('UN')
  const [isRemovable, setIsRequired] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [minQuantity, setMinQuantity] = useState(0)

  useEffect(() => {
    if (option) {
      setValue('name', option.name)
      if (option.posCode) {
        setValue('posCode', option.posCode, { shouldValidate: true })
      }
      setUnit(option.unit)
      setIsRequired(option.isRemovable)
      setQuantity(option.quantity)
      setMinQuantity(option.min)
    }
  }, [option, setValue])

  const handleResetStats = useCallback(() => {
    reset()
    setMinQuantity(0)
    setQuantity(1)
    setUnit('UN')
    setIsRequired(false)
    setIsLoading(false)
  }, [reset])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleChangeTaxableUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as 'UN' | 'KG' | 'G'

    setUnit(value)
    // setValue('posCode', '')
  }

  const handleChangePosCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value

    if (value === 'remove') {
      setValue('posCode', null, { shouldValidate: true })
      return
    }

    setValue('posCode', value, { shouldValidate: true })
  }

  const handleRemovableRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value === 'true'

      setIsRequired(value)
    },
    []
  )

  const handleDecrementButton = useCallback(
    (button: string) => {
      if (button === 'minQuantity' && minQuantity > 0) {
        setMinQuantity((prevState) => prevState - 1)
      } else if (quantity > 1) {
        if (quantity - 1 < minQuantity) {
          setMinQuantity((prevState) => prevState - 1)
        }

        setQuantity((prevState) => prevState - 1)
      }
    },
    [minQuantity, quantity]
  )

  const handleIncrementButton = useCallback((button: string) => {
    if (button === 'minQuantity') {
      setMinQuantity((prevState) => prevState + 1)
    } else {
      setQuantity((prevState) => prevState + 1)
    }
  }, [])

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const { name, posCode } = data

      const optionGroupData = {
        id: option ? option.id : uuidv4(),
        name,
        unit,
        posCode: posCode || null,
        isRemovable,
        quantity,
        min: minQuantity,
        max: quantity,
        available: true
      }

      const newOptions = [...optionGroup]

      if (option) {
        const optionIndex = optionGroup.findIndex((c) => c.id === option.id)

        Object.assign(newOptions[optionIndex], {
          ...optionGroupData
        })
      } else {
        newOptions.push(optionGroupData)
      }

      try {
        setOptionGroup(newOptions)
        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({
          type: 'error',
          message
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      option,
      unit,
      isRemovable,
      quantity,
      minQuantity,
      optionGroup,
      setOptionGroup,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: '#293949',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo ingrediente'}
          </h1>
        </S.Header>

        <S.Form>
          <S.Table>
            <S.Col>
              <S.Label htmlFor="name">
                Nome do ingrediente (obrigatório)
              </S.Label>
              <S.Input
                id="name"
                name="name"
                placeholder="Ex: Pão"
                className={errors.name && 'is-invalid'}
                {...register('name')}
              />
            </S.Col>

            <S.InputWrapper>
              <S.Col>
                <S.Label htmlFor="unit">Unidade Tributável</S.Label>
                <S.Select onChange={handleChangeTaxableUnit}>
                  {taxableUnits.map((taxableUnit) => (
                    <option
                      key={taxableUnit.unit}
                      selected={taxableUnit.unit === unit}
                      value={taxableUnit.unit}
                    >
                      {taxableUnit.unit} - {taxableUnit.name}
                    </option>
                  ))}
                </S.Select>
              </S.Col>

              <S.Col>
                <S.Label htmlFor="posCode">Código PDV</S.Label>
                <S.Select onChange={handleChangePosCode}>
                  {previewPosCode && previewPosCode.trim().length > 0 ? (
                    <option value="remove">Remover estoque</option>
                  ) : (
                    <option selected={!previewPosCode} disabled hidden>
                      Selecione um estoque
                    </option>
                  )}

                  {stocks
                    // .filter(stock =>
                    //   stock.methodsToAdd.some(methodToAdd => methodToAdd.type === unit)
                    // )
                    .map((stock) => (
                      <option
                        key={stock.id}
                        selected={stock.code === previewPosCode}
                        value={stock.code}
                      >
                        {stock.code} - {stock.name}
                      </option>
                    ))}
                </S.Select>
              </S.Col>
            </S.InputWrapper>

            <S.Col>
              <S.Label htmlFor="required">Removivel</S.Label>

              <S.RadioGroup>
                <label>
                  <input
                    name="required"
                    type="radio"
                    value="false"
                    onChange={handleRemovableRadio}
                    defaultChecked={isRemovable === false}
                  />
                  <span>
                    <div>
                      <div>Não</div>
                      <div>O cliente não pode remover este ingrediente</div>
                    </div>
                  </span>
                </label>
                <label>
                  <input
                    name="required"
                    type="radio"
                    value="true"
                    onChange={handleRemovableRadio}
                    defaultChecked={isRemovable === true}
                  />
                  <span>
                    <div>
                      <div>Sim</div>
                      <div>O cliente pode remover este ingrediente</div>
                    </div>
                  </span>
                </label>
              </S.RadioGroup>
            </S.Col>

            <S.Col>
              <S.Removable>
                <S.OptionQuantity>
                  <div>
                    <span>Quantidade</span>

                    <div className="option">
                      <button
                        type="button"
                        disabled={quantity === 1}
                        onClick={() => handleDecrementButton('quantity')}
                      >
                        -
                      </button>
                      <span>{quantity}</span>
                      <button
                        type="button"
                        onClick={() => handleIncrementButton('quantity')}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  {isRemovable && (
                    <div>
                      <span>Mínimo</span>

                      <div className="option">
                        <button
                          type="button"
                          disabled={minQuantity === 0}
                          onClick={() => handleDecrementButton('minQuantity')}
                        >
                          -
                        </button>
                        <span>{minQuantity}</span>
                        <button
                          type="button"
                          disabled={minQuantity + 1 > quantity}
                          onClick={() => handleIncrementButton('minQuantity')}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  )}
                </S.OptionQuantity>
              </S.Removable>
            </S.Col>
          </S.Table>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="button"
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default RecipeModal
