import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FiMoreVertical, FiPlus } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'

import { StockProps } from 'utils/props'
import { api } from 'services/api'
import { useStock } from 'contexts/StockContext'
import { useToast } from 'contexts/ToastContext'
import { taxableUnits } from 'utils/taxableUnits'

import Spinner from 'components/Spinner'
import StockModal from './StockModal'
import AddStockModal from './AddStockModal'

import * as S from './styles'

const Stock: React.FC = () => {
  const { showToast } = useToast()
  const { isLoading, stocks, setStocks } = useStock()
  const themeContext = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isStockModalOpen, setIsStockModalOpen] = useState(false)
  const [isAddStockModalOpen, setIsAddStockModalOpen] = useState(false)
  const [stockSelected, setStockSelected] = useState<StockProps>(null)

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, stock: StockProps) => {
      setStockSelected(stock)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setStockSelected(null)
    setAnchorEl(null)
  }, [])

  const handleToggleStockModal = useCallback((stock: StockProps = null) => {
    setStockSelected(stock)
    setIsStockModalOpen((prevState: boolean) => !prevState)
  }, [])

  const handleToggleAddStockModal = useCallback((stock: StockProps = null) => {
    setStockSelected(stock)
    setIsAddStockModalOpen((prevState: boolean) => !prevState)
  }, [])

  const handleModalStockEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleStockModal(stockSelected)
  }, [handleMenuOptionsClose, handleToggleStockModal, stockSelected])

  const handleUpdateStock = useCallback(
    async (id, stock: StockProps) => {
      try {
        const { data } = await api('user-api').put(`stocks/${id}`, stock)

        setStocks((prevState) =>
          prevState.map((stock) => (stock.id === id ? data : stock))
        )

        showToast({
          message: 'Estoque atualizado com sucesso!',
          type: 'success'
        })
      } catch (err) {
        showToast({
          message: 'Aconteceu um erro!',
          type: 'error'
        })
      }
    },
    [showToast]
  )

  const handleStockDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir o estoque "${stockSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const updatedStocks = [...stocks]
          const filteredStocks = updatedStocks.filter(
            (item) => item.id !== stockSelected.id
          )

          try {
            setStocks(filteredStocks)

            await api('user-api').delete(`stocks/${stockSelected.id}`)
          } catch (err) {
            const message =
              err?.response?.data?.error ||
              'Por Favor entrar em contato com suporte.'

            showToast({ message, type: 'error' })
          }
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [handleMenuOptionsClose, stockSelected, stocks, setStocks, showToast])

  const handleStockStatusUpdate = useCallback(
    ({ id }) => {
      const updatedStocks = stocks.map((item) => {
        if (item.id === id) {
          handleUpdateStock(id, { ...item, available: !item.available })
          return { ...item, available: !item.available }
        }

        return item
      })

      setStocks(updatedStocks)
    },
    [handleUpdateStock, setStocks, stocks]
  )

  const handleCopyCode = useCallback(
    (code: string) => {
      const input = document.body.appendChild(document.createElement('input'))
      input.value = code
      input.focus()
      input.select()
      document.execCommand('copy')
      input.parentNode.removeChild(input)

      showToast({
        message: 'Código do estoque copiado com sucesso!',
        type: 'success'
      })
    },
    [showToast]
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalStockEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleStockDelete}>Excluir</StyledMenuItem>
      </StyledMenu>

      <StockModal
        isOpen={isStockModalOpen}
        handleToggle={handleToggleStockModal}
        stock={stockSelected}
      />

      <AddStockModal
        isOpen={isAddStockModalOpen}
        handleToggle={handleToggleAddStockModal}
        stock={stockSelected}
      />

      <S.Container>
        <S.Title>Estoque</S.Title>

        <S.Options>
          <S.ButtonAdd type="button" onClick={() => handleToggleStockModal()}>
            <FiPlus /> Criar estoque
          </S.ButtonAdd>
        </S.Options>

        <S.Stocks>
          {stocks.map((item, index) => (
            <S.Stock key={item.id}>
              <S.StockHeader>
                <S.StockHeaderLeft>
                  <label htmlFor={item.name}>{item.name}</label>
                </S.StockHeaderLeft>

                <S.StockHeaderRight>
                  <S.StockStatus
                    onChange={() => handleStockStatusUpdate({ id: item.id })}
                    checked={item.available}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={60}
                    handleDiameter={12}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <button
                    type="button"
                    title="Opções"
                    onClick={(e) => handleMenuOptionsOpen(e, item)}
                  >
                    <FiMoreVertical />
                  </button>
                </S.StockHeaderRight>
              </S.StockHeader>

              <S.Form>
                <S.Col>
                  <S.Label htmlFor={`code_${index}`}>Código</S.Label>

                  <S.Copy
                    id={`code_${index}`}
                    onClick={() => handleCopyCode(item.code)}
                  >
                    {item.code}
                  </S.Copy>
                </S.Col>

                {taxableUnits.map((taxableUnit) => (
                  <S.Col key={taxableUnit.unit}>
                    <S.Label
                      htmlFor={`${taxableUnit.quantityInStock}_${index}`}
                    >
                      {taxableUnit.unit} - {taxableUnit.name}
                    </S.Label>

                    <S.QuantityInStock
                      id={`${taxableUnit.quantityInStock}_${index}`}
                      lowStock={
                        item[taxableUnit.quantityInStock] <
                        item[taxableUnit.minimumQuantityInStock]
                      }
                    >
                      {item[taxableUnit.quantityInStock]}
                    </S.QuantityInStock>
                  </S.Col>
                ))}
              </S.Form>
            </S.Stock>
          ))}
        </S.Stocks>
      </S.Container>
    </>
  )
}

export default Stock
