import React, { memo, useCallback, useState } from 'react'
import Swal from 'sweetalert2'

import { CategoryProps } from 'utils/props'
import { useTotem } from 'contexts/TotemContext'
import { useToast } from 'contexts/ToastContext'

import MenuCard from 'components/MenuCard'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(): void
  categories: CategoryProps[]
  setCategories(categories: CategoryProps[]): void
  handleUpdateCategories({ value }: { value: CategoryProps[] }): void
}

const CopyCategoryModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  categories,
  setCategories,
  handleUpdateCategories
}) => {
  const { totem } = useTotem()
  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const handleCopyCategories = useCallback(
    async (selectedCategories: CategoryProps[]) => {
      setIsLoading(true)

      try {
        Swal.fire({
          title:
            'Se você copiar outro cardápio, todos os itens aqui serão substituídos. Deseja continuar?',
          icon: 'question',
          showCancelButton: true,
          reverseButtons: false,
          confirmButtonColor: '#50A773',
          cancelButtonColor: '#EA1D2C',
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.value) {
            handleUpdateCategories({ value: selectedCategories })
            setCategories(selectedCategories)

            handleToggle()
          }
        })
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [handleUpdateCategories, setCategories, handleToggle, showToast]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={handleToggle}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: '#293949',
          borderRadius: '1.5rem',
          maxWidth: '800px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>Copiar cardápio</h1>
          <p>Selecione o cardápio que deseja copiar</p>
        </S.Header>

        <S.Cards>
          <button
            type="button"
            // onClick={() => handleCopyCategories(site.deliveryMenu.categories)}
          >
            <MenuCard mainBg>
              <img src="/img/icons/menu/delivery.svg" alt="Entrega" />

              <div>
                <h1>Entrega</h1>
                <span>O cliente recebe o pedido em casa</span>
              </div>
            </MenuCard>
          </button>

          <button
            type="button"
            // onClick={() => handleCopyCategories(site.takeoutMenu.categories)}
          >
            <MenuCard mainBg>
              <img src="/img/icons/menu/takeout.svg" alt="Retirada" />

              <div>
                <h1>Retirada</h1>
                <span>
                  O cliente pede e retira diretamente no estabelecimento.
                </span>
              </div>
            </MenuCard>
          </button>

          <button
            type="button"
            // onClick={() => handleCopyCategories(site.indoorMenu.categories)}
          >
            <MenuCard mainBg>
              <img src="/img/icons/menu/table.svg" alt="Mesa" />

              <div>
                <h1>Mesa</h1>
                <span>O cliente faz o pedido pelo Qr na mesa</span>
              </div>
            </MenuCard>
          </button>
        </S.Cards>
      </S.Content>
    </S.Container>
  )
}

export default CopyCategoryModal
