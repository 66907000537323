import { MdClose } from 'react-icons/md'
import * as S from './styles'
import React, { Fragment, useContext, useState } from 'react'
import { MotoboyProps, OrderProps } from 'utils/props'
import { ThemeContext } from 'styled-components'
import { de } from 'date-fns/locale'
import { useSite } from 'contexts/SiteContext'
import { api } from 'services/api'

interface Props {
  isOpen: boolean
  order: OrderProps
  orders: OrderProps[]
  handleClose: (motoboyValue?: MotoboyProps) => void
  setIsModalOrderOpen?: (value: boolean) => void
  setOrders(newState: OrderProps[]): void
  setOrder(newState: OrderProps): void
}

const SelectMotoboyModal: React.FC<Props> = ({
  isOpen,
  order,
  orders,
  setIsModalOrderOpen,
  handleClose,
  setOrders,
  setOrder
}) => {
  const [selectedMotoboy, setSelectedMotoboy] = useState<MotoboyProps | null>(
    null
  )
  const { site } = useSite()
  const themeContext = useContext(ThemeContext)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    const motoboy = JSON.parse(selectedValue)
    setSelectedMotoboy(motoboy)
  }

  const handleSave = async () => {
    if (selectedMotoboy) {
      handleClose(selectedMotoboy)

      const { data } = await api('user-api').post(`orders/motoboyOrders`, {
        motoboyId: selectedMotoboy.id,
        serviceId: site.serviceId,
        orderId: order.id
      })

      const { statusData } = await api('user-api').put(
        `orders/${order.id}/dispatch`
      )

      if (setIsModalOrderOpen) {
        setIsModalOrderOpen(false)
      }

      setOrder({
        ...order,
        orderStatus: 'DISPATCHED'
      })

      setOrders(
        orders.map((item) => {
          if (item.id === order.id) {
            return { ...item, orderStatus: 'DISPATCHED' }
          }

          return item
        })
      )
    }
  }

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleClose(null)}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: themeContext.colors.primary,
          borderRadius: '1.5rem'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <div className="left">
            <S.Title>Motoboy</S.Title>
          </div>

          <div className="right">
            <button
              type="button"
              title="Fechar"
              onClick={() => handleClose(null)}
            >
              <MdClose />
            </button>
          </div>
        </S.Header>

        <S.Box>
          <S.Select
            id="motoboy"
            onChange={handleSelectChange}
            value={selectedMotoboy ? JSON.stringify(selectedMotoboy) : ''}
          >
            <option value="" disabled>
              Selecione um motoboy
            </option>
            {site.motoboys
              .filter((item) => item.available)
              .map((item) => (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.name}
                </option>
              ))}
          </S.Select>
          <S.ButtonGreen
            type="button"
            className="green"
            onClick={handleSave}
            disabled={!selectedMotoboy}
          >
            Salvar
          </S.ButtonGreen>
        </S.Box>
      </S.Content>
    </S.Container>
  )
}

export default SelectMotoboyModal
