import React, { useCallback } from 'react'
import { MdImage } from 'react-icons/md'

import * as S from './styles'

interface Props {
  id: string
  image: string
  handleChange(id: string, image: File): void
}

const InputImage: React.FC<Props> = ({ id, image, handleChange }) => {
  const handleChangeImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileId = e.target.dataset.id
      const file = e.target.files[0]

      if (!file) return

      handleChange(fileId, file)
    },
    [handleChange]
  )

  return (
    <S.Container>
      <label htmlFor={`${id}_image`} className={!image && 'is-invalid'}>
        {image ? (
          <img
            src={
              typeof image === 'string'
                ? image
                : URL.createObjectURL(image as File)
            }
            alt="Banner"
          />
        ) : (
          <div>
            <MdImage />
            <strong>Escolher imagem</strong>
          </div>
        )}

        <input
          type="file"
          id={`${id}_image`}
          accept="image/*"
          data-id={id}
          data-file={image}
          onChange={handleChangeImage}
        />
      </label>
    </S.Container>
  )
}

export default InputImage
