import styled, { css } from 'styled-components'
import { Modal } from 'react-responsive-modal'
import media from 'styled-media-query'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  padding: 2rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}

  h1 {
    font-style: normal;
    font-size: 2rem;

    color: #f7fdff;
  }

  p {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2rem;

    span {
      font-style: 400;
      font-size: 2rem;

      color: #f7fdff;
    }

    button {
      font-style: 700;
      font-size: 2rem;

      color: ${(props) => props.theme.colors.red};
    }
  }
`

export const Services = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    column-gap: ${theme.spacings.small};
    row-gap: ${theme.spacings.medium};

    ${media.greaterThan('medium')`
      grid-template-columns: repeat(3, 1fr);
      column-gap: 4rem;
      row-gap: 4rem;
    `};
  `}
`

export const ManagerServices = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
  min-height: 9.5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  img {
    width: 10rem;
    height: 10rem;
  }

  div {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }

    span {
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`
