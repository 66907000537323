import moment from 'moment-timezone'

const colors = ['#28cf8a', '#ff6944', '#8b65e0', '#14ff00']

const parseBarData = (data) => {
  const parsedData = data.dates.map((date, i) => {
    const parsedDate = moment(date).format('DD/MM')

    return {
      notParsedDate: date,
      day: parsedDate,
      Faturamento: data.faturamento[i].value,
      'Custo dos Produtos': data.custoProdutos[i].value,
      'Ticket Médio': data.ticketMedio[i].value,
      'Lucro Líquido': data.lucroLiquido[i].value
    }
  })

  return parsedData
}

const parseLineData = (data) => {
  const parsedData = [
    {
      id: 'Faturamento',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.faturamento[i].value
      }))
    },
    {
      id: 'Custo dos Produtos',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.custoProdutos[i].value
      }))
    },
    {
      id: 'Ticket Médio',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.ticketMedio[i].value
      }))
    },
    {
      id: 'Lucro Líquido',
      data: data.dates.map((date, i) => ({
        notParsedDate: date,
        x: moment(date).format('DD/MM'),
        y: data.lucroLiquido[i].value
      }))
    }
  ]

  return parsedData
}

const parsedCustomSpendsDataOut = (customSpends) => {
  const parsedData = [
    {
      id: 'App',
      label: 'App',
      value: customSpends.appOut,
      color: 'bg-purple-9'
    },
    {
      id: 'Marketing',
      label: 'Marketing',
      value: customSpends.marketingOut,
      color: 'bg-purple-7'
    },
    {
      id: 'Funcionários',
      label: 'Funcionários',
      value: customSpends.employeeOut,
      color: 'bg-purple-5'
    },
    {
      id: 'Custos',
      label: 'Custos',
      value: customSpends.costOut,
      color: 'bg-purple-3'
    },
    {
      id: 'Nenhum',
      label: 'Nenhum',
      value: customSpends.noneOut,
      color: 'bg-purple-1'
    }
  ]

  return parsedData
}

const parsedCustomSpendsDataIn = (customSpends) => {
  const parsedData = [
    {
      id: 'App',
      label: 'App',
      value: customSpends.appIn,
      color: 'bg-purple-9'
    },
    {
      id: 'Funcionários',
      label: 'Funcionários',
      value: customSpends.employeeIn,
      color: 'bg-purple-5'
    },
    {
      id: 'Custos',
      label: 'Custos',
      value: customSpends.costIn,
      color: 'bg-purple-3'
    },
    {
      id: 'Nenhum',
      label: 'Nenhum',
      value: customSpends.noneIn,
      color: 'bg-purple-1'
    }
  ]

  return parsedData
}

const parseProjectionData = (data) => {
  const parsedData = data.map((projectionDate) => {
    const parsedDate = moment(projectionDate.day).format('DD/MM')

    return {
      day: parsedDate,
      notParsedDate: projectionDate.day,
      faturamento: projectionDate.faturamento,
      projection:
        projectionDate.projection - projectionDate.faturamento < 0
          ? 0
          : projectionDate.projection - projectionDate.faturamento
    }
  })

  return parsedData
}

const parseTooltipData = (data, notParsedDate) => {
  const index = data.dates.indexOf(notParsedDate)
  const [
    faturamentoColor,
    custoProdutosColor,
    ticketMedioColor,
    lucroLiquidoColor
  ] = colors

  return {
    data: {
      date: moment(data.dates[index]).format('DD/MM/YYYY'),
      faturamento: data.faturamento[index].value,
      custoProdutos: data.custoProdutos[index].value,
      ticketMedio: data.ticketMedio[index].value,
      lucroLiquido: data.lucroLiquido[index].value
    },
    color: {
      faturamento: faturamentoColor,
      custoProdutos: custoProdutosColor,
      ticketMedio: ticketMedioColor,
      lucroLiquido: lucroLiquidoColor
    }
  }
}

const parseProjectionTooltipData = (parsedData, notParsedDate) => {
  const projectionData = parsedData.filter(
    (projection) => projection.day === notParsedDate
  )

  return projectionData[0]
}

const getTransactionTaxesColor = (bar) =>
  ({
    total: '#ffffff',
    gateway: '#ffffff',
    checkout: '#ffffff',
    tax: '#ffffff'
  }[bar.id])

const getProjectionBilletsColor = (bar) =>
  ({
    faturamento: '#ffffff',
    projection: '#ffffff'
  }[bar.id])

export {
  parseBarData,
  parseLineData,
  parseTooltipData,
  parseProjectionData,
  parseProjectionTooltipData,
  parsedCustomSpendsDataOut,
  parsedCustomSpendsDataIn,
  getTransactionTaxesColor,
  getProjectionBilletsColor,
  colors
}
