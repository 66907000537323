import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { UtilsProvider } from './UtilsContext'
import { UserProvider } from './UserContext'
import { SiteProvider } from './SiteContext'
import { TotemProvider } from './TotemContext'
import { StockProvider } from './StockContext'
import { ModuleProvider } from './ModuleContext'
import { ToastProvider } from './ToastContext'
import { IFoodProvider } from './IFoodContext'

const AppProvider: React.FC = ({ children }) => (
  <UtilsProvider>
    <BrowserRouter>
      <UserProvider>
        <SiteProvider>
          <TotemProvider>
            <StockProvider>
              <ModuleProvider>
                <IFoodProvider>
                  <ToastProvider>{children}</ToastProvider>
                </IFoodProvider>
              </ModuleProvider>
            </StockProvider>
          </TotemProvider>
        </SiteProvider>
      </UserProvider>
    </BrowserRouter>
  </UtilsProvider>
)

export default AppProvider
