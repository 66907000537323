import InputCurrency from 'react-intl-currency-input'
import { Modal } from 'react-responsive-modal'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  width: 100%;
  min-width: 400px;
`

export const Error = styled.span`
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.red};
  margin-top: 0.5rem;
`

export const Header = styled.header`
  display: flex;
  gap: 10rem;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding-bottom: 1.5rem;

  border-bottom: 2px solid ${(props) => props.theme.colors.secondary};

  ${media.lessThan('medium')`
    gap: 2rem;
    align-items: flex-start;
  `};

  .left {
    display: flex;
    gap: 5rem;
    align-items: center;

    ${media.lessThan('medium')`
      gap: 2rem;
      flex-direction: column;
      align-items: flex-start;
    `};

    > div {
      display: flex;
      gap: 4rem;
      align-items: center;

      ${media.lessThan('medium')`
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;
      `};

      h1 {
        display: flex;
        flex-direction: column;

        font-weight: 500;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};

        opacity: 0.9;

        span {
          opacity: 1;
        }
      }

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    button svg {
      width: 2rem;
      height: 2rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #5e5e5e;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-clip: padding-box;
  border: 2px solid #76cdc6;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #76cdc6;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  &:not(:first-child) {
    margin-top: 2rem;
  }
`

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
`

export const Box = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    .info {
      display: flex;
      gap: 1.5rem;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
      }

      h1 {
        font-weight: 400;
        font-size: 2rem;

        color: ${(props) => props.theme.colors.text.cards};
      }

      span {
        font-weight: 400;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};

        opacity: 0.8;
      }
    }

    .forms {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1,
      span {
        font-weight: normal;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};
      }

      h1 {
        text-transform: uppercase;
      }

      small {
        font-weight: normal;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};
      }
    }
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Payments = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`

export const Payment = styled.div`
  display: flex;
  flex-direction: column;
`

export const Select = styled.select`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #5e5e5e;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-clip: padding-box;
  border: 2px solid #76cdc6;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #76cdc6;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const ButtonGreen = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 4.5rem;
  height: 100%;

  margin-top: 0.6rem;

  background: ${(props) => props.theme.colors.secondary};
  border-radius: 0.5rem;

  padding: 1rem;

  font-weight: 700;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};

  &.green {
    background: ${(props) => props.theme.colors.green};
  }

  &.red {
    background: ${(props) => props.theme.colors.red};
  }

  &.blue {
    background: ${(props) => props.theme.colors.blue};
  }
`
