import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 9.5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  img {
    width: 10rem;
    height: 10rem;
  }

  div {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 500;
      font-size: 2rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }

    span {
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`
