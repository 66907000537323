import styled from 'styled-components'
import { ReactSortable } from 'react-sortablejs'
import InputCurrency from 'react-intl-currency-input'

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  > button {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    width: max-content;
    margin-top: 1.5rem;

    font-size: 1.5rem;
    font-weight: 500;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const Sizes = styled(ReactSortable)`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  margin-top: 1.5rem;
`

export const Size = styled.div`
  position: relative;

  display: flex;
  gap: 1.5rem;
  align-items: center;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.secondary};
  border-radius: 1rem;

  @media (max-width: 640px) {
    flex-direction: column;

    align-items: start;

    > button {
      position: absolute;
      top: 0;
      right: 0;

      margin: 0;
    }
  }

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    label,
    span {
      color: ${(props) => props.theme.colors.text.cards};
    }
  }

  > button {
    margin: 1.5rem 1.5rem 0 0;

    svg {
      width: 2rem;
      height: 2rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Sortable = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 1rem;
    border-right: 3px solid ${(props) => props.theme.colors.secondary};

    svg {
      width: 1.5rem;
      height: 1.5rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const InputMoney = styled(InputCurrency)`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`
