import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const Options = styled.div`
  display: flex;
  align-items: center;

  margin-top: 2rem;

  ${media.lessThan('small')`
    flex-direction: column;
    justify-content: center;
  `};
`

export const ButtonAdd = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 5rem;

  padding: 1.5rem;

  background: ${(props) => props.theme.colors.blue};
  border-radius: 1rem;

  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};

  svg {
    margin-right: 1rem;
  }
`

export const Stocks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  margin-top: 3rem;

  ${media.greaterThan('medium')`
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  `};
`

export const Stock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 1rem;

  padding: 1.5rem;
`

export const StockHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 1rem;
  border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
`

export const StockHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 2rem;
    text-align: left;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const StockHeaderRight = styled.div`
  display: flex;
  align-items: center;

  > button svg {
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.theme.colors.text.cards};

    margin-left: 1rem;
  }
`

export const StockStatus = styled(Switch)``

export const Form = styled.form`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  margin-top: 1rem;
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const Label = styled.label`
  width: fit-content;

  margin-bottom: 0.5rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Copy = styled.span`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  background-clip: padding-box;
  border: 2px solid #222;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;
  text-align: left;

  outline: none;

  &:hover,
  &:focus {
    border: 2px solid #2d81d3;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

interface QuantityInStockProps {
  lowStock: boolean
}

export const QuantityInStock = styled.span<QuantityInStockProps>`
  display: block;
  width: 100%;
  height: calc(4rem + 2px);
  padding: 1rem 1.2rem;
  font-size: 1.5rem;
  color: #cad2db;
  background-color: #020216e8;
  border: 2px solid
    ${(props) => (props.lowStock ? props.theme.colors.red : '#222')};
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.4s;
  text-align: left;

  outline: none;

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }
`

export const Button = styled.button`
  margin-top: 2rem;
  color: ${(props) => props.theme.colors.text.cards};
  background-color: ${(props) => props.theme.colors.green};
  border-color: ${(props) => props.theme.colors.green};

  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  min-width: 12rem;

  font-weight: 600;
  text-align: center;

  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover:not(:disabled) {
    color: #f7fdff;
    opacity: 0.9;
  }
`
