import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import jwt from 'jsonwebtoken'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { getToken, logout } from 'services/auth'
import { useAuth } from 'contexts/UserContext'
import usePersistedState from 'hooks/usePersistedState'
import { Routes } from 'routes/router'

import dark from 'styles/themes/dark'
import GlobalStyle from 'styles/global'

const App = () => {
  const { loadUser } = useAuth()
  const [lastSyncUser, setLastSyncUser] = usePersistedState(
    'syncUser',
    moment().format('DD-MM-YYYY')
  )

  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [location])

  useEffect(() => {
    if (moment(lastSyncUser).isBefore(moment().format('DD-MM-YYYY'))) {
      setLastSyncUser(moment().format('DD-MM-YYYY'))
    }
  }, [lastSyncUser, setLastSyncUser, loadUser])

  // useEffect(() => {
  //   const authenticationToken = getToken()
  //   const [, token] = authenticationToken.split(' ')

  //   if (token && jwt.decode(token)) {
  //     const jwtExpirationDate = jwt.decode(token)
  //     const { exp } = jwtExpirationDate as {
  //       exp: number
  //     }

  //     if (Date.now() < exp) {
  //       logout()

  //       window.location.href = 'https://painel.menuzer01.com/auth/login/'
  //     }
  //   }
  // })

  return (
    <ThemeProvider theme={dark}>
      <Routes />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default App
