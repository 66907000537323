import axios, { AxiosInstance } from 'axios'
import { getToken } from './auth'
import errorHandler from './errorHandler'

// const baseURL = 'http://10.0.0.108:3338'
// const baseURL = 'https://api.menuzer01.com'
// const baseURL = 'http://localhost:3333'
export const baseURL = 'https://test-api.menuzer01.com'
// export const baseURL = process.env.BASE_URL

const userApi = axios.create({
  baseURL,
  withCredentials: true
})

// userApi.defaults.headers.common.Authorization = getToken();
userApi.interceptors.request.use((config) => {
  config.headers.Authorization = getToken()

  return config
})

userApi.interceptors.response.use(undefined, (err) => errorHandler(err))

// botApi.interceptors.response.use(undefined, (err) => errorHandler(err))

export const api = (name: 'user-api' | 'bot-api'): AxiosInstance =>
  ({
    'user-api': userApi
  }[name])
