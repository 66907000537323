import React, { useCallback, useState, useContext } from 'react'
import { FiMoreVertical, FiPlusCircle } from 'react-icons/fi'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ThemeContext } from 'styled-components'
import Swal from 'sweetalert2'

import { BannerProps, TotemSelfServiceProps } from 'utils/props'
import { useTotem } from 'contexts/TotemContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import BannerModal from './BannerModal'

import * as S from './styles'

const BannersInitial: React.FC = () => {
  const { showToast } = useToast()
  const { totem } = useTotem()
  const themeContext = useContext(ThemeContext)

  const [banners, setBanners] = useState<BannerProps[]>([
    ...totem.bannersInitial
  ])
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [bannerSelected, setBannerSelected] = useState<BannerProps>(null)

  const StyledMenu = withStyles({
    paper: {
      backgroundColor: themeContext.colors.secondary
    }
  })((props) => (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuOptionsClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: themeContext.colors.secondary,
      color: themeContext.colors.text.cards,
      fontSize: '1.8rem',

      '&:hover': {
        backgroundColor: themeContext.colors.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    }
  }))(MenuItem)

  const handleMenuOptionsOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, banner: BannerProps) => {
      setBannerSelected(banner)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuOptionsClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggleModal = useCallback((banner: BannerProps = null) => {
    setBannerSelected(banner)
    setIsOpen((prevState: boolean) => !prevState)
  }, [])

  const handleModalBannerEdit = useCallback(() => {
    handleMenuOptionsClose()
    handleToggleModal(bannerSelected)
  }, [bannerSelected, handleMenuOptionsClose, handleToggleModal])

  const handleBannerDelete = useCallback(() => {
    handleMenuOptionsClose()

    try {
      Swal.fire({
        title: `Isto irá excluir o banner "${bannerSelected.name}". Deseja confirmar?`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonColor: '#50A773',
        cancelButtonColor: '#EA1D2C',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.value) {
          const updatedBanners = [...banners]
          const filteredBanners = updatedBanners.filter(
            (item) => item.id !== bannerSelected.id
          )

          try {
            setBanners(filteredBanners)

            await api('user-api').put<TotemSelfServiceProps>(
              `totemsselfservice/${totem.id}`,
              {
                bannersInitial: filteredBanners
              }
            )

            showToast({
              type: 'success',
              message: 'Banners atualizado com sucesso!'
            })
          } catch (err) {
            const message =
              err?.response?.data?.error ||
              'Por Favor entrar em contato com suporte.'

            showToast({ type: 'error', message })
          }
        }
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }, [
    bannerSelected?.id,
    bannerSelected?.name,
    banners,
    handleMenuOptionsClose,
    showToast,
    totem?.id
  ])

  const handleUpdateStatusBanner = useCallback(
    async ({ id }) => {
      const updatedBanners = banners.map((item) => {
        if (item.id === id) {
          return { ...item, available: !item.available }
        }

        return item
      })

      try {
        setBanners(updatedBanners)

        await api('user-api').put<TotemSelfServiceProps>(
          `totemsselfservice/${totem.id}`,
          {
            bannersInitial: updatedBanners
          }
        )
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      }
    },
    [banners, showToast, totem?.id]
  )

  return (
    <>
      <StyledMenu>
        <StyledMenuItem onClick={handleModalBannerEdit}>Editar</StyledMenuItem>
        <StyledMenuItem onClick={handleBannerDelete}>Excluir</StyledMenuItem>
        {/* <StyledMenuItem
          onClick={handleCategoryCopy}
        >
          Duplicar
        </StyledMenuItem> */}
      </StyledMenu>

      <BannerModal
        isOpen={isOpen}
        banner={bannerSelected}
        banners={banners}
        setBanners={setBanners}
        handleToggle={handleToggleModal}
      />

      <S.Container>
        <S.Banners>
          {banners.map((item) => (
            <S.Banner key={item.id}>
              <S.BannerHeader>
                <S.BannerHeaderLeft>
                  <label htmlFor={item.name}>{item.name}</label>
                </S.BannerHeaderLeft>

                <S.BannerHeaderRight>
                  <S.BannerStatus
                    onChange={() => handleUpdateStatusBanner({ id: item.id })}
                    checked={item.available}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={60}
                    handleDiameter={12}
                    offColor={themeContext.colors.red}
                    onColor={themeContext.colors.green}
                  />

                  <button
                    type="button"
                    title="Opções"
                    onClick={(e) => handleMenuOptionsOpen(e, item)}
                  >
                    <FiMoreVertical />
                  </button>
                </S.BannerHeaderRight>
              </S.BannerHeader>

              <S.Image>
                <img src={item.image} alt={item.name} />
              </S.Image>
            </S.Banner>
          ))}

          <button type="button" onClick={() => handleToggleModal()}>
            <S.Banner>
              <S.Image>
                <FiPlusCircle />
              </S.Image>
            </S.Banner>
          </button>
        </S.Banners>
      </S.Container>
    </>
  )
}

export default BannersInitial
