import React, { useCallback } from 'react'
import { MdImage } from 'react-icons/md'

import * as S from './styles'

interface Props {
  image: string
  setImage(image: File): void
  previewImage: string
  setPreviewImage(image: string): void
}

const BannerInput: React.FC<Props> = ({
  image,
  setImage,
  previewImage,
  setPreviewImage
}) => {
  const handleChangeImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]

      if (!file) return

      const url = URL.createObjectURL(file)
      setPreviewImage(url)
      setImage(file)
    },
    [setImage, setPreviewImage]
  )

  const handleRemoveImage = useCallback(() => {
    setPreviewImage(null)
    setImage(null)
  }, [setImage, setPreviewImage])

  return (
    <S.Container>
      <label htmlFor="favicon" className={!image && 'is-invalid'}>
        {previewImage ? (
          <img src={previewImage} alt="Banner" />
        ) : (
          <div>
            <MdImage />
            <strong>Escolher imagem</strong>
          </div>
        )}

        <input
          type="file"
          id="favicon"
          accept="image/*"
          data-file={previewImage}
          onChange={handleChangeImage}
        />
      </label>

      {/* {previewImage && (
        <button type="button" onClick={handleRemoveImage}>
          Remover
        </button>
      )} */}
    </S.Container>
  )
}

export default BannerInput
