import styled from 'styled-components'
import ReactSelect from 'react-select'

export const Container = styled(ReactSelect)`
  .react-select__control {
    background-color: #020216e8;
    background-clip: padding-box;
    border: 2px solid #222;
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;

    font-family: Roboto;
    color: #cad2db;

    &:hover {
      border-color: #7159c1;
    }
  }

  .react-select__control--is-focused {
    border-color: #7159c1 !important;
    box-shadow: none;
  }

  .react-select__option {
    background-color: #020216e8;

    font-family: Roboto;
    color: #cad2db;

    padding: 10px 15px;

    &:active {
      background: #7159c1;
    }

    &:hover {
      background: #7159c1;
    }
  }

  .react-select__placeholder.css-1wa3eu0-placeholder,
  .react-select__single-value.css-1uccc91-singleValue {
    font-family: Roboto;

    color: #cad2db;
  }

  .react-select__multi-value__label,
  .react-select__option--is-focused {
    background: #7159c1;
    color: #cad2db;
  }

  .react-select__multi-value {
    background: #7159c1;
  }

  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-color: #e5e5e5;
    box-shadow: none;
  }
`
