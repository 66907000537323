import styled from 'styled-components'
import media from 'styled-media-query'
import Switch from 'react-switch'

interface OpenProps {
  isOpen: boolean
}

export const Container = styled.nav`
  z-index: 1;
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 5rem;

  padding: 1rem 2rem;

  background: ${(props) => props.theme.colors.primary};
  border-left: 1px solid ${(props) => props.theme.colors.mainBg};
`

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.5rem;
    height: 2.5rem;

    color: ${(props) => props.theme.colors.white};
  }

  ${media.greaterThan('medium')`
    display: none;
  `}
`
