import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  gap: 4rem;
  flex-direction: column;

  ${media.lessThan('medium')`
    gap: 2rem;
  `};
`

export const DatePicker = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan('medium')`
    justify-content: flex-end;
  `};
`

export const Cards = styled.section`
  display: grid;
  gap: ${(props) => props.theme.spacings.medium};
  grid-template-columns: 1fr;
  text-align: center;

  ${media.greaterThan('medium')`
    gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
  `};
`

export const Color = styled.div`
  width: 100%;
  height: 1rem;

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  &.blue {
    background: linear-gradient(90deg, #3398ff 8.06%, #b0f2ee 95.7%);
  }

  &.green {
    background: linear-gradient(
      90deg,
      #28cf8a 8.06%,
      #28cfc9 53.07%,
      #28cfca 95.7%
    );
  }

  &.purple {
    background: linear-gradient(
      90deg,
      #8b65e0 8.06%,
      #ba65e0 54.89%,
      #ba65e0 95.7%
    );
  }

  &.orange {
    background: linear-gradient(
      90deg,
      #ff6944 8.06%,
      #ff8944 52.16%,
      #ffb044 95.7%
    );
  }

  &.pink {
    background: linear-gradient(90deg, #ff3358 8.06%, #c8449b 95.7%);
  }

  &.light-green {
    background: linear-gradient(90deg, #14ff00 8.06%, #faff00 95.7%);
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 9.5rem;

  background: ${(props) => props.theme.colors.cards};
  border-radius: 1rem;

  .content {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    padding: 1.5rem;

    header {
      display: flex;

      h1 {
        font-weight: 500;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};
      }
    }

    footer {
      display: flex;

      span {
        font-weight: normal;
        font-size: 1.3rem;

        color: ${(props) => props.theme.colors.text.cards};

        strong {
          font-size: 1.8rem;
        }
      }
    }
  }
`

export const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 30rem;

  ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `};
`

export const Chart = styled.div`
  background: ${(props) => props.theme.colors.cards};
  border-radius: 1rem;

  .content {
    padding: 2.4rem;

    ${media.lessThan('medium')`
      padding: 1.5rem;
    `};

    header {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: space-between;

      ${media.lessThan('small')`
        gap: 1rem;
        flex-direction: column;
      `};

      h3 {
        font-weight: 500;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};
      }

      p {
        font-weight: normal;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};
      }

      select {
        padding: 0.5rem;

        background: ${(props) => props.theme.colors.secondary};
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
        border-radius: 0.5rem;

        font-weight: normal;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};
      }
    }
  }

  .chart-content {
    height: 30rem;
    width: auto;
  }
`

export const Resume = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  background: ${(props) => props.theme.colors.cards};
  border-radius: 1rem;

  .content {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    width: 100%;
    height: 100%;

    padding: 1.5rem;

    div {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};
      }
    }
  }
`
