import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  gap: 4rem;
  flex-direction: column;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const ListDays = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  h1 {
    font-weight: 400;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }

  div {
    display: flex;
    gap: 1rem;
    align-items: center;

    button {
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;

      background: ${(props) => props.theme.colors.red};
      border-radius: 50%;

      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;

      color: ${(props) => props.theme.colors.text.cards};

      &.checked {
        background: ${(props) => props.theme.colors.green};
      }
    }
  }
`

export const Days = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  column-gap: ${(props) => props.theme.spacings.small};
  row-gap: ${(props) => props.theme.spacings.medium};

  ${media.greaterThan('medium')`
      grid-template-columns: repeat(4, 1fr);
      column-gap: 4rem;
      row-gap: 4rem;
    `};

  div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    label {
      font-weight: 400;
      font-size: 2rem;
      text-align: left;
      color: ${(props) => props.theme.colors.text.cards};
    }

    input {
      display: block;
      width: 100%;
      height: calc(4rem + 2px);
      padding: 1rem 1.2rem;
      font-size: 1.5rem;
      color: ${(props) => props.theme.colors.primary};

      background: ${(props) => props.theme.colors.white};
      border-radius: 5px;
      border: 2px solid #cecece;

      &:hover,
      &:focus {
        border: 2px solid #2d81d3;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &.is-invalid {
        border-color: ${(props) => props.theme.colors.red};
      }
    }
  }
`
