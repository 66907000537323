export interface TaxableUnitProps {
  unit: string
  name: string
  quantityInStock:
    | 'quantityInStockUN'
    | 'quantityInStockKG'
    | 'quantityInStockCX'
  minimumQuantityInStock:
    | 'minimumQuantityInStockUN'
    | 'minimumQuantityInStockKG'
    | 'minimumQuantityInStockCX'
}

const taxableUnits: TaxableUnitProps[] = [
  {
    unit: 'UN',
    name: 'Unidades',
    quantityInStock: 'quantityInStockUN',
    minimumQuantityInStock: 'minimumQuantityInStockUN'
  },
  // {
  //   unit: 'KG',
  //   name: 'Quilograma',
  //   quantityInStock: 'quantityInStockKG',
  //   minimumQuantityInStock: 'minimumQuantityInStockKG'
  // },
  {
    unit: 'CX',
    name: 'Caixas',
    quantityInStock: 'quantityInStockCX',
    minimumQuantityInStock: 'minimumQuantityInStockCX'
  }
]

export { taxableUnits }
