import { Modal } from 'react-responsive-modal'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  gap: 10rem;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding-bottom: 1.5rem;

  border-bottom: 2px solid ${(props) => props.theme.colors.secondary};

  ${media.lessThan('medium')`
    gap: 2rem;
    align-items: flex-start;
  `};

  .left {
    display: flex;
    gap: 5rem;
    align-items: center;

    ${media.lessThan('medium')`
      gap: 2rem;
      flex-direction: column;
      align-items: flex-start;
    `};

    > div {
      display: flex;
      gap: 4rem;
      align-items: center;

      ${media.lessThan('medium')`
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;
      `};

      h1 {
        display: flex;
        flex-direction: column;

        font-weight: 500;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};

        opacity: 0.9;

        span {
          opacity: 1;
        }
      }

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    button svg {
      width: 2rem;
      height: 2rem;

      color: ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const Grid = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: 1fr 20rem;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `};
`

export const Left = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    .info {
      display: flex;
      gap: 1.5rem;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
      }

      h1 {
        font-weight: 400;
        font-size: 2rem;

        color: ${(props) => props.theme.colors.text.cards};
      }

      span {
        font-weight: 400;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};

        opacity: 0.8;
      }
    }

    .forms {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1,
      span {
        font-weight: normal;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};
      }

      h1 {
        text-transform: uppercase;
      }

      small {
        font-weight: normal;
        font-size: 1.5rem;

        color: ${(props) => props.theme.colors.text.cards};
      }
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.8rem;

  color: ${(props) => props.theme.colors.text.cards};
`

export const OrderStatus = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  img {
    width: 3rem;
    height: 3rem;
  }

  h1 {
    font-weight: 400;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};
  }

  span {
    font-weight: 400;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};

    opacity: 0.8;
  }
`

export const Customer = styled.section`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  .info {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
    }

    h1 {
      font-weight: 400;
      font-size: 2rem;

      color: ${(props) => props.theme.colors.text.cards};
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 1.8rem;

      color: ${(props) => props.theme.colors.text.cards};

      opacity: 0.8;
    }
  }
`

export const Product = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;

  padding: 1rem;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
  }
`

export const Options = styled.div`
  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;

    margin-left: 1.5rem;

    color: ${(props) => props.theme.colors.text.cards};

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
`

export const Option = styled.div`
  // display: flex;
  // align-items: center;
  display: flex;
  flex-direction: column;

  margin-top: 0.5rem;
  margin-left: 2rem;

  white-space: normal;

  p,
  span {
    display: inline-block;
    white-space: pre-line;
  }

  p {
    font-style: normal;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};
    margin: 0 0.5rem 0 0;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.blue};
  }

  // span:not(:last-child)::after {
  //   content: '+';
  //   margin: 0 0.5rem;
  // }
`

export const Payments = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`

export const Payment = styled.div`
  display: flex;
  flex-direction: column;
`

export const Right = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      min-height: 4.5rem;
      height: 100%;

      background: ${(props) => props.theme.colors.secondary};
      border-radius: 0.5rem;

      padding: 1rem;

      span,
      h1 {
        font-weight: normal;
        font-size: 1.8rem;

        color: ${(props) => props.theme.colors.text.cards};

        opacity: 0.8;
      }

      h1 {
        font-weight: 500;
        opacity: 1;
      }

      &.green {
        h1 {
          color: ${(props) => props.theme.colors.green};
        }
      }

      &.red {
        h1 {
          color: ${(props) => props.theme.colors.red};
        }
      }

      &.blue {
        h1 {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }

    > button {
      display: flex;
      justify-content: center;
      align-items: center;

      min-height: 4.5rem;
      height: 100%;

      background: ${(props) => props.theme.colors.secondary};
      border-radius: 0.5rem;

      padding: 1rem;

      font-weight: 700;
      font-size: 1.8rem;

      color: ${(props) => props.theme.colors.text.cards};

      &.green {
        background: ${(props) => props.theme.colors.green};
      }

      &.red {
        background: ${(props) => props.theme.colors.red};
      }

      &.blue {
        background: ${(props) => props.theme.colors.blue};
      }
    }
  }
`
