import moment from 'moment-timezone'

const currencyFormatter = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.')

const numberBrFormatter = (number: number, minimumFractionDigits = 1) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits
  })

  return formatter.format(number)
}

const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

const calculateProductMargin = (cogs, productSellPrice) => {
  const result = (cogs * 100) / productSellPrice
  return Number(Math.round(Math.abs(result - 100)).toFixed(0))
}

const parseLineChartData = (data) => {
  if (!data) return {}

  const lucroLiquido = data[0]?.map((el) => ({
    t: moment(el.t),
    y: el.y.toFixed(2)
  }))
  // const marketing = data[1]?.map(el => ({ t: new Date(el.t), y: el.y }));
  const custoProdutos = data[1]?.map((el) => ({
    t: moment(el.t),
    y: el.y.toFixed(2)
  }))
  const faturamento = data[2]?.map((el) => ({
    t: moment(el.t),
    y: el.y.toFixed(2)
  }))

  return {
    lucroLiquido,
    //  marketing,
    custoProdutos,
    faturamento
  }
}

const getOnlyNumbers = (digits: string): string => {
  return digits?.replace(/\D/g, '')
}

const isAllTheSameDigits = (digits: string): boolean => {
  return !digits.split('').some((digit) => digit !== digits[0])
}

const calcChecker = (digits: string, weights: number[]): string => {
  const digitsLength = digits.length
  const digitsLengthWithoutChecker = weights.length - 1

  const sum = digits.split('').reduce((acc, digit, idx) => {
    return acc + +digit * weights[digitsLength - 1 - idx]
  }, 0)
  const sumDivisionRemainder = sum % 11
  const checker = sumDivisionRemainder < 2 ? 0 : 11 - sumDivisionRemainder

  if (digitsLength === digitsLengthWithoutChecker) {
    return calcChecker(`${digits}${checker}`, weights)
  }

  return `${digits[digitsLength - 1]}${checker}`
}

const isValid = (
  digits: string,
  correctDigitsLength: number,
  weights: number[]
): boolean => {
  const cleanDigits = getOnlyNumbers(digits)
  if (
    cleanDigits?.length !== correctDigitsLength ||
    isAllTheSameDigits(cleanDigits)
  ) {
    return false
  }
  const digitsWithoutChecker = cleanDigits.substring(0, correctDigitsLength - 2)
  const digitsChecker = cleanDigits.substring(
    correctDigitsLength - 2,
    correctDigitsLength
  )
  const calculetedChecker = calcChecker(digitsWithoutChecker, weights)
  return digitsChecker === calculetedChecker
}

const isCPFValid = (cpf: string): boolean => {
  const cpfLength = 11
  const weights = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  return isValid(cpf, cpfLength, weights)
}

const isCnpjValid = (cnpj: string): boolean => {
  const cpfLength = 14
  const weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6]
  return isValid(cnpj, cpfLength, weights)
}

const masks = {
  cpf(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  },
  cnpj(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  },
  cep(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  },
  currency(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.')
  },
  phone(value = '') {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d{4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }
}

const currencyToNumber = (value: string) => {
  if (value.length > 2) {
    const parsedValue = value
      .replace(',', ' ')
      .replaceAll('.', '')
      .replace(' ', '.')

    return parsedValue
  }

  return value
}

const states = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]

export {
  calculateProductMargin,
  currencyFormatter,
  currencyToNumber,
  formatPrice,
  isCPFValid,
  masks,
  numberBrFormatter,
  parseLineChartData,
  states
}
