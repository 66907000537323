import React from 'react'
import { FiMenu } from 'react-icons/fi'

import * as S from './styles'

interface Props {
  handleOpenSidebar(): void
}

const Navbar: React.FC<Props> = ({ handleOpenSidebar }) => {
  return (
    <S.Container>
      <S.Buttons>
        <S.Button type="button" onClick={handleOpenSidebar}>
          <FiMenu />
        </S.Button>
      </S.Buttons>
    </S.Container>
  )
}

export default Navbar
