import React, { createContext, useState, useContext } from 'react'

import { ModuleProps } from 'utils/props'

interface ModuleProviderProps {
  children: React.ReactNode
}

interface ModuleContextData {
  module: ModuleProps | null
  setModule(module: ModuleProps): void
}

const ModuleContext = createContext({} as ModuleContextData)

const ModuleProvider: React.FC<ModuleProviderProps> = ({ children }) => {
  const [module, setModule] = useState(null)

  return (
    <ModuleContext.Provider
      value={{
        module,
        setModule
      }}
    >
      {children}
    </ModuleContext.Provider>
  )
}

function useModule(): ModuleContextData {
  const context = useContext(ModuleContext)

  if (!context) {
    throw new Error('useModule must be used within a ModuleContext')
  }

  return context
}

export { ModuleContext, ModuleProvider, useModule }
