import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react'

import { api } from 'services/api'
import { useSite } from './SiteContext'
import errorHandler from 'services/errorHandler'
// import { botSocket } from 'services/socket'

interface BotContextData {
  isLoading: boolean
  isConnected: boolean
  token: string
  qrCode: string
  handleCreateTheBot(): void
  handleCloseTheBot(): void
}

export const BotContext = createContext({} as BotContextData)

const BotProvider: React.FC = ({ children }) => {
  const { site } = useSite()
  const [isLoading, setIsLoading] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [token, setToken] = useState(null)
  const [qrCode, setQrCode] = useState(null)

  // useEffect(() => {
  //   api('bot-api')
  //     .get('check-connection-session')
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setIsConnected(true)
  //         setIsLoading(false)
  //       }
  //     })
  // }, [])

  // useEffect(() => {
  //   botSocket.on('qrCode', (data) => {
  //     if (site.slug === data.session) {
  //       setQrCode(data.data)
  //       setIsLoading(false)
  //     }
  //   })

  //   botSocket.on('session-logged', (data) => {
  //     if (data.session === site.slug) {
  //       if (token) {
  //         setIsConnected(true)
  //         setIsLoading(false)
  //       }
  //     }
  //   })
  // }, [site, token])

  const handleCreateTheBot = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await api('bot-api').post(
        `${site.slug}/WHATSAPP/generate-token`
      )

      localStorage.setItem('@Bot-Token', data.token)
      setToken(data.token)

      const { data: checkConn } = await api('bot-api').get(
        'check-connection-session'
      )

      if (!checkConn.status) {
        await api('bot-api').post('start-session', null)
      } else {
        setIsConnected(true)
        setIsLoading(false)
      }
    } catch (err) {
      errorHandler(err)
      setIsLoading(false)
    }
  }, [site])

  const handleCloseTheBot = useCallback(async () => {
    setIsLoading(true)

    try {
      await api('bot-api').post('close-session', null)

      setToken(null)
      setQrCode(null)
      setIsConnected(false)
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return (
    <BotContext.Provider
      value={{
        isLoading,
        isConnected,
        token,
        qrCode,
        handleCreateTheBot,
        handleCloseTheBot
      }}
    >
      {children}
    </BotContext.Provider>
  )
}

function useBot(): BotContextData {
  const context = useContext(BotContext)

  if (!context) {
    throw new Error('useBot must be used within a BotContext')
  }

  return context
}

export { BotProvider, useBot }
