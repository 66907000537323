import { Barcode, House } from 'phosphor-react'

import ClientForm from 'pages/Dashboard/ClientForm'
import DeliveryAreas from 'pages/Dashboard/DeliveryAreas'
import Motoboys from 'pages/Dashboard/Motoboys'
import Home from 'pages/Dashboard/Home'
import Indoor from 'pages/Dashboard/Indoor'
import Menu from 'pages/Dashboard/Menu'
import Modules from 'pages/Dashboard/Modules'
import OpeningHours from 'pages/Dashboard/OpeningHours'
import Orders from 'pages/Dashboard/Orders'
import PaymentMethods from 'pages/Dashboard/PaymentMethods'
import Profile from 'pages/Dashboard/Profile'
import Promotions from 'pages/Dashboard/Promotions'
import Stock from 'pages/Dashboard/Stock'

import TotemCategory from 'pages/TotemSelfService/Category'
import TotemHome from 'pages/TotemSelfService/Home'
import TotemProfile from 'pages/TotemSelfService/Profile'

import Services from 'pages/Services/Home'

const menuRoutes = {
  dashboard: [
    [
      {
        path: '/',
        icon: '/img/icons/sidebar/home.svg',
        name: 'Início',
        new: false,
        mobileActive: ['/dashboard', '/dashboard/'],
        component: Home
      },
      {
        path: '/orders/',
        icon: '/img/icons/sidebar/orders.svg',
        name: 'Pedidos',
        new: false,
        mobileActive: ['/dashboard/orders', '/dashboard/orders/'],
        component: Orders
      }
    ],
    [
      {
        path: '/menu/',
        icon: '/img/icons/sidebar/menu.svg',
        name: 'Cardápios',
        new: false,
        mobileActive: [
          '/dashboard/menu',
          '/dashboard/menu/',

          '/dashboard/menu/category',
          '/dashboard/menu/category/'
        ],
        component: Menu
      },
      {
        path: '/client-form/',
        icon: '/img/icons/sidebar/person.svg',
        name: 'Dados do cliente',
        new: false,
        mobileActive: ['/dashboard/client-form', '/dashboard/client-form/'],
        component: ClientForm
      },
      {
        path: '/stock/',
        icon: '/img/icons/sidebar/stock.svg',
        name: 'Estoque',
        new: false,
        mobileActive: ['/dashboard/stock', '/dashboard/stock/'],
        component: Stock
      },
      {
        path: '/indoor/',
        icon: '/img/icons/sidebar/indoor.svg',
        name: 'Mesas',
        new: false,
        mobileActive: ['/dashboard/indoor', '/dashboard/indoor/'],
        component: Indoor
      },
      {
        path: '/opening-hours/',
        icon: '/img/icons/sidebar/opening-hours.svg',
        name: 'Horários',
        new: false,
        mobileActive: ['/dashboard/opening-hours', '/dashboard/opening-hours/'],
        component: OpeningHours
      },
      {
        path: '/motoboys/',
        icon: '/img/icons/sidebar/delivery-outline.png',
        name: 'Motoboys',
        new: true,
        mobileActive: ['/dashboard/motoboys', '/dashboard/motoboys/'],
        component: Motoboys
      },
      {
        path: '/delivery-areas/',
        icon: '/img/icons/sidebar/delivery-settings.svg',
        name: 'Áreas de entrega',
        new: false,
        mobileActive: [
          '/dashboard/delivery-areas',
          '/dashboard/delivery-areas/'
        ],
        component: DeliveryAreas
      },
      {
        path: '/payment-methods/',
        icon: '/img/icons/sidebar/payment-methods.svg',
        name: 'Formas de pagamento',
        new: false,
        mobileActive: [
          '/dashboard/payment-methods',
          '/dashboard/payment-methods/'
        ],
        component: PaymentMethods
      },
      {
        path: '/promotions/',
        icon: '/img/icons/sidebar/promotion.svg',
        name: 'Cupons de desconto',
        new: false,
        mobileActive: ['/dashboard/promotions', '/dashboard/promotions/'],
        component: Promotions
      }
    ],
    [
      {
        path: '/modules/',
        icon: '/img/icons/sidebar/services.svg',
        name: 'Módulos',
        new: false,
        mobileActive: [
          '/dashboard/modules',
          '/dashboard/modules/',

          '/dashboard/modules/colors',
          '/dashboard/modules/colors/',

          '/dashboard/modules/integrations',
          '/dashboard/modules/integrations/',

          '/dashboard/modules/bot',
          '/dashboard/modules/bot/',

          '/dashboard/modules/analytics',
          '/dashboard/modules/analytics/',

          '/dashboard/modules/printers',
          '/dashboard/modules/printers/',

          '/dashboard/modules/whatsapp',
          '/dashboard/modules/whatsapp/',

          '/dashboard/modules/mercadopago',
          '/dashboard/modules/mercadopago/',
          '/dashboard/modules/picpay',
          '/dashboard/modules/picpay/',
          '/dashboard/modules/pagarme',
          '/dashboard/modules/pagarme/'
        ],
        component: Modules
      },
      {
        path: '/profile/',
        icon: '/img/icons/sidebar/profile.svg',
        name: 'Perfil',
        new: false,
        mobileActive: ['/dashboard/profile', '/dashboard/profile/'],
        component: Profile
      }
    ]
  ],
  totem: [
    [
      {
        path: '/',
        icon: House,
        name: 'Início',
        new: false,
        mobileActive: ['/totem', '/totem/'],
        component: TotemHome
      },
      {
        path: '/menu/',
        icon: '/img/icons/sidebar/menu.svg',
        name: 'Cardápio',
        new: false,
        mobileActive: ['/totem/menu', '/totem/menu/'],
        component: TotemCategory
      },
      {
        path: '/profile/',
        icon: '/img/icons/sidebar/profile.svg',
        name: 'Perfil',
        new: false,
        mobileActive: ['/totem/profile', '/totem/profile/'],
        component: TotemProfile
      }
    ]
  ],
  services: [
    [
      {
        path: '/',
        icon: House,
        name: 'Início',
        new: false,
        mobileActive: ['/services', '/services/'],
        component: Services
      },

      {
        path: '/invoices/',
        icon: Barcode,
        name: 'Faturas',
        new: true,
        mobileActive: ['/invoices', '/invoices/'],
        component: Services
      }
    ]
  ]
}

export default menuRoutes
