import React, { createContext, useContext, useCallback } from 'react'
import { toast, ToastProps } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
  autoClose: 3000,
  hideProgressBar: true,
  pauseOnHover: false
})

interface ToastProviderProps {
  children: React.ReactNode
}

interface showToastProps {
  message: string
  type: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark'
  options?: Partial<ToastProps>
}

interface ToastContextData {
  showToast(data: showToastProps): void
}

const ToastContext = createContext({} as ToastContextData)

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const showToast = useCallback(({ message, type }: showToastProps) => {
    const backgrounds = {
      error: 'bg-gradient-danger',
      success: 'bg-gradient-primary',
      warn: 'bg-orange'
    }

    toast[type](message, { className: `${backgrounds[type]}` })
  }, [])

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
    </ToastContext.Provider>
  )
}

function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}

export { ToastContext, ToastProvider, useToast }
