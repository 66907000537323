import React, { useCallback, useEffect, useState } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useWindowSize } from 'hooks/useWindowSize'

import { v4 as uuidv4 } from 'uuid'
import { MotoboyProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { isCPFValid, masks } from 'utils/utils'

interface Props {
  isOpen: boolean
  handleToggle(item: FormDataProps | null): void
  motoboy?: MotoboyProps
  motoboys?: MotoboyProps[]
  setMotoboys(motoboys: MotoboyProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().trim().min(3).required('Este campo é obrigatório'),
  cpf: yup
    .string()
    .trim()
    .length(14)
    .test('cpf-valido', 'O CPF informado não é válido', (value) =>
      isCPFValid(value.replace(/[^0-9]/g, ''))
    )
    .required('Este campo é obrigatório'),
  phone: yup.string().trim().length(15).required('Este campo é obrigatório'),
  placa: yup.string().trim().min(6).required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
  cpf: string
  phone: string
  placa: string
}

const ModalAddMotoboy: React.FC<Props> = ({
  isOpen,
  handleToggle,
  motoboy,
  motoboys,
  setMotoboys
}) => {
  const [width, height] = useWindowSize()

  const {
    reset,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })
  const [isLoading, setIsLoading] = useState(false)
  const [available, setAvailable] = useState(true)

  const { showToast } = useToast()
  const { site } = useSite()
  const previewName = watch('name')

  const handleResetStats = useCallback(() => {
    reset()
    setValue('cpf', '')
    setValue('phone', '')
    setAvailable(true)
    setIsLoading(false)
  }, [reset, setValue])

  useEffect(() => {
    if (motoboy) {
      setValue('name', motoboy.name, { shouldValidate: true })
      setValue('cpf', motoboy.cpf, { shouldValidate: true })
      setValue('phone', motoboy.phone, { shouldValidate: true })
      setValue('placa', motoboy.placa, { shouldValidate: true })
      setAvailable(motoboy.available)
    }
  }, [motoboy, setValue])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleAvailableRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setAvailable(value === 'true')
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const motoboyData = {
        id: motoboy ? motoboy.id : uuidv4(),
        name: data.name,
        phone: data.phone,
        cpf: data.cpf,
        placa: data.placa,
        available
      }

      const updatedMotoboys = [...motoboys]

      if (motoboy) {
        const motoboyIndex = motoboys.findIndex((c) => c.id === motoboy.id)

        updatedMotoboys[motoboyIndex] = motoboyData as MotoboyProps
      } else {
        updatedMotoboys.push(motoboyData as MotoboyProps)
      }

      try {
        setMotoboys(
          updatedMotoboys.sort((a, b) =>
            a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
          )
        )

        // Envio dos dados para o back-end
        if (motoboy) {
          await api('user-api').put(`motoboys/${motoboy.id}`, {
            ...motoboyData,
            serviceId: site.serviceId
          })
        } else {
          await api('user-api').post('/motoboys/', {
            ...motoboyData,
            serviceId: site.serviceId
          })
        }

        showToast({ message: 'Motoboy salvo com sucesso!', type: 'success' })
        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por favor, entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      available,
      handleToggleModal,
      motoboy,
      motoboys,
      setMotoboys,
      showToast,
      site.serviceId
    ]
  )
  return (
    <>
      <S.Container
        open={isOpen}
        showCloseIcon={false}
        onClose={() => handleToggleModal(null)}
        center
        styles={{
          modal: {
            background: '#293949',
            padding: '2rem',
            borderRadius: '1.5rem',
            minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
            minHeight: width <= 1000 ? `${height - 50}px` : '600px'
          }
        }}
      >
        <S.Content>
          <S.Header>
            <h1>
              {previewName && previewName.trim().length > 0
                ? previewName
                : 'Motoboy'}
            </h1>
            <p>Detalhes do motoboy</p>
          </S.Header>

          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.Col>
              <S.Label htmlFor="name">Nome do motoboy</S.Label>
              <S.Input
                id="name"
                name="name"
                placeholder="Ex: João"
                minLength={3}
                className={errors.name && 'is-invalid'}
                {...register('name')}
              />
            </S.Col>

            <S.Col>
              <S.Label htmlFor="cpf">CPF</S.Label>
              <S.InputMask
                id="cpf"
                name="cpf"
                mask="999.999.999-99"
                maskChar={false}
                placeholder="Ex: 000.000.000-00"
                className={errors.cpf && 'is-invalid'}
                value={watch('cpf')}
                onChange={(e) => setValue('cpf', e.target.value)}
              />
            </S.Col>

            <S.Col>
              <S.Label htmlFor="phone">Telefone</S.Label>
              <S.InputMask
                id="phone"
                name="phone"
                mask="(99) 99999-9999"
                maskChar={false}
                placeholder="Ex: (00) 00000-0000"
                className={errors.phone && 'is-invalid'}
                value={watch('phone')}
                onChange={(e) => setValue('phone', e.target.value)}
              />
            </S.Col>

            <S.Col>
              <S.Label htmlFor="placa">Placa do veículo</S.Label>
              <S.Input
                id="placa"
                name="placa"
                maxLength={8}
                placeholder="Ex: AAA0A00"
                className={errors.placa && 'is-invalid'}
                style={{ textTransform: 'uppercase' }}
                {...register('placa')}
              />
            </S.Col>

            <S.Col>
              <S.Label>Disponibilidade</S.Label>

              <S.RadioGroup>
                <label>
                  <input
                    name="available"
                    type="radio"
                    value="true"
                    onChange={handleAvailableRadio}
                    defaultChecked={available}
                  />
                  <span>
                    <div>
                      <div>Disponível</div>
                    </div>
                  </span>
                </label>
                <label>
                  <input
                    name="available"
                    type="radio"
                    value="false"
                    onChange={handleAvailableRadio}
                    defaultChecked={!available}
                  />
                  <span>
                    <div>
                      <div>Indisponível</div>
                    </div>
                  </span>
                </label>
              </S.RadioGroup>
            </S.Col>

            <S.Buttons>
              <S.Button
                type="button"
                cancel
                onClick={() => handleToggleModal(null)}
              >
                Cancelar
              </S.Button>

              <S.Button
                type="submit"
                disabled={
                  isLoading ||
                  previewName === undefined ||
                  previewName?.trim().length === 0
                }
              >
                Salvar
              </S.Button>
            </S.Buttons>
          </S.Form>
        </S.Content>
      </S.Container>
    </>
  )
}

export default ModalAddMotoboy
