import React from 'react'
import QR from 'qrcode.react'

import { useSite } from 'contexts/SiteContext'

import * as S from './styles'

interface Props {
  link?: string
  id?: string
}

const QrCode: React.FC<Props> = ({ link, ...rest }) => {
  const { site } = useSite()

  return (
    <S.Container>
      <QR
        value={link || 'https://painel.menuzer01.com/dashboard/indoor/'}
        size={250}
        bgColor="#ffffff"
        fgColor="#000000"
        level="M"
        includeMargin={false}
        // renderAs="svg"
        imageSettings={{
          src: '/favicon.png',
          x: null,
          y: null,
          height: 50,
          width: 50,
          excavate: true
        }}
        {...rest}
      />
    </S.Container>
  )
}

export default QrCode
