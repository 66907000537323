import React from 'react'

import { PrintersModuleProps } from 'utils/props'

import * as S from './styles'

interface Props {
  printersModule: PrintersModuleProps
  handleChangeComponent(e: React.ChangeEvent<HTMLSelectElement>): void
}

const PrintersComponentStatus: React.FC<Props> = ({
  printersModule,
  handleChangeComponent
}) => {
  return (
    <S.Container>
      <S.Empty>
        <img src="/img/icons/modules/printers.png" alt="Impressoras" />

        <div>
          <h1>{printersModule.component} não está conectado</h1>

          <S.Select onChange={handleChangeComponent}>
            <option
              defaultValue={null}
              selected={!printersModule.component}
              disabled
              hidden
            >
              Selecione um componente
            </option>
            <option
              selected={printersModule.component === 'MenuPrinter'}
              value="MenuPrinter"
            >
              MenuPrinter
            </option>
            <option
              selected={printersModule.component === 'QzTray'}
              value="QzTray"
            >
              QzTray
            </option>
          </S.Select>
        </div>
      </S.Empty>
    </S.Container>
  )
}

export default PrintersComponentStatus
