import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  gap: 4rem;
  flex-direction: column;
`

export const DatePicker = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
  `};

  .showValues button,
  .top-showValues button {
    color: #ffffff;
  }

  @media (max-width: 600px) {
    .showValues {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 30rem;

      button {
        font-size: 2rem;
      }
    }
  }
`

export const TopCards = styled.section`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  ${media.lessThan('small')`
    flex-wrap: wrap;
    justify-content: center;
  `}

  @media (max-width: 600px) {
    .top-showValues {
      display: none;
    }
  }
`

export const Card = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  padding: 1rem;

  min-width: 8rem;

  background: ${(props) => props.theme.colors.primary};
  border-radius: 0.7px;

  span {
    font-weight: normal;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.text.cards};
  }

  strong {
    font-weight: bold;
    font-size: 1.8rem;

    color: ${(props) => props.theme.colors.text.cards};

    &.red {
      color: ${(props) => props.theme.colors.red};
    }

    &.blue {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`

export const Content = styled.section``
