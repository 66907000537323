import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { CategoryProps, ItemProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(): void
  categories: CategoryProps[]
  setCategories(newState: CategoryProps[]): void
  product: ItemProps
  setProduct(product: ItemProps): void
  handleUpdateCategories({ value }: { value: CategoryProps[] }): void
}

const ModalCopyProduct: React.FC<Props> = ({
  isOpen,
  handleToggle,
  categories,
  setCategories,
  product,
  setProduct,
  handleUpdateCategories
}) => {
  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [categorySelected, setCategorySelected] = useState(null)

  useEffect(() => {
    if (product) {
      setCategorySelected(product.categoryId)
    }
  }, [product])

  const handleToggleModal = () => {
    handleToggle()
    setIsLoading(false)
    setProduct(null)
    setCategorySelected(null)
  }

  const handleChangeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setCategorySelected(value)
  }

  const handleSubmit = () => {
    setIsLoading(true)

    const newCategories = [...categories]
    const categoryIndex = categories.findIndex((c) => c.id === categorySelected)

    const productData = {
      ...product,
      id: uuidv4(),
      categoryId: categorySelected
    }

    newCategories[categoryIndex].items.push(productData as ItemProps)

    try {
      handleUpdateCategories({ value: newCategories })
      setCategories(newCategories)
      handleToggleModal()
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({ message, type: 'error' })
    }
  }

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={handleToggleModal}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: '#293949',
          borderRadius: '1.5rem'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>Copiar produto</h1>
          <p>{product?.name}</p>
        </S.Header>

        {console.log(categorySelected)}

        <S.Col>
          <S.Label htmlFor="category">Copiar para categoria</S.Label>
          <S.Select onChange={handleChangeCategory}>
            {categories.map((category) => (
              <option
                key={category.id}
                selected={category.id === categorySelected}
                value={category.id}
              >
                {category.name}
              </option>
            ))}
          </S.Select>
        </S.Col>

        <S.Buttons>
          <S.Button
            type="button"
            cancel
            disabled={isLoading}
            onClick={handleToggleModal}
          >
            Cancelar
          </S.Button>

          <S.Button type="button" disabled={isLoading} onClick={handleSubmit}>
            Copiar
          </S.Button>
        </S.Buttons>
      </S.Content>
    </S.Container>
  )
}

export default ModalCopyProduct
