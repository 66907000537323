import { useAuth } from 'contexts/UserContext'
import React, { useState } from 'react'

import Address from './Address'
import Banners from './Banners'
import Details from './Details'
import UserLogin from './UserLogin'

import * as S from './styles'

const Profile: React.FC = () => {
  const { user } = useAuth()
  const [tabActive, setTabActive] = useState('DETAILS')

  const handleChangeSelectedTab = (
    tabSelected: 'DETAILS' | 'ADDRESS' | 'BANNERS' | 'LOGIN'
  ) => {
    setTabActive(tabSelected)
  }

  return (
    <S.Container>
      <S.Content>
        <S.Options>
          <button
            type="button"
            className={`${tabActive === 'DETAILS' ? 'selected' : undefined}`}
            onClick={() => handleChangeSelectedTab('DETAILS')}
          >
            Detalhes
          </button>

          <button
            type="button"
            className={`${tabActive === 'ADDRESS' ? 'selected' : undefined}`}
            onClick={() => handleChangeSelectedTab('ADDRESS')}
          >
            Endereço
          </button>

          <button
            type="button"
            className={`${tabActive === 'BANNERS' ? 'selected' : undefined}`}
            onClick={() => handleChangeSelectedTab('BANNERS')}
          >
            Banners
          </button>

          {user.user && (
            <button
              type="button"
              className={`${tabActive === 'LOGIN' ? 'selected' : undefined}`}
              onClick={() => handleChangeSelectedTab('LOGIN')}
            >
              Login
            </button>
          )}
        </S.Options>

        {tabActive === 'DETAILS' ? (
          <Details />
        ) : tabActive === 'ADDRESS' ? (
          <Address />
        ) : tabActive === 'LOGIN' && user.user ? (
          <UserLogin />
        ) : (
          <Banners />
        )}
      </S.Content>
    </S.Container>
  )
}

export default Profile
