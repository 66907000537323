import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'contexts/ToastContext'
import { ServiceProps } from 'utils/props'

import { api } from 'services/api'
import * as S from './styles'

interface Props {
  service: ServiceProps
  handleClick({ type, serviceId }: { type: string; serviceId: string }): void
}

const Service: React.FC<Props> = ({ service, handleClick }) => {
  const { showToast } = useToast()
  const history = useHistory()

  const handleServiceClick = useCallback(
    async (type: string, serviceId: string) => {
      if (!service[type]) {
        const newSite = {
          slug: service.name.replaceAll(' ', '').toLocaleLowerCase(),
          title: service.name,
          slogan: service.name,
          phone: '(00) 0000-00000',
          serviceId: service.id
        }

        await api('user-api').post(`sites/`, newSite)

        showToast({
          message: `${
            type.charAt(0).toUpperCase() + type.slice(1)
          } criado com sucesso!`,
          type: 'success'
        })
        setTimeout(() => {
          location.reload()
        }, 1600)
      } else {
        handleClick({ type, serviceId })
      }
    },
    [handleClick, service, showToast]
  )

  const services = [
    {
      type: 'site',
      image: '/comandante.png',
      create: 'Criar novo site'
    },
    {
      type: 'pdv',
      image: '/favicon.png',
      create: 'Criar novo pdv'
    }
  ]

  return (
    <S.Container>
      <div>
        <h1>{service.name}</h1>
        <p>Funcionario {service.employee.name}</p>
      </div>

      <S.Cards>
        {services.map((item, index) => (
          <S.Card
            key={index}
            onClick={() =>
              handleServiceClick(item.type, service[item.type]?.id)
            }
          >
            <img
              src={item.image}
              alt={item.type}
              style={{ width: '200px !important' }}
            />

            <div>
              <h1>
                {service[item.type]
                  ? `Ir até o ${item.type}`
                  : `Criar novo ${item.type}`}
              </h1>
            </div>
          </S.Card>
        ))}

        {service.totemsselfservice.map((totem) => (
          <S.Card
            key={totem.id}
            onClick={() => handleServiceClick('totemsselfservice', totem.id)}
            // onClick={() => history.push(`/totemselfservice/${totem.id}`)}
          >
            <div />
            <div>
              <h1>Ir até o totem {totem.name}</h1>
            </div>
          </S.Card>
        ))}
      </S.Cards>
    </S.Container>
  )
}

export default Service
