import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      cursor: pointer;
      transition: opacity 300ms;

      &:hover {
        opacity: 0.7;
      }

      img,
      div {
        width: 20rem;
        height: 20rem;
        object-fit: contain;

        padding: 1rem;

        background: ${theme.colors.secondary};
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 1.5rem;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        svg {
          color: #dddddd;
          width: 40px;
          height: 40px;
        }

        strong {
          color: #dddddd;
          font-size: 16px;
        }
      }

      input {
        display: none;
      }
    }

    > button {
      margin-top: 1rem;

      font-size: 1.5rem;
      color: ${({ theme }) => theme.colors.text.cards};
    }
  `}
`
