import React, { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'

import { useSite } from 'contexts/SiteContext'

import MenuCard from 'components/MenuCard'

import * as S from './styles'

interface Props extends RouteComponentProps {}

const Menu: React.FC<Props> = ({ history }) => {
  const { site } = useSite()

  const handleNavigateToCategory = useCallback(
    (category: string) => {
      localStorage.setItem('last-active-menu-id', category)
      history.push('/dashboard/menu/category/')
    },
    [history]
  )

  return (
    <S.Container>
      <S.Title>Cardápios</S.Title>

      <S.Cards>
        <button
          type="button"
          onClick={() => handleNavigateToCategory(site.deliveryMenuId)}
        >
          <MenuCard>
            <img
              draggable={false}
              src="/img/icons/menu/delivery.svg"
              alt="Entrega"
            />

            <div>
              <h1>Entrega</h1>
              <span>O cliente recebe o pedido em casa</span>
            </div>
          </MenuCard>
        </button>

        <button
          type="button"
          onClick={() => handleNavigateToCategory(site.takeoutMenuId)}
        >
          <MenuCard>
            <img
              draggable={false}
              src="/img/icons/menu/takeout.svg"
              alt="Retirada"
            />

            <div>
              <h1>Retirada</h1>
              <span>
                O cliente pede e retira diretamente no estabelecimento.
              </span>
            </div>
          </MenuCard>
        </button>

        <button
          type="button"
          onClick={() => handleNavigateToCategory(site.indoorMenuId)}
        >
          <MenuCard>
            <img draggable={false} src="/img/icons/menu/table.svg" alt="Mesa" />

            <div>
              <h1>Mesa</h1>
              <span>O cliente faz o pedido pelo Qr na mesa</span>
            </div>
          </MenuCard>
        </button>
      </S.Cards>
    </S.Container>
  )
}

export default Menu
