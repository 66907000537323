import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
`

export const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;

  width: 100%;

  ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `};
`

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    flex-direction: column;

    height: 100%;

    padding: 1.5rem;

    background: ${theme.colors.primary};
    border-radius: 10px;

    textarea {
      resize: none;
      display: block;
      width: 100%;
      min-height: 16rem;
      padding: 1rem 1.2rem;
      font-size: 1.5rem;
      color: #cad2db;
      background-color: #020216e8;
      background-clip: padding-box;
      border: 2px solid #222;
      border-radius: 0.375rem;
      box-shadow: none;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: 0.4s;

      outline: none;

      &:hover,
      &:focus {
        border: 2px solid #2d81d3;
      }

      &.is-invalid {
        border-color: ${(props) => props.theme.colors.red};
      }
    }
  `}
`

export const CardTitle = styled.h1`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: 2rem;

    text-align: center;

    color: ${theme.colors.text.cards};
  `}
`

export const Button = styled.button`
  ${({ theme }) => css`
    padding: 1rem;

    background: ${theme.colors.blue};
    border-radius: 0.5rem;

    font-weight: 500;
    font-size: 2rem;

    color: ${theme.colors.text.cards};
  `}
`

export const Contacts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: 100%;

    div {
      display: flex;
      gap: 1rem;
      align-items: center;

      button {
        width: 20px;
        height: 20px;

        background: ${theme.colors.red};
        border-radius: 50%;

        transition: all 0.2s;

        &.selected {
          background: ${theme.colors.green};
        }
      }

      span {
        font-weight: 500;
        font-size: 2rem;

        color: ${theme.colors.text.cards};
      }
    }
  `}
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  input {
    display: block;
    width: 100%;
    height: calc(4rem + 2px);
    padding: 1rem 1.2rem;
    font-size: 1.5rem;
    color: #cad2db;
    background-color: #020216e8;
    background-clip: padding-box;
    border: 2px solid #222;
    border-radius: 0.375rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: 0.4s;

    outline: none;

    &:hover,
    &:focus {
      border: 2px solid #2d81d3;
    }

    &.is-invalid {
      border-color: ${(props) => props.theme.colors.red};
    }
  }
`
