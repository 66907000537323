import { colors } from 'utils/chartUtils'

const chartBarOptions = {
  colors,
  keys: ['Faturamento', 'Custo dos Produtos', 'Ticker Médio', 'Lucro Líquido'],
  indexBy: 'day',
  margin: {
    top: 30,
    right: 10,
    bottom: 50,
    left: 50
  },
  padding: 0.3,
  axisTop: null,
  theme: {
    tooltip: {
      container: {
        background: 'invisible',
        boxShadow: '0'
      }
    }
  },
  axisBottom: {
    tickRotation: -45
  },
  axisRight: null,
  enableLabel: false,
  animate: true,
  motionStiffness: 90,
  motionDamping: 15
}

const chartLineOptions = {
  colors,
  margin: {
    top: 30,
    right: 10,
    bottom: 60,
    left: 50
  },
  xScale: { type: 'point' },
  yScale: {
    type: 'linear',
    min: 'auto',
    max: 'auto',
    stacked: true,
    reverse: false
  },
  curve: 'natural',
  areaOpacity: 0.4,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 30,
    tickRotation: -45
  },
  enableGridX: false,
  pointSize: 10,
  pointColor: { theme: 'background' },
  pointBorderWidth: 2,
  pointBorderColor: { from: 'serieColor' },
  pointLabel: 'y',
  pointLabelYOffset: -12,
  enableArea: true,
  areaBaselineValue: 100,
  useMesh: true
}

export { chartBarOptions, chartLineOptions }
