import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const DatePicker = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
  `};
`

export const Options = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  button {
    font-weight: bold;
    font-size: 2rem;

    color: ${(props) => props.theme.colors.text.cards};

    padding-bottom: 0.5rem;

    &.selected {
      border-bottom: 2px solid ${(props) => props.theme.colors.text.cards};
    }
  }
`

export const ButtonClose = styled.button`
  padding: 10px;

  width: 15rem;

  background: ${(props) => props.theme.colors.red};
  border-radius: 1rem;

  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: ${(props) => props.theme.colors.text.cards};

  ${media.lessThan('medium')`
      margin-top: 2rem;
    `};
`
