import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { api } from 'services/api'
import { useAuth } from 'contexts/UserContext'
import { useToast } from 'contexts/ToastContext'
import { login } from 'services/auth'
import { ServiceProps, UserProps } from 'utils/props'

import * as S from './styles'

interface FormData {
  email: string
  password: string
  isEmployee: boolean
}

interface ResponseProps {
  token: string
  employee: UserProps
  user: UserProps
}

interface Props extends RouteComponentProps {}

const Login: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false)

  const { loadUser } = useAuth()
  const { showToast } = useToast()

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Você precisa inserir um e-mail válido')
      .required('E-mail obrigatório'),
    password: yup.string().required('Senha obrigatória')
    // isEmployee: yup.boolean().required('Este campo é obrigatório'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      setLoading(true)

      try {
        const { data: response } = await api('user-api').post<ResponseProps>(
          'auth/login',
          data
        )

        login(response.token)
        loadUser({
          ...response.employee,
          user: response.user
        })

        const message = `Seja bem vindo ${response.employee.name}`

        if (response.user) {
          showToast({ message, type: 'info' })
          history.push('/services/')
        } else {
          const { data: dataService } = await api('user-api').get<
            ServiceProps[]
          >('services/employees')

          if (!dataService[0] || (dataService[0] && !dataService[0].site)) {
            return showToast({
              message: 'Por Favor entrar em contato com suporte.',
              type: 'error'
            })
          }

          showToast({ message, type: 'info' })
          localStorage.setItem('last-active-service-type', 'site')
          localStorage.setItem('last-active-service-id', dataService[0].site.id)
          history.push('/dashboard/orders/')
        }
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setLoading(false)
      }
      // } else {
      //   const message = 'A validação do Recaptcha falhou.'
      //   showToast({ message, type: 'error' })
      // }
    },
    [showToast, history, loadUser]
  )

  return (
    <S.Body>
      <S.Left>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="email">E-mail</S.Label>
            <S.Input
              id="email"
              name="email"
              className={errors.email && 'is-invalid'}
              type="email"
              placeholder="E-mail"
              {...register('email')}
            />

            {errors.email && <S.Error>{errors.email.message}</S.Error>}
          </S.Col>

          <S.Col>
            <S.Label htmlFor="password">Senha</S.Label>
            <S.Input
              id="password"
              name="password"
              className={errors.password && 'is-invalid'}
              type="password"
              placeholder="Senha"
              {...register('password')}
            />

            {errors.password && <S.Error>{errors.password.message}</S.Error>}
          </S.Col>

          {/* <S.Col>
              <S.Label>Logar como funcionário</S.Label>
              <div>
                <S.CheckBox
                  name="isEmployee"
                  className={errors.isEmployee && 'is-invalid'}
                  type="checkbox"
                  defaultChecked
                  {...register('isEmployee')}
                />
              </div>
            </S.Col> */}

          <S.Button type="submit" disabled={loading}>
            {loading ? (
              <div className="divLoading">
                <span className="spanLoading" />
                <span className="spanLoading" />
                <span className="spanLoading" />
                <span className="spanLoading" />
              </div>
            ) : (
              'Entrar'
            )}
          </S.Button>
        </S.Form>
      </S.Left>
    </S.Body>
  )
}

export default Login
