import React, { useState } from 'react'

import Details from './Details'
import BannersInitial from './BannersInitial'
import BannersMain from './BannersMain'

import * as S from './styles'

const Profile: React.FC = () => {
  const [tabActive, setTabActive] = useState('DETAILS')

  const handleChangeSelectedTab = (
    tabSelected: 'DETAILS' | 'BANNERS INITIAL' | 'BANNERS MAIN'
  ) => {
    setTabActive(tabSelected)
  }

  return (
    <S.Container>
      <S.Content>
        <S.Options>
          <button
            type="button"
            className={`${tabActive === 'DETAILS' ? 'selected' : undefined}`}
            onClick={() => handleChangeSelectedTab('DETAILS')}
          >
            Detalhes
          </button>

          <button
            type="button"
            className={`${
              tabActive === 'BANNERS INITIAL' ? 'selected' : undefined
            }`}
            onClick={() => handleChangeSelectedTab('BANNERS INITIAL')}
          >
            Banners iniciais
          </button>

          <button
            type="button"
            className={`${
              tabActive === 'BANNERS MAIN' ? 'selected' : undefined
            }`}
            onClick={() => handleChangeSelectedTab('BANNERS MAIN')}
          >
            Banners principais
          </button>
        </S.Options>

        {tabActive === 'DETAILS' ? (
          <Details />
        ) : tabActive === 'BANNERS INITIAL' ? (
          <BannersInitial />
        ) : (
          <BannersMain />
        )}
      </S.Content>
    </S.Container>
  )
}

export default Profile
