import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect
} from 'react'
import { useSite } from 'contexts/SiteContext'
import errorHandler from 'services/errorHandler'

import moment from 'moment-timezone'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { ThemeContext } from 'styled-components'
import { api } from 'services/api'

import { chartBarOptions, chartLineOptions } from './ChartOptions'
import ChartTooltip from './ChartTooltip'
import { parseBarData, parseLineData, parseTooltipData } from 'utils/chartUtils'
import { numberBrFormatter } from 'utils/utils'

import DatePicker from 'components/DatePicker'

import type { OrderProps } from 'utils/props'

import * as S from './styles'

const Home = () => {
  const { site } = useSite()
  const themeContext = useContext(ThemeContext)
  const [orders, setOrders] = useState<OrderProps[]>([])
  const [chartType, setChartType] = useState('line')
  const [chart, setChart] = useState<{ [key: string]: number[] } | null>(null)
  const [period, setPeriod] = useState({
    startDate: moment(),
    endDate: moment()
  })
  const [selectedDate, setSelectedDate] = useState('today')
  const tourConfig = [
    {
      selector: '[data-tut="reactour__style"]',
      content: function DemoHelperComponent() {
        return (
          <div>
            <h1>Oi</h1>
          </div>
        )
      },
      style: {
        backgroundColor: 'black',
        color: 'white'
      }
    }
  ]

  const faturamento = useMemo(
    () =>
      orders
        .filter(
          (order) =>
            order.orderStatus !== 'PLACED' && order.orderStatus !== 'CANCELLED'
        )
        .reduce((totalAmount, i) => totalAmount + i.total.orderAmount, 0),
    [orders]
  )

  const totalPedidos = useMemo(
    () => orders.filter((order) => order.orderStatus !== 'CANCELLED').length,
    [orders]
  )

  const valorPedidosCancelados = useMemo(
    () =>
      orders
        .filter((order) => order.orderStatus === 'CANCELLED')
        .reduce((totalAmount, i) => totalAmount + i.total.orderAmount, 0),
    [orders]
  )

  const ticketMedio = useMemo(
    () => faturamento / (totalPedidos > 0 ? totalPedidos : 1),
    [faturamento, totalPedidos]
  )

  const custos = useMemo(
    () =>
      orders
        .filter(
          (order) =>
            order.orderStatus !== 'PLACED' && order.orderStatus !== 'CANCELLED'
        )
        .reduce(
          (totalCost, order) =>
            totalCost +
            order.items.reduce(
              (totalProduct, item) =>
                totalProduct + (item?.costValue?.value ?? 0),
              0
            ),

          0
        ),
    [orders]
  )

  const lucroLiquido = useMemo(
    () => faturamento - custos,
    [custos, faturamento]
  )

  const margemLucro = useMemo(() => {
    if (faturamento == 0) {
      return 0
    }

    return (lucroLiquido / faturamento) * 100
  }, [faturamento, lucroLiquido])

  const loadOrders = useCallback(async () => {
    try {
      const { data } = await api('user-api').get<OrderProps[]>('orders', {
        headers: {
          siteId: site.id,
          startDate: period.startDate.format('YYYY-MM-DD'),
          endDate: period.endDate.tz('America/Bahia').format('YYYY-MM-DD')
        }
      })

      const newData = data.map((order, index, array) => ({
        ...order,
        index: array.length - index
      }))

      setOrders(newData)
    } catch (err) {
      errorHandler(err)
    }
  }, [site, period])

  const loadChart = useCallback(async () => {
    const { data } = await api('user-api').get('orders/chart', {
      headers: {
        siteId: site.id
      }
    })

    setChart(data)
  }, [site])

  useEffect(() => {
    loadOrders()
  }, [loadOrders])

  useEffect(() => {
    loadChart()
  }, [loadChart])

  const onDate = useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) return

    if (!startDate.isValid() || !endDate.isValid()) return

    setPeriod({ startDate, endDate })
  }, [])

  const data = useMemo(
    () => ({
      status: 'success',
      chart: {
        dates: [
          moment().subtract(6, 'M'),
          moment().subtract(5, 'M'),
          moment().subtract(4, 'M'),
          moment().subtract(3, 'M'),
          moment().subtract(2, 'M'),
          moment().subtract(1, 'M'),
          moment()
        ],
        faturamento: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 }
        ],
        custoProdutos: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 }
        ],
        ticketMedio: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 }
        ],
        lucroLiquido: [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 }
        ],
        ...chart
      }
    }),
    [chart]
  )

  const handleChartSelect = useCallback(
    (chartSelected) => setChartType(chartSelected),
    []
  )

  const axisYTickLeft = useCallback(
    (tick) => {
      return (
        <g transform={`translate(${tick.x - 22},${tick.y}) rotate(-45)`}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fill: themeContext.colors.text.cards,
              fontFamily: 'Roboto',
              fontSize: 12
            }}
          >
            *{/* {`R$ ${numberBrFormatter(tick.value, 0)}`} */}
          </text>
        </g>
      )
    },
    [themeContext]
  )

  const axisYTickBottom = useCallback(
    (tick) => (
      <g transform={`translate(${tick.x},${tick.y})`}>
        <text
          transform="translate(0,10) rotate(-45)"
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: themeContext.colors.text.cards,
            fontFamily: 'Roboto',
            fontSize: 11
          }}
        >
          {tick.value}
        </text>
      </g>
    ),
    [themeContext]
  )

  return (
    <S.Container>
      <S.DatePicker>
        <DatePicker
          startDate={period.startDate}
          endDate={period.endDate}
          onDate={onDate}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      </S.DatePicker>
      <S.Cards>
        <S.Card>
          <div className="content">
            <header>
              <h1>Faturamento</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(faturamento, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color green" />
        </S.Card>

        <S.Card>
          <div className="content">
            <header>
              <h1>Custos dos produtos</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(custos, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color orange" />
        </S.Card>

        <S.Card>
          <div className="content">
            <header>
              <h1>Ticket médio</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(ticketMedio, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color purple" />
        </S.Card>

        <S.Card>
          <div className="content">
            <header>
              <h1>Lucro líquido</h1>
            </header>

            <footer>
              <span>
                R$ <strong>{numberBrFormatter(lucroLiquido, 2)}</strong>
              </span>
            </footer>
          </div>

          <S.Color className="color light-green" />
        </S.Card>
      </S.Cards>

      <S.Grid>
        <S.Chart>
          <div className="content">
            <header>
              <div style={{ marginBottom: '2.4rem' }}>
                <h3>GRÁFICO DE PERFORMANCE</h3>
                <p>Veja como está sua evolução</p>
              </div>
              <div>
                <select
                  name="chart"
                  id="chart"
                  defaultValue="line"
                  onChange={(e) => handleChartSelect(e.target.value)}
                >
                  <option value="line">Gráfico de Linha</option>
                  <option value="bar">Gráfico de Barra</option>
                </select>
              </div>
            </header>

            <div className="chart-content">
              {chartType === 'line' ? (
                <ResponsiveLine
                  data={parseLineData(data.chart)}
                  {...chartLineOptions}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    renderTick: axisYTickLeft
                  }}
                  axisBottom={{
                    renderTick: axisYTickBottom
                  }}
                  tooltip={({
                    point: {
                      data: { notParsedDate }
                    }
                  }) => (
                    <ChartTooltip
                      data={parseTooltipData(data?.chart, notParsedDate)}
                    />
                  )}
                />
              ) : (
                <ResponsiveBar
                  data={parseBarData(data?.chart)}
                  {...chartBarOptions}
                  axisLeft={{
                    renderTick: axisYTickLeft
                  }}
                  axisBottom={{
                    renderTick: axisYTickBottom
                  }}
                  tooltip={({ data: { notParsedDate } }) => (
                    <ChartTooltip
                      data={parseTooltipData(data?.chart, notParsedDate)}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <S.Color className="blue" />
        </S.Chart>

        <S.Resume>
          <div className="content">
            <div>
              <span>Lucro líquido:</span>

              <span>R$ {numberBrFormatter(lucroLiquido, 2)}</span>
            </div>

            <div>
              <span>Total de pedidos:</span>

              <span>{totalPedidos}</span>
            </div>

            <div>
              <span>Margem de lucro:</span>

              <span>{numberBrFormatter(margemLucro, 0)}%</span>
            </div>

            <div>
              <span>Pedidos cancelados:</span>

              <span>R$ {numberBrFormatter(valorPedidosCancelados, 2)}</span>
            </div>
          </div>

          <S.Color className="pink" />
        </S.Resume>
      </S.Grid>
    </S.Container>
  )
}

export default Home
