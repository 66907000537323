import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { TableProps } from 'utils/props'
import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'
import { api } from 'services/api'

import QrCode from 'components/QrCode'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: TableProps | null): void
  table?: TableProps
  tables: TableProps[]
  setTables(tables: TableProps[]): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const TableModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  table,
  tables,
  setTables
}) => {
  const { site } = useSite()
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const canvasRef = useRef()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const [available, setAvailable] = useState(true)

  const handleResetStats = useCallback(() => {
    reset()
    setAvailable(true)
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (table) {
      setValue('name', table.name, { shouldValidate: true })
      setAvailable(table.available)
    }
  }, [setValue, table])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleAvailableRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setAvailable(value === 'true')
  }

  const handleDownloadQR = () => {
    const canvas = document.getElementById(table.id) as HTMLCanvasElement
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${table.name}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const handleLinkTable = () => {
    return `${site.domain || `https://menuzer01.com/${site.slug}`}/${table.id}`
  }

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      const tableData = {
        name: data.name,
        available
      }

      const newTables = [...tables]

      if (table) {
        const response = await api('user-api').put<TableProps>(
          `tables/${table.id}`,
          tableData
        )

        const tableIndex = tables.findIndex((c) => c.id === table.id)

        newTables[tableIndex] = {
          ...tableData,
          id: table.id
        } as TableProps
      } else {
        const { data: response } = await api('user-api').post<TableProps>(
          'tables',
          {
            ...tableData,
            serviceId: site.service.id
          }
        )

        newTables.push({
          ...tableData,
          id: response.id
        } as TableProps)
      }

      try {
        setTables(newTables)

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      available,
      tables,
      table,
      site.service.id,
      setTables,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 50}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo mesa'}
          </h1>
          <p>Detalhes da mesa</p>
        </S.Header>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Table>
            <S.Col style={{ gridArea: 'qrcode ' }}>
              <S.QrCodeContainer>
                <QrCode
                  id={table ? table.id : ''}
                  link={table ? handleLinkTable() : null}
                />

                {table && (
                  <S.Button type="button" onClick={handleDownloadQR}>
                    Baixar QrCode
                  </S.Button>
                )}

                {table && (
                  <S.OpenTable href={handleLinkTable()} target="_blank">
                    Visualizar mesa
                  </S.OpenTable>
                )}
              </S.QrCodeContainer>
            </S.Col>

            <S.Col style={{ gridArea: 'table' }}>
              <S.Label htmlFor="name">Nome da mesa</S.Label>
              <S.Input
                id="name"
                name="name"
                placeholder="Ex: 01"
                className={errors.name && 'is-invalid'}
                {...register('name')}
              />
            </S.Col>

            <S.Col style={{ gridArea: 'available ' }}>
              <S.Label>Disponibilidade</S.Label>

              <S.RadioGroup>
                <label>
                  <input
                    name="available"
                    type="radio"
                    value="true"
                    onChange={handleAvailableRadio}
                    defaultChecked={available}
                  />
                  <span>
                    <div>
                      <div>Disponível</div>
                      <div>
                        A mesa ficará disponível para que o cliente possa
                        acessar
                      </div>
                    </div>
                  </span>
                </label>
                <label>
                  <input
                    name="available"
                    type="radio"
                    value="false"
                    onChange={handleAvailableRadio}
                    defaultChecked={!available}
                  />
                  <span>
                    <div>
                      <div>Indisponível</div>
                      <div>
                        A mesa ficará indisponível para que o cliente possa
                        acessar
                      </div>
                    </div>
                  </span>
                </label>
              </S.RadioGroup>
            </S.Col>
          </S.Table>

          <S.Buttons>
            <S.Button
              type="button"
              cancel
              onClick={() => handleToggleModal(null)}
            >
              Cancelar
            </S.Button>

            <S.Button
              type="submit"
              disabled={
                isLoading ||
                previewName === undefined ||
                previewName?.trim().length === 0
              }
            >
              Salvar
            </S.Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default TableModal
