import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'

import { IFoodModuleProps } from 'utils/props'
import { useModule } from './ModuleContext'
import { useSite } from './SiteContext'
import { polling, open, close, disconnect } from 'hooks/useIFood'

interface IFoodProviderProps {
  children: React.ReactNode
}

interface IFoodContextData {
  ifood: IFoodModuleProps | null
  setIFood(ifood: IFoodModuleProps): void
  handleOpenIFood(): void
  handleCloseIFood(): void
  handleDiscconectIFood(): void
}

const IFoodContext = createContext({} as IFoodContextData)

const IFoodProvider: React.FC<IFoodProviderProps> = ({ children }) => {
  const { module } = useModule()
  const { site } = useSite()
  const [ifood, setIFood] = useState<IFoodModuleProps | null>(null)

  useEffect(() => {
    setIFood(module?.ifoodModule)
  }, [module?.ifoodModule])

  const handlePolling = useCallback(async () => {
    const data = await polling({
      module: module?.ifoodModule?.id,
      siteid: site?.id
    })

    console.log(data)
  }, [module?.ifoodModule?.id, site?.id])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (ifood?.accessToken && ifood?.active) {
        await handlePolling()
      }
    }, 30000)

    return () => clearInterval(intervalId)
  }, [handlePolling, ifood?.accessToken, ifood?.active])

  const handleOpenIFood = useCallback(async () => {
    const data = await open(module?.ifoodModule?.id)
    await handlePolling()

    setIFood(data)
  }, [handlePolling, module?.ifoodModule?.id])

  const handleCloseIFood = useCallback(async () => {
    const data = await close(module?.ifoodModule?.id)

    setIFood(data)
  }, [module?.ifoodModule?.id])

  const handleDiscconectIFood = useCallback(async () => {
    try {
      const data = await disconnect(module?.ifoodModule?.id)

      setIFood(data)
    } catch (err) {
      console.log(err)
    }
  }, [module?.ifoodModule?.id])

  return (
    <IFoodContext.Provider
      value={{
        ifood,
        setIFood,
        handleOpenIFood,
        handleCloseIFood,
        handleDiscconectIFood
      }}
    >
      {children}
    </IFoodContext.Provider>
  )
}

function useIFood(): IFoodContextData {
  const context = useContext(IFoodContext)

  if (!context) {
    throw new Error('useIFood must be used within a IFoodContext')
  }

  return context
}

export { IFoodContext, IFoodProvider, useIFood }
