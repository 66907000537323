import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import pt from 'date-fns/locale/pt'

import {
  itemCategoryOptions,
  itemOptions,
  itemRecipeOptions
} from 'utils/itemOptions'
import { OrderProps } from 'utils/props'
import { formatPrice } from 'utils/utils'

export const useOrderPrint = (order: OrderProps) => {
  let html = `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Açaí Comandante</title>
      <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700" rel="stylesheet" >
        <style>
          * {
            margin: 0;
            padding: 0;
            outline: 0;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
          }
          
          body {
            background: "#FFF";
            -webkit-font-smoothing: antialiased;
            color: "#000000";
            position: relative;
          }
          
          body,
          input,
          button {
            font: 14px Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
          }
          
          @media print {
            @page {
              margin: 0mm;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
          
          .container {
            display: flex;
            width: 100%;
          }
          
          .content {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 20px;
          }
          
          .informacoes {
            display: flex;
            flex-direction: column;
          }
          
          .informacoes div {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
          }
          
          .informacoes span:not(.observacao) {
            margin-top: 5px;
          }

          .informacoes .observacao {
            margin-top: 15px;
          }
          
          .produto {
            display: flex;
            flex-direction: column;
            border: 1px dotted #000000;
            border-radius: 5px;
            padding: 15px;
          }
          
          .produto:not(:first-child) {
            margin-top: 15px;
          }
          
          .produto-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          
          .produto-header div {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          
          .produto-header-amount,
          .produto-header-name,
          .produto-header-price {
            color: #000000;
          }
          
          .produto-header-amount {
            margin-right: 5px;
          }
          
          .produto-header-name {
            font-weight: bold;
          }

          .optional-title {
            margin-top: 0.5rem;
          }
          
          .optional {
            display: flex;
            margin-left: 20px;
            white-space: normal;
          }
          
          .optional:not(:first-child) {
            margin-top: 5px;
          }
          
          .optional-name {
            display: flex;
            flex-direction: column;

            color: #000000;
          }
          
          .optional-name p,
          .optional-name span {
            display: inline-block;
          }
          
          .optional-name p {
            font-weight: bold;
            margin: 0 5px 0 0;
          }
          
          // .optional-name span:not(:last-child)::after {
          //   content: '+';
          //   margin: 0 5px;
          // }
          
          .total {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            text-align: end;
          }
          
          .total span {
            margin-top: 5px;
          }
          
          .total div {
            display: flex;
            flex-direction: column;
            margin-top: 15px;
          }
        </style>
      </head>
    <body>
    <main class="container">
      <div class="content">
        <div class="informacoes">
          <div>
            <span>Pedido ${order.index}</span>
            <span>${format(
              zonedTimeToUtc(order.createdAt, 'America/Sao_Paulo'),
              "d 'de' MMMM 'de' yyyy 'às' HH:mm",
              { locale: pt }
            )}</span>
            <br />
          </div>

          ${
            order.salesChannel === 'TOTEM'
              ? order.indoor.password
                ? `<span>Senha: ${order.indoor.password}</span>`
                : ''
              : order.orderType === 'INDOOR'
              ? `<span>Mesa: ${order.indoor.table}</span>`
              : ''
          }
          
          ${
            order.salesChannel !== 'IFOOD'
              ? order.salesChannel === 'TOTEM'
                ? `
              <div>
                <span>Cliente: ${order.indoor.clientName}</span>
              </div>
            `
                : `
              <div>
                <span>Cliente: ${order.customer.name}</span>
                <br />
                <span>Telefone: ${order.customer.phoneNumber}</span>
                <br />
                <span>${
                  order.customer?.ordersCountOnMerchant === 1
                    ? 'Primeiro pedido na sua loja'
                    : `${order.customer.ordersCountOnMerchant} pedidos na sua loja`
                }</span>
              </div>
            `
              : ``
          }

          ${
            order.orderType === 'DELIVERY'
              ? `
              <div>
                <span>Endereço: ${order.delivery.streetName}, ${order.delivery.streetNumber}</span>
                <br />
                <span>Ref: ${order.delivery.reference}</span>
                <span>Bairro: ${order.delivery.neighborhood}</span>
              </div>
            `
              : ''
          }
      </div>
    `

  order.items.forEach((item) => {
    html += `<div class="produto">
      <div class="item-header">
        <div> <span class="item-header-amount">${item.quantity}x</span> <span class="item-header-name">${item.name}</span> </div>
        <span class="item-header-price">`

    html += `${formatPrice(item.totalPrice)}`

    html += `</span>
      </div>`

    item.observations?.trim().length > 0
      ? (html += `
          <div class="optional">
            <div class="optional-name">
              <p>Observacao:</p>
              <span>${item.observations}</span>
            </div>
          </div>
          `)
      : (html += '')

    if (order.salesChannel !== 'IFOOD') {
      itemRecipeOptions(item).length > 0
        ? (html += '<span class="optional-title">Receita</span>')
        : (html += '')

      itemRecipeOptions(item).forEach((optionGroup, i: number) => {
        if (itemRecipeOptions(item)[i].options.length > 0) {
          html += `
          <div class="optional">
            <div class="optional-name">
              <p>${optionGroup.name}:</p>
              `

          itemRecipeOptions(item)[i].options.map(
            (option) =>
              (html += `<span> ${option.quantity}x ${option.name}</span>`)
          )

          html += `
            </div>
          </div>
        `
        }
      })

      itemCategoryOptions(item).length > 0
        ? (html += '<span class="optional-title">Opções da categoria</span>')
        : (html += '')

      itemCategoryOptions(item).forEach((optionGroup, i: number) => {
        if (itemCategoryOptions(item)[i].options.length > 0) {
          html += `
          <div class="optional">
            <div class="optional-name">
              <p>${optionGroup.name}:</p>
              `

          itemCategoryOptions(item)[i].options.map(
            (option) =>
              (html += `<span> ${option.quantity}x ${option.name}</span>`)
          )

          html += `
            </div>
          </div>
        `
        }
      })
    }

    itemOptions(item).length > 0
      ? (html += '<span class="optional-title">Adicionais</span>')
      : (html += '')

    itemOptions(item).forEach((optionGroup, i: number) => {
      if (itemOptions(item)[i].options.length > 0) {
        html += `
            <div class="optional">
              <div class="optional-name">
                ${
                  order.salesChannel !== 'IFOOD'
                    ? `<p>${optionGroup.name}:</p>`
                    : ``
                }
                `

        itemOptions(item)[i].options.map(
          (option) =>
            (html += `<span> ${option.quantity}x ${option.name}</span>`)
        )

        html += `
              </div>
            </div>
          `
      }
    })

    html += '</div>'
  })

  html += `<div class="total">
  <span>Subtotal: ${formatPrice(order.total.subTotal)}</span>
  <br />

  ${
    order.total.benefits !== 0
      ? `<span>Cupom: ${formatPrice(order.total.benefits)})</span><br />`
      : ''
  }
  `

  html += `${
    order.orderType
      ? `<span>Taxa de entrega: ${formatPrice(
          order.total.deliveryFee
        )}</span><br />`
      : ''
  }`

  html += `<span>Total: ${formatPrice(
    order.total.orderAmount
  )}</span></div><br />`

  html += `<div><span>Forma pagamento:</span><br />`

  order.payments.methods.forEach((method) => {
    html += `
      <br />
      <span>${
        method.type === 'OFFLINE'
          ? 'Cobrar do cliente'
          : 'Não cobrar do cliente'
      } - ${method.method} ${formatPrice(method.value)}</span>
    `

    if (method.haveChangeOption && method.change > 0) {
      html += `
        <br />
        <span>Troco para: ${formatPrice(method.change)}</span>
        <br />
        <span>Troco: ${formatPrice(method.change - method.value)}</span>
      `
    }
  })

  html += `</div>`

  html += `
      </div>
    </main>
  </body>
</html>`

  return {
    html
  }
}
