import { useAuth } from 'contexts/UserContext'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useCallback, useContext, useState } from 'react'
import { MdClose } from 'react-icons/md'
import 'react-responsive-modal/styles.css'
import { ThemeContext } from 'styled-components'

import { useMenuPrinter, useQzTray } from 'contexts/UtilsContext'
import { useOrderPrint } from 'hooks/useOrderPrint'
import usePersistedState from 'hooks/usePersistedState'
import { api } from 'services/api'
import errorHandler from 'services/errorHandler'
import {
  itemCategoryOptions,
  itemOptions,
  itemRecipeOptions
} from 'utils/itemOptions'
import { OrderProps, PrinterProps, PrintersModuleProps } from 'utils/props'
import { formatPrice, masks } from 'utils/utils'

import PaymentModal from '../PaymentModal'
import * as S from './styles'
import SelectMotoboyModal from '../SelectMotoboyModal'
import { useSite } from 'contexts/SiteContext'

interface Props {
  handleEditOrder(orderValue?: any): void
  isOpen: boolean
  order: OrderProps
  setOrder(newState: OrderProps): void
  orders: OrderProps[]
  setOrders(newState: OrderProps[]): void
  setIsOpen(value: boolean): void
}

const OrderModal: React.FC<Props> = ({
  handleEditOrder,
  isOpen,
  setIsOpen,
  order,
  setOrder,
  setOrders,
  orders
}) => {
  const { user } = useAuth()
  const themeContext = useContext(ThemeContext)
  const { menuPrinter, handlePrinter } = useMenuPrinter()
  const { qz } = useQzTray()
  const { html } = useOrderPrint(order)
  const [motoboyModalOpen, setMotoboyModalOpen] = useState(false)

  const [printersModule] = usePersistedState<PrintersModuleProps>(
    'printersModule',
    {
      component: 'MenuPrinter',
      printers: [],
      active: false
    }
  )

  const [isLoading, setIsLoading] = useState(false)
  const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false)
  const { site } = useSite()

  const handlePrintOrder = useCallback(async () => {
    if (printersModule.active) {
      const printers: PrinterProps[] = printersModule.printers.reduce(
        (prevValue, value) => {
          const orderType =
            order.orderType === 'DELIVERY'
              ? 'deliveryMenuId'
              : order.orderType === 'TAKEOUT'
              ? 'takeoutMenuId'
              : 'indoorMenuId'

          if (
            value.activatedOrder ||
            order.items.some((item) =>
              value.categories[orderType]?.includes(item.categoryId)
            )
          ) {
            prevValue.push(value)
          }

          return prevValue
        },
        []
      )

      if (
        printersModule.component === 'MenuPrinter' &&
        menuPrinter.isActive()
      ) {
        const printersMenuPrinter = menuPrinter.printers()

        printers.forEach((printer) => {
          if (printersMenuPrinter.some((p) => p === printer.name)) {
            handlePrinter({
              printerName: printer.name,
              printerManufacturer: printer.manufacturer,
              columnSize: printer.columnSize,
              fontSize: printer.fontSize,
              type: 'Order',
              data: order
            })
          } else {
            const date = new Date()
            const mSec = date.getTime()
            const mywindow = window.open(
              '',
              `MENUZER01_${mSec}`,
              'height=400,width=600'
            )

            mywindow.document.write(html)

            mywindow.document.close()
            mywindow.print()
            mywindow.focus()
            // mywindow.onafterprint = () => {
            //   mywindow.close()
            // }
          }
        })
      } else if (
        printersModule.component === 'QzTray' &&
        qz.websocket.isActive()
      ) {
        const printersQzTray: string[] = await qz.printers.find()

        printers.forEach((printer) => {
          if (printersQzTray.some((p) => p === printer.name)) {
            const config = qz.configs.create(printer.name, {
              scaleContent: false,
              rasterize: false,
              border: {
                x: 0,
                y: 0,
                width: 0,
                height: 0
              },
              margins: 0
            })

            qz.print(config, [
              {
                type: 'html',
                format: 'plain',
                data: html
              }
            ])
          } else {
            const date = new Date()
            const mSec = date.getTime()
            const mywindow = window.open(
              '',
              `MENUZER01_${mSec}`,
              'height=400,width=600'
            )

            mywindow.document.write(html)

            mywindow.document.close()
            mywindow.print()
            mywindow.focus()
            // mywindow.onafterprint = () => {
            //   mywindow.close()
            // }
          }
        })
      } else {
        const date = new Date()
        const mSec = date.getTime()
        const mywindow = window.open(
          '',
          `MENUZER01_${mSec}`,
          'height=400,width=600'
        )

        mywindow.document.write(html)

        mywindow.document.close()
        mywindow.print()
        mywindow.focus()
        // mywindow.onafterprint = () => {
        //   mywindow.close()
        // }
      }
    } else {
      const date = new Date()
      const mSec = date.getTime()
      const mywindow = window.open(
        '',
        `MENUZER01_${mSec}`,
        'height=400,width=600'
      )

      mywindow.document.write(html)

      mywindow.document.close()
      mywindow.print()
      mywindow.focus()
      mywindow.onafterprint = () => {
        mywindow.close()
      }
    }
  }, [handlePrinter, html, menuPrinter, order, qz, printersModule])

  const handleOrderStatus = useCallback(
    async (
      status:
        | 'PLACED'
        | 'CONFIRMED'
        | 'READY'
        | 'DISPATCHED'
        | 'CONCLUDED'
        | 'PENDING'
        | 'CANCELLED'
    ) => {
      setIsLoading(true)

      if (order.salesChannel === 'IFOOD') return

      try {
        if (order.orderStatus === 'DISPATCHED' && status === 'READY') {
          if (site.motoboys.length > 0) {
            const { data } = await api('user-api').delete(
              `/orders/${order.id}/motoboyOrders`
            )
          }
        }

        if (status === 'CANCELLED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/cancel`
          )
        } else if (status === 'PLACED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/placed`
          )
        } else if (status === 'CONFIRMED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/confirm`
          )

          handlePrintOrder()
        } else if (status === 'READY') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/readyToPickup`
          )
        } else if (status === 'DISPATCHED') {
          if (site.motoboys.length > 0) {
            setMotoboyModalOpen(true)
            return
          }
        } else if (status === 'CONCLUDED') {
          const { data } = await api('user-api').put(
            `orders/${order.id}/concluded`
          )
        }

        setOrder({
          ...order,
          orderStatus: status
        })
        setOrders(
          orders.map((item) => {
            if (item.id === order.id) {
              return { ...item, orderStatus: status }
            }

            return item
          })
        )

        handleEditOrder(null)
      } catch (err) {
        errorHandler(err)
      } finally {
        setIsLoading(false)
      }
    },
    [
      handleEditOrder,
      handlePrintOrder,
      order,
      orders,
      setOrder,
      setOrders,
      site.motoboys.length
    ]
  )

  const orderStatusToNumber = () => {
    switch (order.orderStatus) {
      case 'CANCELLED':
        return 0
      case 'PLACED':
        return 1
      case 'CONFIRMED':
        return 2
      case 'READY':
        return 2
      case 'DISPATCHED':
        return 4
      case 'CONCLUDED':
        return 5
      case 'PENDING':
        return 5
      default:
        return 1
    }
  }

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleEditOrder(null)}
      center
      styles={{
        modal: {
          // width: '100%',
          padding: '2rem',
          background: themeContext.colors.primary,
          borderRadius: '1.5rem'
        }
      }}
    >
      <SelectMotoboyModal
        isOpen={motoboyModalOpen}
        setIsModalOrderOpen={setIsOpen}
        order={order}
        orders={orders}
        setOrder={setOrder}
        setOrders={setOrders}
        handleClose={() => setMotoboyModalOpen(false)}
      />

      <S.Content>
        <S.Header>
          <div className="left">
            <img
              src="/img/icons/orders/delivery.svg"
              alt="Pedido para entregar"
            />

            <div>
              {order.salesChannel === 'SITE' ? (
                <img
                  src="/favicon.png"
                  alt="Pedido feito pelo Site"
                  title="Pedido feito pelo Site"
                />
              ) : order.salesChannel === 'APPLICATION' ? (
                <img
                  src="/img/icons/orders/application.svg"
                  alt="Pedido feito pelo Aplicativo"
                  title="Pedido feito pelo Aplicativo"
                />
              ) : (
                order.salesChannel === 'IFOOD' && (
                  <img
                    src="/img/icons/orders/ifood.svg"
                    alt="Pedido feito pelo IFood"
                    title="Pedido feito pelo IFood"
                  />
                )
              )}

              {order.salesChannel !== 'IFOOD' &&
                order.salesChannel !== 'TOTEM' && (
                  <>
                    {order.customer?.ordersCountOnMerchant === 1 ? (
                      <h1>Primeiro pedido na sua loja</h1>
                    ) : (
                      <h1>
                        <span>{order.customer?.ordersCountOnMerchant}</span>{' '}
                        pedidos feitos
                      </h1>
                    )}
                  </>
                )}

              {/* <h1>
                Pedido #0000000 <span>#0000000</span>
              </h1> */}

              <h1>
                Data{' '}
                {format(parseISO(order.createdAt), 'd MMM', {
                  locale: ptBR
                })}{' '}
                <span>
                  {format(parseISO(order.createdAt), 'HH:mm', {
                    locale: ptBR
                  })}
                </span>
              </h1>
            </div>
          </div>

          <div className="right">
            <button
              type="button"
              title="Fechar"
              onClick={() => handleEditOrder(null)}
            >
              <MdClose />
            </button>
          </div>
        </S.Header>

        <S.Grid>
          <S.Left>
            <div>
              <S.Title>Informações do pedido</S.Title>

              {/* <div className="info">
                <img src="/img/icons/orders/helmet.svg" alt="Entregador" />

                <div>
                  <h1>Entregador</h1>
                  <span>Guilherme</span>
                </div>
              </div> */}

              <S.OrderStatus>
                <div>
                  {orderStatusToNumber() === 0 ? (
                    <h1>Pedido cancelado</h1>
                  ) : (
                    <h1>Pedido recebido</h1>
                  )}

                  {((orderStatusToNumber() === 0 &&
                    order.cancelledStartDateTime) ||
                    (orderStatusToNumber() === 1 && order.createdAt)) && (
                    <span>
                      {format(
                        parseISO(
                          orderStatusToNumber() === 0
                            ? order.cancelledStartDateTime
                            : order.createdAt
                        ),
                        'd MMM HH:mm',
                        {
                          locale: ptBR
                        }
                      )}
                    </span>
                  )}
                </div>

                {orderStatusToNumber() >= 2 && (
                  <div>
                    <h1>Pedido em produção</h1>
                    {order.confirmedStartDateTime && (
                      <span>
                        {format(
                          parseISO(order.confirmedStartDateTime),
                          'd MMM HH:mm',
                          {
                            locale: ptBR
                          }
                        )}
                      </span>
                    )}
                  </div>
                )}

                {orderStatusToNumber() >= 4 && (
                  <div>
                    <h1>Pedido despachado</h1>
                    {order.dispatchedStartDateTime && (
                      <span>
                        {format(
                          parseISO(order.dispatchedStartDateTime),
                          'd MMM HH:mm',
                          {
                            locale: ptBR
                          }
                        )}
                      </span>
                    )}
                  </div>
                )}

                {orderStatusToNumber() >= 5 && (
                  <div>
                    <h1>Pedido entregue</h1>
                    {order.concludedStartDateTime && (
                      <span>
                        {format(
                          parseISO(order.concludedStartDateTime),
                          'd MMM HH:mm',
                          {
                            locale: ptBR
                          }
                        )}
                      </span>
                    )}
                  </div>
                )}
              </S.OrderStatus>
            </div>

            {order.salesChannel !== 'IFOOD' && (
              <S.Customer>
                <S.Title>Cliente</S.Title>

                <div className="info">
                  <img src="/img/icons/orders/user.svg" alt="Cliente" />

                  {order.orderType === 'INDOOR' &&
                  order.salesChannel === 'TOTEM' ? (
                    <div>
                      <h1>{order.indoor.clientName}</h1>
                      {order.indoor.password && (
                        <span>{order.indoor.password}</span>
                      )}
                    </div>
                  ) : (
                    <div>
                      <h1>{order.customer?.name}</h1>
                      <span>{order.customer?.phoneNumber}</span>
                      {order.customer?.cpf && (
                        <span>{masks.cpf(order.customer?.cpf)}</span>
                      )}
                      {order.customer?.email && (
                        <span>{order.customer?.email}</span>
                      )}
                    </div>
                  )}
                </div>
              </S.Customer>
            )}

            {order.orderType === 'DELIVERY' ? (
              <div>
                <S.Title>Endereço para entrega</S.Title>

                {order.salesChannel !== 'IFOOD' ? (
                  <div className="info">
                    <img src="/img/icons/orders/location.svg" alt="Endereço" />

                    <div>
                      <h1>
                        {order.delivery.streetName},{' '}
                        {order.delivery.streetNumber} -{' '}
                        {order.delivery.reference}
                      </h1>
                      <span>{order.delivery.neighborhood}</span>
                    </div>
                  </div>
                ) : (
                  <div className="info">
                    <img src="/img/icons/orders/location.svg" alt="Endereço" />

                    <div>
                      <h1>
                        {order.delivery.deliveryAddress.streetName},{' '}
                        {order.delivery.deliveryAddress.streetNumber} -{' '}
                        {order.delivery.deliveryAddress.reference}
                      </h1>
                      <span>{order.delivery.deliveryAddress.neighborhood}</span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              order.orderType === 'INDOOR' &&
              order.salesChannel !== 'TOTEM' && (
                <div>
                  <S.Title>Mesa para entrega</S.Title>
                  <div className="info">
                    <img src="/img/icons/orders/location.svg" alt="Endereço" />

                    <div>
                      <h1>Mesa: {order.indoor.table}</h1>
                    </div>
                  </div>{' '}
                </div>
              )
            )}

            <div>
              <S.Title>Itens</S.Title>

              {order.items.map((item, index) => (
                <S.Product key={index}>
                  <h1>
                    {item.quantity}x {item.name} {formatPrice(item.totalPrice)}
                  </h1>

                  <S.Options>
                    {item.observations?.trim().length > 0 && (
                      <S.Option>
                        <div>
                          <p>Observação:</p> <span>{item.observations}</span>
                        </div>
                      </S.Option>
                    )}

                    {order.salesChannel !== 'IFOOD' &&
                      itemRecipeOptions(item).length > 0 && <h1>Receita</h1>}

                    {order.salesChannel !== 'IFOOD' &&
                      itemRecipeOptions(item).map((optionGroup, i: number) => {
                        if (itemRecipeOptions(item)[i].options.length > 0) {
                          return (
                            <S.Option key={i}>
                              <p>{optionGroup.name}:</p>
                              {itemRecipeOptions(item)[i].options.map(
                                (option, itemIndex: number) => (
                                  <span key={itemIndex}>
                                    {option.quantity}x {option.name}
                                  </span>
                                )
                              )}
                            </S.Option>
                          )
                        }

                        return false
                      })}

                    {order.salesChannel !== 'IFOOD' &&
                      itemCategoryOptions(item).length > 0 && (
                        <h1>Opções da categoria</h1>
                      )}

                    {order.salesChannel !== 'IFOOD' &&
                      itemCategoryOptions(item).map(
                        (optionGroup, i: number) => {
                          if (itemCategoryOptions(item)[i].options.length > 0) {
                            return (
                              <S.Option key={i}>
                                <p>{optionGroup.name}:</p>
                                {itemCategoryOptions(item)[i].options.map(
                                  (option, itemIndex: number) => (
                                    <span key={itemIndex}>
                                      {option.quantity}x {option.name}
                                    </span>
                                  )
                                )}
                              </S.Option>
                            )
                          }

                          return false
                        }
                      )}

                    {itemOptions(item).length > 0 && <h1>Adicionais</h1>}

                    {itemOptions(item).map((optionGroup, i: number) => {
                      if (itemOptions(item)[i].options.length > 0) {
                        return (
                          <S.Option key={i}>
                            {order.salesChannel !== 'IFOOD' && (
                              <p>{optionGroup.name}:</p>
                            )}
                            {itemOptions(item)[i].options.map(
                              (option, itemIndex: number) => (
                                <span key={itemIndex}>
                                  {option.quantity}x {option.name}
                                </span>
                              )
                            )}
                          </S.Option>
                        )
                      }

                      return false
                    })}
                  </S.Options>
                </S.Product>
              ))}
            </div>

            <div>
              <S.TitleContainer>
                <S.Title>Formas de pagamentos</S.Title>

                <img
                  onClick={() => setEditPaymentModalOpen(true)}
                  style={{ cursor: 'pointer' }}
                  src="/img/icons/orders/pencil-white.svg"
                  alt="Editar"
                  width="18px"
                />
              </S.TitleContainer>

              <S.Payments>
                {order.payments.methods.map((method, i) => (
                  <S.Payment key={i}>
                    <div className="forms">
                      <h1>
                        {method.type === 'OFFLINE'
                          ? 'Cobrar do cliente'
                          : 'Não cobrar do cliente'}{' '}
                        - {method.method}&nbsp;
                      </h1>
                      <span>{formatPrice(method.value)}</span>
                    </div>

                    {method.haveChangeOption && method.change > 0 && (
                      <>
                        <div className="forms">
                          <small>Troco para</small>
                          <span>{formatPrice(method.change)}</span>
                        </div>
                        <div className="forms">
                          <small>Troco</small>
                          <span>
                            {formatPrice(method.change - method.value)}
                          </span>
                        </div>
                      </>
                    )}
                  </S.Payment>
                ))}
              </S.Payments>
            </div>
          </S.Left>

          <S.Right>
            <div>
              <div>
                <span>Subtotal</span>
                <h1>{formatPrice(order.total.subTotal)}</h1>
              </div>
              <div className="green">
                <span>Tx. Entrega</span>
                <h1>{formatPrice(order.total.deliveryFee)}</h1>
              </div>
              <div className="red">
                <span>Desconto</span>
                <h1>{formatPrice(order.total.benefits)}</h1>
              </div>
              <div className="blue">
                <span>Total</span>
                <h1>{formatPrice(order.total.orderAmount)}</h1>
              </div>
            </div>

            <div>
              <button type="button" className="blue" onClick={handlePrintOrder}>
                Imprimir
              </button>

              {order.orderStatus === 'PLACED' ? (
                <button
                  type="button"
                  className="red"
                  title="Você não tem permissão para cancelar o pedido!"
                  disabled={isLoading || !user.permissions.pedido_cancelar}
                  {...(user.permissions.pedido_cancelar && {
                    onClick: () => handleOrderStatus('CANCELLED')
                  })}
                >
                  Cancelar
                </button>
              ) : order.orderStatus === 'CONFIRMED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('PLACED')}
                  disabled={isLoading}
                >
                  Voltar para aguardando
                </button>
              ) : order.orderStatus === 'READY' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('CONFIRMED')}
                  disabled={isLoading}
                >
                  Voltar para preparando
                </button>
              ) : order.orderStatus === 'DISPATCHED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('READY')}
                  disabled={isLoading}
                >
                  Voltar para pronto
                </button>
              ) : order.orderStatus === 'CONCLUDED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() =>
                    handleOrderStatus(
                      order.orderType === 'DELIVERY' ? 'DISPATCHED' : 'READY'
                    )
                  }
                  disabled={isLoading}
                >
                  {order.orderType === 'DELIVERY'
                    ? 'Voltar para entregando'
                    : 'Voltar para pronto'}
                </button>
              ) : (
                order.orderStatus === 'CANCELLED' && (
                  <button
                    type="button"
                    className="red"
                    title="Você não tem permissão para voltar o pedido!"
                    disabled={isLoading || !user.permissions.pedido_cancelar}
                    {...(user.permissions.pedido_cancelar && {
                      onClick: () => handleOrderStatus('PLACED')
                    })}
                  >
                    Voltar para aguardando
                  </button>
                )
              )}

              {order.orderStatus === 'PLACED' ? (
                <button
                  type="button"
                  className="green"
                  onClick={() => handleOrderStatus('CONFIRMED')}
                  disabled={isLoading}
                >
                  Aceitar
                </button>
              ) : order.orderStatus === 'CONFIRMED' ? (
                <button
                  type="button"
                  className="green"
                  onClick={() => handleOrderStatus('READY')}
                  disabled={isLoading}
                >
                  Pronto
                </button>
              ) : order.orderStatus === 'READY' ? (
                <button
                  type="button"
                  className="green"
                  onClick={() =>
                    handleOrderStatus(
                      order.orderType === 'DELIVERY'
                        ? 'DISPATCHED'
                        : 'CONCLUDED'
                    )
                  }
                  disabled={isLoading}
                >
                  {order.orderType === 'DELIVERY'
                    ? 'Sair para entrega'
                    : 'Entregue'}
                </button>
              ) : (
                order.orderStatus === 'DISPATCHED' && (
                  <button
                    type="button"
                    className="green"
                    onClick={() => handleOrderStatus('CONCLUDED')}
                    disabled={isLoading}
                  >
                    Entregue
                  </button>
                )
              )}
            </div>
          </S.Right>
        </S.Grid>
      </S.Content>

      <PaymentModal
        isOpen={editPaymentModalOpen}
        handleClose={() => setEditPaymentModalOpen(false)}
        order={order}
        setOrder={setOrder}
        setOrders={
          setOrders as unknown as (
            newState: (prevState: OrderProps[]) => OrderProps[]
          ) => void
        }
      />
    </S.Container>
  )
}

export default OrderModal
