import React from 'react'
import { OptionTypeBase, Props as SelectProps } from 'react-select'

import * as S from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  isMultiSelect?: boolean
}

const Select: React.FC<Props> = ({ isMultiSelect = false, ...rest }) => (
  <S.Container
    {...rest}
    classNamePrefix="react-select"
    isMulti={isMultiSelect}
    theme={undefined}
    noOptionsMessage={() => 'Nenhuma opção encontrada!'}
  />
)

export default Select
