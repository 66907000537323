import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { useSite } from 'contexts/SiteContext'
import { useToast } from 'contexts/ToastContext'
import { api } from 'services/api'

import type { ClientFormConfigProps } from 'utils/props'
import * as S from './styles'

const ClientFormConfig: React.FC = () => {
  const { site, setSite } = useSite()
  const { showToast } = useToast()
  const themeContext = useContext(ThemeContext)
  const [clientFormConfig, setClientFormConfig] =
    useState<ClientFormConfigProps | null>(site.clientFormConfig ?? null)

  let time: NodeJS.Timeout = null

  const carregarDados = useCallback(async () => {
    if (!site.clientFormConfig) {
      const { data } = await api('user-api').get<ClientFormConfigProps>(
        `services/${site.serviceId}/client-form`
      )
      setClientFormConfig(data)
    }
  }, [site.clientFormConfig, site.serviceId])

  useEffect(() => {
    carregarDados()
  }, [carregarDados])

  const handleUpdateClientFormConfig = useCallback(
    async (updatedClientFormConfig: ClientFormConfigProps) => {
      try {
        clearTimeout(time)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        time = setTimeout(async () => {
          await api('user-api').put(
            `services/${site.serviceId}/client-form`,
            updatedClientFormConfig
          )

          showToast({
            message:
              'Configuração de formulário de dados do cliente atualizadas com sucesso!',
            type: 'success'
          })
        }, 1000)
      } catch (err) {
        showToast({
          message: 'Aconteceu um erro!',
          type: 'error'
        })
      }
    },
    [setSite, showToast, site]
  )

  const handleUpdateStatusArea = useCallback(
    ({ key }) => {
      if (!clientFormConfig) {
        return
      }

      setClientFormConfig((prevState) => {
        const newClientFormConfig = {
          ...prevState,
          [key]: !prevState[key]
        }

        handleUpdateClientFormConfig(newClientFormConfig)

        return newClientFormConfig
      })
    },
    [clientFormConfig, handleUpdateClientFormConfig]
  )

  return (
    <>
      <S.Container>
        <S.Title>Dados do cliente</S.Title>

        <S.Payments>
          <S.Payment>
            <S.PaymentLeft>
              <label htmlFor="Nome">Nome</label>
            </S.PaymentLeft>

            <S.PaymentRight>
              <S.PaymentStatus
                checked
                disabled
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={60}
                handleDiameter={12}
                offColor={themeContext.colors.red}
                onColor={themeContext.colors.green}
                onChange={() => {
                  return
                }}
              />
            </S.PaymentRight>
          </S.Payment>

          <S.Payment>
            <S.PaymentLeft>
              <label htmlFor="CPF">CPF</label>
            </S.PaymentLeft>

            <S.PaymentRight>
              <S.PaymentStatus
                checked={clientFormConfig?.cpf ?? false}
                onChange={() => handleUpdateStatusArea({ key: 'cpf' })}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={60}
                handleDiameter={12}
                offColor={themeContext.colors.red}
                onColor={themeContext.colors.green}
              />
            </S.PaymentRight>
          </S.Payment>

          <S.Payment>
            <S.PaymentLeft>
              <label htmlFor="E-mail">E-mail</label>
            </S.PaymentLeft>

            <S.PaymentRight>
              <S.PaymentStatus
                checked={clientFormConfig?.email ?? false}
                onChange={() => handleUpdateStatusArea({ key: 'email' })}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={60}
                handleDiameter={12}
                offColor={themeContext.colors.red}
                onColor={themeContext.colors.green}
              />
            </S.PaymentRight>
          </S.Payment>

          <S.Payment>
            <S.PaymentLeft>
              <label htmlFor="Telefone">Telefone</label>
            </S.PaymentLeft>

            <S.PaymentRight>
              <S.PaymentStatus
                checked
                disabled
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={60}
                handleDiameter={12}
                offColor={themeContext.colors.red}
                onColor={themeContext.colors.green}
                onChange={() => {
                  return
                }}
              />
            </S.PaymentRight>
          </S.Payment>
        </S.Payments>
      </S.Container>
    </>
  )
}

export default ClientFormConfig
