import React, { useState, useRef, useCallback } from 'react'
import { MdImage } from 'react-icons/md'

import errorHandler from 'services/errorHandler'

import * as S from './styles'

interface Props {
  setImage(base64: string | ArrayBuffer): void
}

const ImageInput: React.FC<Props> = ({ setImage }) => {
  const [preview, setPreview] = useState(null)

  const ref = useRef()

  const handleChanged = useCallback(
    (e) => {
      const file = e.target.files[0]

      if (!file) {
        setPreview(null)
        setImage(null)
        return
      }

      const url = URL.createObjectURL(file)
      setPreview(url)

      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = function () {
        setImage(reader.result)
      }

      reader.onerror = function (error) {
        return errorHandler(error)
      }
    },
    [setImage]
  )

  return (
    <S.Container>
      <label htmlFor="imagem">
        {preview ? (
          <img src={preview} alt="imagem" />
        ) : (
          <div>
            <MdImage /> <strong>Adicionar imagem</strong>
          </div>
        )}

        <input
          type="file"
          id="imagem"
          accept="image/*"
          onChange={handleChanged}
          ref={ref}
        />
      </label>
    </S.Container>
  )
}

export default ImageInput
