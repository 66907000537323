import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { CategoryProps, OptionGroupProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'
import { useWindowSize } from 'hooks/useWindowSize'
import { api } from 'services/api'

import SelectCategoryTypeCard from './SelectCategoryTypeCard'
import SizesCategoryCard from './SizesCategoryCard'
import ImageInput from './ImageInput'
import Options from './Options'

import * as S from './styles'

interface Props {
  isOpen: boolean
  handleToggle(item: CategoryProps | null): void
  categories: CategoryProps[]
  setCategories(categories: CategoryProps[]): void
  category?: CategoryProps
  handleUpdateCategories({ value }: { value: CategoryProps[] }): void
}

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório')
})

interface FormDataProps {
  name: string
}

const CategoryModal: React.FC<Props> = ({
  isOpen,
  handleToggle,
  categories,
  setCategories,
  category,
  handleUpdateCategories
}) => {
  const { showToast } = useToast()
  const [width, height] = useWindowSize()

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [isLoading, setIsLoading] = useState(false)
  const previewName = watch('name')
  const [tabActive, setTabActive] = useState('DETAILS')
  const [image, setImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const [categoryType, setCategoryType] = useState(null)
  const [categorySizes, setCategorySizes] = useState([
    (() => {
      const id = uuidv4()

      return {
        id,
        name: 'Pequena',
        posCode: 't01',
        multipleToppingsSizeIds: {
          oneToppingSizeId: id,
          twoToppingsSizeId: null,
          threeToppingsSizeId: null,
          fourToppingsSizeId: null
        },
        available: true
      }
    })(),

    (() => {
      const id = uuidv4()

      return {
        id,
        name: 'Média',
        posCode: 't02',
        multipleToppingsSizeIds: {
          oneToppingSizeId: id,
          twoToppingsSizeId: `${id}2`,
          threeToppingsSizeId: null,
          fourToppingsSizeId: null
        },
        available: true
      }
    })(),

    (() => {
      const id = uuidv4()

      return {
        id,
        name: 'Grande',
        posCode: 't03',
        multipleToppingsSizeIds: {
          oneToppingSizeId: id,
          twoToppingsSizeId: `${id}2`,
          threeToppingsSizeId: `${id}3`,
          fourToppingsSizeId: null
        },
        available: true
      }
    })()
  ])
  const [optionGroup, setOptionGroup] = useState<OptionGroupProps[]>([])
  const [availability, setAvailability] = useState('ALWAYS')

  const categoriesModes = [
    {
      type: 'CATEGORY',
      image: '/img/icons/menu/items.svg',
      title: 'Modo de categoria',
      subtitle: 'Crie vários itens dentro de uma categoria'
    },
    {
      type: 'SUBCATEGORY',
      image: '/img/icons/menu/pizzas.svg',
      title: 'Modo de subcategoria',
      subtitle:
        'Crie várias subcategorias e dentro dessas subcategorias crie seus itens'
    }
  ]

  const categoriesTypes = [
    {
      type: 'ITEMS',
      image: '/img/icons/menu/items.svg',
      title: 'Itens principais',
      subtitle: 'Comidas, lanches, sobremesas, etc.'
    },
    {
      type: 'PIZZAS',
      image: '/img/icons/menu/pizzas.svg',
      title: 'Pizzas',
      subtitle: 'Defina o tamanho das pizzas'
    }
  ]

  const handleResetStats = useCallback(() => {
    reset()
    setAvailability('ALWAYS')
    setOptionGroup([])
    setCategorySizes([
      (() => {
        const id = uuidv4()

        return {
          id,
          name: 'Pequena',
          posCode: 't01',
          multipleToppingsSizeIds: {
            oneToppingSizeId: id,
            twoToppingsSizeId: null,
            threeToppingsSizeId: null,
            fourToppingsSizeId: null
          },
          available: true
        }
      })(),

      (() => {
        const id = uuidv4()

        return {
          id,
          name: 'Média',
          posCode: 't02',
          multipleToppingsSizeIds: {
            oneToppingSizeId: id,
            twoToppingsSizeId: `${id}2`,
            threeToppingsSizeId: null,
            fourToppingsSizeId: null
          },
          available: true
        }
      })(),

      (() => {
        const id = uuidv4()

        return {
          id,
          name: 'Grande',
          posCode: 't03',
          multipleToppingsSizeIds: {
            oneToppingSizeId: id,
            twoToppingsSizeId: `${id}2`,
            threeToppingsSizeId: `${id}3`,
            fourToppingsSizeId: null
          },
          available: true
        }
      })()
    ])
    setCategoryType(null)
    setPreviewImage(null)
    setImage(null)
    setTabActive('DETAILS')
    setIsLoading(false)
  }, [reset])

  useEffect(() => {
    if (category) {
      setCategoryType(category.type)
      setCategorySizes(category.sizes ? [...category.sizes] : [])
      setImage(category.image)
      setPreviewImage(category.image)
      setValue('name', category.name, { shouldValidate: true })
      setOptionGroup(category.optionGroup ? category.optionGroup : [])
      setAvailability(category.availability)
    }
  }, [category, setValue])

  const handleToggleModal = useCallback(
    (item = null) => {
      handleToggle(item)
      handleResetStats()
    },
    [handleResetStats, handleToggle]
  )

  const handleSelectTabActive = useCallback((tab: string) => {
    setTabActive(tab)
  }, [])

  const handleSelectCategoryType = useCallback((typeSelected) => {
    setCategoryType(typeSelected)
  }, [])

  const handleAvailabilityRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setAvailability(value)
    },
    []
  )

  const onSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)

      let imageUrl = typeof image === 'string' ? image : null

      if (image && typeof image !== 'string') {
        const data = new FormData()
        data.append('file', image)

        const response = await api('user-api').post('files/images', data)

        const { url } = response.data
        imageUrl = url
      }

      const categoryData = {
        id: category ? category.id : uuidv4(),
        icon: null,
        image: imageUrl,
        name: data.name,
        type: categoryType,
        items: category ? category.items : [],
        sizes: categorySizes,
        canDuplicate: category ? category.canDuplicate : true,
        canEdit: category ? category.canEdit : true,
        optionGroup,
        shifts: {
          startTime: '00:00',
          endTime: '23:59',
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true
        },
        available: category ? category.available : true,
        availability
      }

      const newCategories = [...categories]

      if (category) {
        const categoryIndex = categories.findIndex((c) => c.id === category.id)

        newCategories[categoryIndex] = categoryData as CategoryProps
      } else {
        newCategories.push(categoryData as CategoryProps)
      }

      try {
        handleUpdateCategories({ value: newCategories })
        setCategories(newCategories)

        handleToggleModal(null)
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      image,
      category,
      categoryType,
      categorySizes,
      optionGroup,
      availability,
      categories,
      handleUpdateCategories,
      setCategories,
      handleToggleModal,
      showToast
    ]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggleModal(null)}
      center
      styles={{
        modal: {
          background: '#293949',
          padding: '2rem',
          borderRadius: '1.5rem',
          minWidth: width <= 1000 ? `${width - 50}px` : '1000px',
          minHeight: width <= 1000 ? `${height - 20}px` : '600px'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <h1>
            {previewName && previewName.trim().length > 0
              ? previewName
              : 'Novo categoria'}
          </h1>
          <p>
            {categoryType
              ? 'Selecione o modelo de categoria'
              : 'Detalhes da categoria'}
          </p>

          {categoryType && (
            <S.Options>
              <button
                type="button"
                className={tabActive === 'DETAILS' ? 'active' : ''}
                onClick={() => handleSelectTabActive('DETAILS')}
              >
                Detalhes
              </button>

              <button
                type="button"
                className={tabActive === 'OPTIONS' ? 'active' : ''}
                onClick={() => handleSelectTabActive('OPTIONS')}
              >
                Opções nos produtos
              </button>

              <button
                type="button"
                className={tabActive === 'AVAILABILITY' ? 'active' : ''}
                onClick={() => handleSelectTabActive('AVAILABILITY')}
              >
                Disponibilidade
              </button>
            </S.Options>
          )}
        </S.Header>

        {categoryType ? (
          <>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              {tabActive === 'DETAILS' ? (
                <>
                  <S.Card>
                    <div>
                      <img
                        src={
                          categoriesTypes.filter(
                            (type) => type.type === categoryType
                          )[0].image
                        }
                        alt={
                          categoriesTypes.filter(
                            (type) => type.type === categoryType
                          )[0].type
                        }
                      />

                      <div>
                        <h1>
                          {
                            categoriesTypes.filter(
                              (type) => type.type === categoryType
                            )[0].title
                          }
                        </h1>
                        <p>
                          {
                            categoriesTypes.filter(
                              (type) => type.type === categoryType
                            )[0].subtitle
                          }
                        </p>
                      </div>
                    </div>

                    <button type="button" onClick={() => setCategoryType(null)}>
                      Alterar
                    </button>
                  </S.Card>

                  <S.Col>
                    <ImageInput
                      setImage={setImage}
                      previewImage={previewImage}
                      setPreviewImage={setPreviewImage}
                    />
                  </S.Col>

                  <S.Col>
                    <S.Label htmlFor="name">Nome da categoria</S.Label>
                    <S.Input
                      id="name"
                      name="name"
                      placeholder="Ex: Promoção"
                      className={errors.name && 'is-invalid'}
                      {...register('name')}
                    />
                  </S.Col>

                  {categoryType === 'PIZZAS' && (
                    <SizesCategoryCard
                      categorySizes={categorySizes}
                      setCategorySizes={setCategorySizes}
                    />
                  )}
                </>
              ) : tabActive === 'OPTIONS' ? (
                <Options
                  optionGroup={optionGroup}
                  setOptionGroup={setOptionGroup}
                />
              ) : (
                <>
                  <S.Col>
                    <S.Label>Disponibilidade</S.Label>

                    <S.RadioGroup>
                      <label>
                        <input
                          name="availabilityType"
                          type="radio"
                          value="ALWAYS"
                          onChange={handleAvailabilityRadio}
                          defaultChecked={availability === 'ALWAYS'}
                        />
                        <span>
                          <div>
                            <div>Sempre Disponível</div>
                            <div>
                              O item ficará disponível sempre que o restaurante
                              estiver aberto
                            </div>
                          </div>
                        </span>
                      </label>
                      <label>
                        <input
                          name="availabilityType"
                          type="radio"
                          value="SHIFTS"
                          onChange={handleAvailabilityRadio}
                          defaultChecked={availability === 'SHIFTS'}
                        />
                        <span>
                          <div>
                            <div>Disponível em dias e horários específicos</div>
                            <div>
                              Selecione os períodos em que o item ficará
                              disponível no aplicativo
                            </div>
                          </div>
                        </span>
                      </label>
                    </S.RadioGroup>
                  </S.Col>
                </>
              )}

              <S.Buttons>
                <S.Button
                  type="button"
                  cancel
                  onClick={() => handleToggleModal(null)}
                >
                  Cancelar
                </S.Button>

                <S.Button
                  type="submit"
                  disabled={
                    isLoading ||
                    (categoryType === 'PIZZAS' &&
                      (categorySizes.length <= 0 ||
                        !categorySizes.every(
                          (item) => item.name.length > 0
                        ))) ||
                    previewName === undefined ||
                    previewName?.trim().length === 0
                  }
                >
                  Salvar
                </S.Button>
              </S.Buttons>
            </S.Form>
          </>
        ) : (
          <SelectCategoryTypeCard
            categoriesTypes={categoriesTypes}
            handleSelectCategoryType={handleSelectCategoryType}
            categoryType={categoryType}
          />
        )}
      </S.Content>
    </S.Container>
  )
}

export default CategoryModal
