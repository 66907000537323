import { useSite } from 'contexts/SiteContext'
import React, { Fragment, useContext, useState } from 'react'
import { MdClose } from 'react-icons/md'
import 'react-responsive-modal/styles.css'
import { ThemeContext } from 'styled-components'

import type {
  OrderProps,
  PaymentMethodProps,
  PaymentMethodsProps
} from 'utils/props'

import { api } from 'services/api'
import * as S from './styles'

interface Props {
  handleClose: (orderValue?: OrderProps) => void
  isOpen: boolean
  order: OrderProps
  setOrder: (newState: OrderProps) => void
  setOrders: (newState: (prevState: OrderProps[]) => OrderProps[]) => void
}

const PaymentModal: React.FC<Props> = ({
  handleClose,
  isOpen,
  order,
  setOrder,
  setOrders
}) => {
  const { site } = useSite()
  const themeContext = useContext(ThemeContext)

  const [isLoading, setIsLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsProps[]>(
    order.payments.methods ?? []
  )

  const handleChangePayments = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const paymentMethod = JSON.parse(e.target.value) as PaymentMethodProps

    const newPaymentData = {
      id: paymentMethod.id,
      method: paymentMethod.name,
      change: 0,
      value: paymentMethods[0].value,
      currency: 'BRL',
      haveChangeOption: paymentMethod.haveChangeOption,
      type: 'OFFLINE'
    }

    if (
      paymentMethod.id === 'Cartão de Crédito' ||
      paymentMethod.id === 'Pix' ||
      paymentMethod.id === 'PicPay'
    ) {
      newPaymentData.type = 'ONLINE'
    }

    setPaymentMethods([newPaymentData])
  }

  const handleChangeOptionChange = (_, maskedValue: number) => {
    const newPaymentData = {
      ...paymentMethods[0],
      change: maskedValue
    }

    setPaymentMethods([newPaymentData])
  }

  const handleSubmitPaymentMethod = async () => {
    setIsLoading(true)

    try {
      const newOrder = {
        ...order,
        payments: {
          ...order.payments,
          methods: paymentMethods
        }
      }

      setOrder(newOrder)

      setOrders((prevState: OrderProps[]) =>
        prevState.map((item) => {
          if (item.id === order.id) {
            return newOrder
          }

          return item
        })
      )

      await api('user-api').put(`orders/${order.id}/payment`, {
        payments: {
          ...order.payments,
          methods: paymentMethods
        }
      })
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleClose(null)}
      center
      styles={{
        modal: {
          padding: '2rem',
          background: themeContext.colors.primary,
          borderRadius: '1.5rem'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <div className="left">
            <S.Title>Editar formas de pagamento</S.Title>
          </div>

          <div className="right">
            <button
              type="button"
              title="Fechar"
              onClick={() => handleClose(null)}
            >
              <MdClose />
            </button>
          </div>
        </S.Header>

        <S.Box>
          <div>
            <S.Title>Formas de pagamentos</S.Title>

            {paymentMethods.map((paymentMethod, index) => (
              <Fragment key={index}>
                <S.Select id="payments" onChange={handleChangePayments}>
                  {site.paymentMethods.paymentMethods.length > 0 && (
                    <optgroup label="Selecione uma forma de pagamento">
                      {site.paymentMethods.paymentMethods
                        .filter((payment) => payment.available)
                        .map((payment) => (
                          <option
                            key={payment.id}
                            selected={payment.id == paymentMethod.id}
                            value={JSON.stringify(payment)}
                          >
                            {payment.name}
                          </option>
                        ))}
                    </optgroup>
                  )}
                </S.Select>

                {paymentMethod && paymentMethod.haveChangeOption && (
                  <S.Payment>
                    <S.Col>
                      <S.Label htmlFor="changeOption">
                        Troco para quanto?
                      </S.Label>
                      <S.InputMoney
                        id="changeOption"
                        name="changeOption"
                        money="true"
                        max={10000}
                        currency="BRL"
                        placeholder="Digite aqui"
                        config={{
                          locale: 'pt-BR',
                          formats: {
                            number: {
                              BRL: {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            }
                          }
                        }}
                        value={paymentMethod.change}
                        onChange={handleChangeOptionChange}
                      />
                      {paymentMethod.haveChangeOption &&
                        paymentMethod.change > 0 &&
                        paymentMethod.change <= paymentMethod.value && (
                          <S.Error>
                            O valor do troco deve ser maior que o total
                          </S.Error>
                        )}
                    </S.Col>
                  </S.Payment>
                )}
                <S.ButtonGreen
                  type="button"
                  className="green"
                  onClick={() => handleSubmitPaymentMethod()}
                  disabled={isLoading}
                >
                  {isLoading ? 'Carregando...' : 'Salvar'}
                </S.ButtonGreen>
              </Fragment>
            ))}
          </div>
        </S.Box>
      </S.Content>
    </S.Container>
  )
}

export default PaymentModal
