import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.main`
  display: flex;

  padding-left: 64px;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`

export const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 2rem;

  ${media.lessThan('medium')`
    min-height: 100vh;
  `}

  ${media.greaterThan('medium')`
    padding: 4rem;
  `}
`
