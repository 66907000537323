import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav'
import { ArrowsLeftRight } from 'phosphor-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useAuth } from 'contexts/UserContext'
import { useWindowSize } from 'hooks/useWindowSize'
import routes from 'routes/routes'
import { logout } from 'services/auth'

import SidebarMobile from './SidebarMobile'

import * as S from './styles'

interface Props {
  logo?: string
  title?: string
  layout: string

  isSidebarOpen: boolean
  handleCloseSidebar(): void
}

const Sidebar: React.FC<Props> = ({
  logo = null,
  title = null,
  layout,
  isSidebarOpen,
  handleCloseSidebar
}) => {
  const location = useLocation()
  const { user } = useAuth()
  const [expanded, setExpanded] = useState(false)
  const history = useHistory()
  const [width] = useWindowSize()

  const getModuleName = useCallback(
    () => location.pathname.split('services/').pop(),
    [location]
  )

  const handlerExpand = useCallback(
    (value) => () => {
      setExpanded(value)
    },
    []
  )

  const handleOnSelect = useCallback(
    (selected) => {
      if (location.pathname !== selected) {
        history.push(selected)
      }
    },
    [history, location.pathname]
  )

  const handleNavigateToServices = () => history.push('/services/')

  const handleLogout = () => {
    logout()
    history.push('/auth/login/')
  }

  const navItems = useMemo(() => {
    return routes[layout].flat().map((prop) => {
      if (typeof prop.icon === 'string') {
        return (
          <NavItem
            className="sidebar-icon"
            key={prop.path}
            eventKey={`/${layout}${prop.path}`}
            active={[
              `/${layout}${prop.path}`,
              `/${layout}${prop.path}/`
            ].includes(location.pathname)}
          >
            <NavIcon>
              <div className="custom-sidebar-icon">
                <img src={prop.icon} alt={prop.name} />
              </div>
            </NavIcon>
            <NavText>
              <div className="custom-sidebar-text">
                {prop.name}

                {prop.new && (
                  <div className="new-tag">
                    <span>NOVO</span>
                  </div>
                )}
              </div>
            </NavText>
          </NavItem>
        )
      }

      const Icon = prop.icon

      return (
        <NavItem
          className="sidebar-icon"
          key={prop.path}
          eventKey={`/${layout}${prop.path}`}
          active={[
            `/${layout}${prop.path}`,
            `/${layout}${prop.path}/`
          ].includes(location.pathname)}
        >
          <NavIcon>
            <div className="custom-sidebar-icon">
              <Icon size={24} />
            </div>
          </NavIcon>
          <NavText>
            <div className="custom-sidebar-text">
              {prop.name}

              {prop.new && (
                <div className="new-tag">
                  <span>NOVO</span>
                </div>
              )}
            </div>
          </NavText>
        </NavItem>
      )
    })
  }, [layout, location.pathname])

  return (
    <S.Container>
      {width > 768 ? (
        <SideNav
          onMouseLeave={handlerExpand(false)}
          onMouseEnter={handlerExpand(true)}
          expanded={expanded}
          onToggle={handlerExpand(true)}
          className="d-none d-md-block sidebar-body"
          onSelect={handleOnSelect}
        >
          <SideNav.Toggle />
          <div className="logo">
            <img
              src={logo || '/favicon.png'}
              alt="Açaí Comandante"
              draggable={false}
            />
            <img
              src={logo || '/favicon.png'}
              alt="Açaí Comandante"
              draggable={false}
            />
          </div>

          <SideNav.Nav defaultSelected={`/${layout}/${getModuleName()}`}>
            {!location.pathname.includes('/services') && user.user && (
              <NavItem
                className="sidebar-icon"
                onClick={handleNavigateToServices}
              >
                <NavIcon>
                  <div className="custom-sidebar-icon">
                    <ArrowsLeftRight size={24} />
                  </div>
                </NavIcon>
                <NavText>
                  <div className="custom-sidebar-text">
                    Voltar para os serviços
                  </div>
                </NavText>
              </NavItem>
            )}

            <br />

            {navItems}

            <br />
            <NavItem className="sidebar-icon" onClick={handleLogout}>
              <NavIcon>
                <div className="custom-sidebar-icon">
                  <img src="/img/icons/sidebar/person.svg" alt="Sair" />
                </div>
              </NavIcon>
              <NavText>
                <div className="custom-sidebar-text">Sair</div>
              </NavText>
            </NavItem>
          </SideNav.Nav>
        </SideNav>
      ) : (
        <SidebarMobile
          logo={logo}
          title={title}
          layout={layout}
          isSidebarOpen={isSidebarOpen}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </S.Container>
  )
}

export default Sidebar
