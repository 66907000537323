import React, { useCallback, useState } from 'react'
import { ColorResult, SketchPicker } from 'react-color'
import { GradientPickerPopover } from 'react-linear-gradient-picker'
import 'react-linear-gradient-picker/dist/index.css'

import { api } from 'services/api'
import { useSite } from 'contexts/SiteContext'
import { useModule } from 'contexts/ModuleContext'
import { useToast } from 'contexts/ToastContext'

import * as S from './styles'

const rgbToRgba = (rgb, a = 1) =>
  rgb.replace('rgb(', 'rgba(').replace(')', `, ${a})`)

const ColorsModule: React.FC = () => {
  const { site } = useSite()
  const { module, setModule } = useModule()
  const { showToast } = useToast()

  const [isOpen, setIsOpen] = useState({
    primary: false,
    delivery: false,
    takeout: false,
    indoor: false
  })
  const [previewColors, setPreviewColors] = useState(module.colorsModule.site)

  const handleChangeMode = (
    color: 'primary' | 'delivery' | 'takeout' | 'indoor',
    mode: 'solid' | 'gradient'
  ) => {
    const modeData = mode === 'solid' ? 'gradient' : 'solid'

    const data =
      color === 'primary' ? previewColors[color] : previewColors.cards[color]

    const colorData =
      mode === 'solid'
        ? [
            {
              ...data.colors[0],
              offset: 0
            },
            {
              ...data.colors[0],
              offset: 1
            }
          ]
        : [{ ...data.colors[0] }]

    if (color === 'primary') {
      setPreviewColors((prevState) => ({
        ...prevState,
        [color]: {
          ...prevState[color],
          colors: colorData,
          mode: modeData,
          angle: data.angle || 90
        }
      }))
    } else {
      setPreviewColors((prevState) => ({
        ...prevState,
        cards: {
          ...prevState.cards,
          [color]: {
            ...prevState.cards[color],
            colors: colorData,
            mode: modeData,
            angle: data.angle || 90
          }
        }
      }))
    }
  }

  const handleOpenMenu = (
    color: 'primary' | 'delivery' | 'takeout' | 'indoor',
    isOpen: boolean
  ) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [color]: isOpen
    }))
  }

  const handleChangeColors = (
    color: 'primary' | 'delivery' | 'takeout' | 'indoor',
    mode: 'solid' | 'gradient',
    result: ColorResult
  ) => {
    const colors =
      color === 'primary'
        ? previewColors[color].colors
        : previewColors.cards[color].colors
    let data

    if (mode === 'gradient') {
      const activeIndex = result.findIndex(
        (cr) => cr.active && typeof cr.color !== 'object'
      )

      if (activeIndex >= 0) {
        data = colors.map((c, index) => {
          if (index === activeIndex) {
            return {
              ...c,
              offset: result[activeIndex].offset
            }
          }

          return c
        })
      } else {
        const colorIndex = result.findIndex(
          (cr) => typeof cr.color === 'object'
        )

        data = colors.map((c, index) => {
          if (index === colorIndex) {
            const { color, offset } = result[colorIndex]

            return {
              ...color,
              offset
            }
          }

          return c
        })
      }
    } else {
      const { hex, rgb } = result

      data = [
        {
          hex: hex,
          rgba: {
            r: rgb.r,
            g: rgb.g,
            b: rgb.b,
            a: rgb.a
          }
        }
      ]
    }

    if (color === 'primary') {
      setPreviewColors((prevState) => ({
        ...prevState,
        [color]: {
          ...prevState[color],
          colors: data
        }
      }))
    } else {
      setPreviewColors((prevState) => ({
        ...prevState,
        cards: {
          ...prevState.cards,
          [color]: {
            ...prevState.cards[color],
            colors: data
          }
        }
      }))
    }
  }

  const handleChangeAngle = (
    color: 'primary' | 'delivery' | 'takeout' | 'indoor',
    result: number
  ) => {
    if (color === 'primary') {
      setPreviewColors((prevState) => ({
        ...prevState,
        [color]: {
          ...prevState[color],
          angle: result
        }
      }))
    } else {
      setPreviewColors((prevState) => ({
        ...prevState,
        cards: {
          ...prevState.cards,
          [color]: {
            ...prevState.cards[color],
            angle: result
          }
        }
      }))
    }
  }

  const WrappedSketchPicker = ({ onSelect, ...rest }) => {
    return (
      <SketchPicker
        {...rest}
        color={rgbToRgba(rest.color, rest.opacity)}
        onChange={(result) => {
          const { hex, rgb } = result

          onSelect({
            hex: hex,
            rgba: {
              r: rgb.r,
              g: rgb.g,
              b: rgb.b,
              a: rgb.a
            }
          })
          // onSelect(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`, rgb.a)
        }}
      />
    )
  }

  const handleUpdateColors = useCallback(async () => {
    try {
      const { data: response } = await api('user-api').put(
        `/modules/colors/${module.colorsModule.id}`,
        {
          site: previewColors
        }
      )

      setModule({
        ...module,
        colorsModule: response
      })

      showToast({
        message: 'Módulo atualizado com sucesso!',
        type: 'success'
      })
    } catch (err) {
      showToast({
        message: 'Aconteceu um erro!',
        type: 'error'
      })
    }
  }, [module, previewColors, setModule, showToast])

  return (
    <S.Container>
      <S.Title>
        <h1>Cores</h1>

        <S.Button type="button" onClick={handleUpdateColors}>
          Salvar
        </S.Button>
      </S.Title>

      <S.Content>
        <S.Cards>
          <S.Card>
            <div className="change-mode">
              <span>Primária</span>

              <S.Button
                type="button"
                disabled
                onClick={() =>
                  handleChangeMode('primary', previewColors.primary.mode)
                }
              >
                {previewColors.primary.mode === 'solid'
                  ? 'Cor sólida'
                  : 'Gradiente'}
              </S.Button>
            </div>

            {previewColors.primary.mode === 'solid' ? (
              <>
                <div
                  className="swatch"
                  onClick={() => handleOpenMenu('primary', !isOpen.primary)}
                >
                  <div
                    className="color"
                    style={{
                      background: `
                        rgba(
                          ${previewColors.primary.colors[0].rgba.r}, 
                          ${previewColors.primary.colors[0].rgba.g}, 
                          ${previewColors.primary.colors[0].rgba.b}, 
                          ${previewColors.primary.colors[0].rgba.a}
                        )
                      `
                    }}
                  />
                </div>
                {isOpen.primary ? (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() => handleOpenMenu('primary', !isOpen.primary)}
                    />
                    <SketchPicker
                      color={previewColors.primary.colors[0].hex}
                      onChange={(result) =>
                        handleChangeColors(
                          'primary',
                          previewColors.primary.mode,
                          result
                        )
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <GradientPickerPopover
                {...{
                  open: isOpen.primary,
                  setOpen: (open: boolean) => handleOpenMenu('primary', open),
                  angle: previewColors.primary.angle,
                  setAngle: (result: number) =>
                    handleChangeAngle('primary', result),
                  showAnglePicker: true,
                  width: 220,
                  maxStops: 2,
                  paletteHeight: 32,
                  palette: previewColors.primary.colors.map((color) => {
                    return {
                      offset: color.offset,
                      color: `rgb(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b})`
                    }
                  }),
                  onPaletteChange: (e) =>
                    handleChangeColors('primary', previewColors.primary.mode, e)
                }}
              >
                <WrappedSketchPicker />
              </GradientPickerPopover>
            )}
          </S.Card>

          <S.Card>
            <div className="change-mode">
              <span>Botão de entrega</span>

              <S.Button
                type="button"
                onClick={() =>
                  handleChangeMode(
                    'delivery',
                    previewColors.cards.delivery.mode
                  )
                }
              >
                {previewColors.cards.delivery.mode === 'solid'
                  ? 'Cor sólida'
                  : 'Gradiente'}
              </S.Button>
            </div>

            {previewColors.cards.delivery.mode === 'solid' ? (
              <>
                <div
                  className="swatch"
                  onClick={() => handleOpenMenu('delivery', !isOpen.delivery)}
                >
                  <div
                    className="color"
                    style={{
                      background: `
                        rgba(
                          ${previewColors.cards.delivery.colors[0].rgba.r}, 
                          ${previewColors.cards.delivery.colors[0].rgba.g}, 
                          ${previewColors.cards.delivery.colors[0].rgba.b}, 
                          ${previewColors.cards.delivery.colors[0].rgba.a}
                        )
                      `
                    }}
                  />
                </div>

                {isOpen.delivery ? (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() =>
                        handleOpenMenu('delivery', !isOpen.delivery)
                      }
                    />
                    <SketchPicker
                      color={previewColors.cards.delivery.colors[0].hex}
                      onChange={(result) =>
                        handleChangeColors(
                          'delivery',
                          previewColors.cards.delivery.mode,
                          result
                        )
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <GradientPickerPopover
                {...{
                  open: isOpen.delivery,
                  setOpen: (open: boolean) => handleOpenMenu('delivery', open),
                  angle: previewColors.cards.delivery.angle,
                  setAngle: (result: number) =>
                    handleChangeAngle('delivery', result),
                  showAnglePicker: true,
                  width: 220,
                  maxStops: 2,
                  paletteHeight: 32,
                  palette: previewColors.cards.delivery.colors.map((color) => {
                    return {
                      offset: color.offset,
                      color: `rgb(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b})`
                    }
                  }),
                  onPaletteChange: (e) =>
                    handleChangeColors(
                      'delivery',
                      previewColors.cards.delivery.mode,
                      e
                    )
                }}
              >
                <WrappedSketchPicker />
              </GradientPickerPopover>
            )}
          </S.Card>

          <S.Card>
            <div className="change-mode">
              <span>Botão de retirada</span>

              <S.Button
                type="button"
                onClick={() =>
                  handleChangeMode('takeout', previewColors.cards.takeout.mode)
                }
              >
                {previewColors.cards.takeout.mode === 'solid'
                  ? 'Cor sólida'
                  : 'Gradiente'}
              </S.Button>
            </div>

            {previewColors.cards.takeout.mode === 'solid' ? (
              <>
                <div
                  className="swatch"
                  onClick={() => handleOpenMenu('takeout', !isOpen.takeout)}
                >
                  <div
                    className="color"
                    style={{
                      background: `
                        rgba(
                          ${previewColors.cards.takeout.colors[0].rgba.r}, 
                          ${previewColors.cards.takeout.colors[0].rgba.g}, 
                          ${previewColors.cards.takeout.colors[0].rgba.b}, 
                          ${previewColors.cards.takeout.colors[0].rgba.a}
                        )
                      `
                    }}
                  />
                </div>

                {isOpen.takeout ? (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() => handleOpenMenu('takeout', !isOpen.takeout)}
                    />
                    <SketchPicker
                      color={previewColors.cards.takeout.colors[0].hex}
                      onChange={(result) =>
                        handleChangeColors(
                          'takeout',
                          previewColors.cards.takeout.mode,
                          result
                        )
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <GradientPickerPopover
                {...{
                  open: isOpen.takeout,
                  setOpen: (open: boolean) => handleOpenMenu('takeout', open),
                  angle: previewColors.cards.takeout.angle,
                  setAngle: (result: number) =>
                    handleChangeAngle('takeout', result),
                  showAnglePicker: true,
                  width: 220,
                  maxStops: 2,
                  paletteHeight: 32,
                  palette: previewColors.cards.takeout.colors.map((color) => {
                    return {
                      offset: color.offset,
                      color: `rgb(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b})`
                    }
                  }),
                  onPaletteChange: (e) =>
                    handleChangeColors(
                      'takeout',
                      previewColors.cards.takeout.mode,
                      e
                    )
                }}
              >
                <WrappedSketchPicker />
              </GradientPickerPopover>
            )}
          </S.Card>

          <S.Card>
            <div className="change-mode">
              <span>Botão da mesa</span>

              <S.Button
                type="button"
                onClick={() =>
                  handleChangeMode('indoor', previewColors.cards.indoor.mode)
                }
              >
                {previewColors.cards.indoor.mode === 'solid'
                  ? 'Cor sólida'
                  : 'Gradiente'}
              </S.Button>
            </div>

            {previewColors.cards.indoor.mode === 'solid' ? (
              <>
                <div
                  className="swatch"
                  onClick={() => handleOpenMenu('indoor', !isOpen.indoor)}
                >
                  <div
                    className="color"
                    style={{
                      background: `
                        rgba(
                          ${previewColors.cards.indoor.colors[0].rgba.r}, 
                          ${previewColors.cards.indoor.colors[0].rgba.g}, 
                          ${previewColors.cards.indoor.colors[0].rgba.b}, 
                          ${previewColors.cards.indoor.colors[0].rgba.a}
                        )
                      `
                    }}
                  />
                </div>

                {isOpen.indoor ? (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() => handleOpenMenu('indoor', !isOpen.indoor)}
                    />
                    <SketchPicker
                      color={previewColors.cards.indoor.colors[0].hex}
                      onChange={(result) =>
                        handleChangeColors(
                          'indoor',
                          previewColors.cards.indoor.mode,
                          result
                        )
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <GradientPickerPopover
                {...{
                  open: isOpen.indoor,
                  setOpen: (open: boolean) => handleOpenMenu('indoor', open),
                  angle: previewColors.cards.indoor.angle,
                  setAngle: (result: number) =>
                    handleChangeAngle('indoor', result),
                  showAnglePicker: true,
                  width: 220,
                  maxStops: 2,
                  paletteHeight: 32,
                  palette: previewColors.cards.indoor.colors.map((color) => {
                    return {
                      offset: color.offset,
                      color: `rgb(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b})`
                    }
                  }),
                  onPaletteChange: (e) =>
                    handleChangeColors(
                      'indoor',
                      previewColors.cards.indoor.mode,
                      e
                    )
                }}
              >
                <WrappedSketchPicker />
              </GradientPickerPopover>
            )}
          </S.Card>
        </S.Cards>

        <S.MobileFrame>
          <img
            draggable={false}
            src="https://io13-high-dpi.appspot.com/images/n4_large.png"
          />
          <div className="frame-box">
            <iframe
              title={site.title}
              src={`https://testes-guilhermekayan.vercel.app/${site.slug}`}
              frameBorder="0"
              style={{ overflow: 'hidden' }}
            ></iframe>
          </div>
        </S.MobileFrame>
      </S.Content>
    </S.Container>
  )
}

export default ColorsModule
