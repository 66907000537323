import React, { createContext, useState, useEffect, useContext } from 'react'
import qz from 'qz-tray'
import { KJUR, KEYUTIL, stob64, hextorstr } from 'jsrsasign'

interface QzTrayProviderProps {
  children: React.ReactNode
}

interface QzTrayContextData {
  qz: any
  printers: string[]
}

const QzTrayContext = createContext({} as QzTrayContextData)

const QzTrayProvider: React.FC<QzTrayProviderProps> = ({ children }) => {
  const [listPrinters, setListPrinters] = useState([])

  useEffect(() => {
    try {
      qz.security.setCertificatePromise((resolve) => {
        fetch('/signing/override.crt', {
          cache: 'no-store',
          headers: { 'Content-Type': 'text/plain' }
        }).then((data) => resolve(data.text()))
      })

      qz.security.setSignatureAlgorithm('SHA512')
      qz.security.setSignaturePromise((hash) => (resolve) => {
        fetch('/signing/key.pem', {
          cache: 'no-store',
          headers: { 'Content-Type': 'text/plain' }
        })
          .then((wrapped) => wrapped.text())
          .then((data) => {
            const pk = KEYUTIL.getKey(data)
            const sig = new KJUR.crypto.Signature({ alg: 'SHA512withRSA' })
            sig.init(pk)
            sig.updateString(hash)
            const hex = sig.sign()
            resolve(stob64(hextorstr(hex)))
          })
      })

      if (!qz.websocket.isActive()) {
        qz.websocket
          .connect({ retries: 5, delay: 1 })
          .then(() => {
            console.log('Active', 'success')
            qz?.printers?.find().then((printers) => setListPrinters(printers))
          })
          .catch((error) => {
            if (error.target !== undefined) {
              if (error.target.readyState >= 2) {
                console.log('Connection to QZ Tray was closed')
              } else {
                console.log(
                  'A connection error occurred, check log for details'
                )
                console.log(error)
              }
            } else {
              console.log(error)
            }
          })
      }
    } catch (err) {
      console.log(err)
    }

    return () => qz.websocket.disconnect()
  }, [])

  return (
    <QzTrayContext.Provider
      value={{
        qz,
        printers: listPrinters
      }}
    >
      {children}
    </QzTrayContext.Provider>
  )
}

function useQzTray(): QzTrayContextData {
  const context = useContext(QzTrayContext)

  if (!context) {
    throw new Error('useQzTray must be used within a QzTrayContext')
  }

  return context
}

export { QzTrayContext, QzTrayProvider, useQzTray }
