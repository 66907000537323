import React, { useContext, useCallback } from 'react'
import { ThemeContext } from 'styled-components'

import { CategoryProps, ItemProps, SizeProductProps } from 'utils/props'

import Sizes from './Sizes'

import * as S from './styles'

interface PriceShiftProps {
  weekday: string
  dayOfWeek: number

  value?: {
    currency: string
    value: number
  }
  available: boolean
}

interface Props {
  category: CategoryProps
  product: ItemProps

  haveSizes: boolean
  setHaveSizes(haveSizes: boolean): void
  sizes: SizeProductProps[]
  setSizes(sizes: SizeProductProps[]): void

  prices: any
  priceCostValue: number

  setPriceCostValue(priceCostValue: number): void
  priceOriginalValue: number
  setPriceOriginalValue(priceOriginalValue: number): void
  setPrices(prices: any): void
  isDiscounted: boolean
  setIsDiscounted(isDiscounted: boolean): void
  priceDiscountValue: number
  setPriceDiscountValue(priceDiscountValue: number): void

  priceFromValue: null | number
  setPriceFromValue(priceFromValue: null | number): void

  priceShiftsValue: PriceShiftProps[]
  setPriceShiftsValue(priceShiftsValue: PriceShiftProps[]): void
}

const Price: React.FC<Props> = ({
  category,
  product,
  haveSizes,
  setHaveSizes,
  sizes,
  setSizes,
  prices,
  setPrices,
  priceCostValue,
  setPriceCostValue,
  priceOriginalValue,
  setPriceOriginalValue,
  isDiscounted,
  setIsDiscounted,
  priceDiscountValue,
  setPriceDiscountValue,

  priceFromValue,
  setPriceFromValue,

  priceShiftsValue,
  setPriceShiftsValue
}) => {
  const themeContext = useContext(ThemeContext)

  const handleHaveSizes = () => {
    setHaveSizes((prevState) => !prevState)
  }

  const handlePriceFromActive = useCallback(() => {
    setPriceFromValue(priceFromValue === null ? 0 : null)
  }, [priceFromValue, setPriceFromValue])

  const handlePriceShiftActive = useCallback(() => {
    setPriceShiftsValue(
      priceShiftsValue === null
        ? [
            {
              weekday: 'Domingo',
              dayOfWeek: 0,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            },
            {
              weekday: 'Segunda',
              dayOfWeek: 1,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            },
            {
              weekday: 'Terça',
              dayOfWeek: 2,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            },
            {
              weekday: 'Quarta',
              dayOfWeek: 3,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            },
            {
              weekday: 'Quinta',
              dayOfWeek: 4,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            },
            {
              weekday: 'Sexta',
              dayOfWeek: 5,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            },
            {
              weekday: 'Sábado',
              dayOfWeek: 6,
              value: {
                currency: 'BRL',
                value: 0
              },
              available: true
            }
          ]
        : null
    )
  }, [priceShiftsValue, setPriceShiftsValue])

  const handleDiscountActive = useCallback(() => {
    setIsDiscounted(!isDiscounted)
  }, [isDiscounted, setIsDiscounted])

  const handleChangePriceCostValue = useCallback(
    (_, maskedValue: number) => {
      setPriceCostValue(maskedValue)
    },
    [setPriceCostValue]
  )

  const handleChangePriceFromValue = useCallback(
    (_, maskedValue: number) => {
      setPriceFromValue(maskedValue)
    },
    [setPriceFromValue]
  )

  const handleChangePriceOriginalValue = useCallback(
    (_, maskedValue: number) => {
      setPriceDiscountValue(maskedValue)
      setPriceOriginalValue(maskedValue)
    },
    [setPriceDiscountValue, setPriceOriginalValue]
  )

  const handleChangePriceDiscountValue = useCallback(
    (_, maskedValue: number) => {
      setPriceDiscountValue(maskedValue)
    },
    [setPriceDiscountValue]
  )

  const handleChangePricePizzaValue = useCallback(
    (id: string, maskedValue: number) => {
      setPrices(
        prices.map((size) => {
          if (size.sizeId === id) {
            return { ...size, price: { ...size.price, value: maskedValue } }
          }

          return size
        })
      )
    },
    [prices, setPrices]
  )

  // SHIFTS //
  const handleChangeStatusDay = useCallback(
    (dayOfWeek: number) => {
      const updatedPriceShifts = priceShiftsValue.map((item) => {
        if (item.dayOfWeek === dayOfWeek) {
          return { ...item, available: !item.available }
        }

        return item
      })

      setPriceShiftsValue(updatedPriceShifts)
    },
    [priceShiftsValue, setPriceShiftsValue]
  )

  const handleChangeShiftPriceOriginalValue = useCallback(
    (maskedValue: number, dayOfWeek: number) => {
      const updatedPriceShifts = priceShiftsValue.map((item) => {
        if (item.dayOfWeek === dayOfWeek) {
          return {
            ...item,
            value: {
              ...item.value,
              value: maskedValue
            }
          }
        }

        return item
      })

      setPriceShiftsValue(updatedPriceShifts)
    },
    [priceShiftsValue, setPriceShiftsValue]
  )

  return (
    <S.Container>
      {category.type !== 'PIZZAS' && (
        <S.Options>
          <S.ColWrapper>
            <S.Col>
              <S.Label htmlFor="haveSizes">O produto tem tamanhos?</S.Label>

              <S.Switch
                id="haveSizes"
                onChange={handleHaveSizes}
                checked={haveSizes}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={60}
                handleDiameter={12}
                offColor={themeContext.colors.red}
                onColor={themeContext.colors.green}
              />
            </S.Col>
          </S.ColWrapper>

          {!haveSizes && (
            <S.ColWrapper>
              <S.Col>
                <S.Label htmlFor="priceFrom">A partir de</S.Label>

                <S.Switch
                  id="priceFrom"
                  disabled={priceShiftsValue !== null}
                  onChange={handlePriceFromActive}
                  checked={priceFromValue !== null}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />
              </S.Col>

              <S.Col>
                <S.Label htmlFor="priceShift">Preço por dia</S.Label>

                <S.Switch
                  id="priceShift"
                  disabled={priceFromValue !== null}
                  onChange={handlePriceShiftActive}
                  checked={priceShiftsValue !== null}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={60}
                  handleDiameter={12}
                  offColor={themeContext.colors.red}
                  onColor={themeContext.colors.green}
                />
              </S.Col>
            </S.ColWrapper>
          )}
        </S.Options>
      )}

      {category.type === 'PIZZAS' ? (
        <S.PriceSizePrice>
          <h1>Preços</h1>

          <div>
            {category.sizes?.map((size) => {
              let lastSize = 1

              if (size.multipleToppingsSizeIds.fourToppingsSizeId) {
                lastSize = 4
              } else if (size.multipleToppingsSizeIds.threeToppingsSizeId) {
                lastSize = 3
              } else if (size.multipleToppingsSizeIds.twoToppingsSizeId) {
                lastSize = 2
              }

              const sizeInfo = prices.filter(
                (item) => item.sizeId === size.id
              )[0]

              const price = sizeInfo?.price?.value || 0

              return (
                <div key={size.id}>
                  <img
                    src={`/img/icons/menu/pizzas/${lastSize}.svg`}
                    alt={`${size.name} (${lastSize} ${
                      lastSize > 1 ? 'sabores' : 'sabor'
                    })`}
                    draggable={false}
                  />

                  <S.Label htmlFor={size.id}>{size.name}</S.Label>

                  <S.InputMoney
                    id={size.id}
                    money
                    currency="BRL"
                    disabled={isDiscounted}
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        }
                      }
                    }}
                    value={price}
                    onChange={(_, maskedValue: number) =>
                      handleChangePricePizzaValue(size.id, maskedValue)
                    }
                  />
                </div>
              )
            })}
          </div>
        </S.PriceSizePrice>
      ) : !haveSizes ? (
        <>
          {priceShiftsValue !== null ? (
            <>
              <S.ListDays>
                <h1>Escolha os dias e horários</h1>

                <div className="day">
                  {priceShiftsValue.map((item) => (
                    <button
                      key={item.dayOfWeek}
                      type="button"
                      onClick={() => handleChangeStatusDay(item.dayOfWeek)}
                      className={item.available ? 'available' : ''}
                    >
                      {item.weekday.substring(0, 3)}
                    </button>
                  ))}
                </div>

                <S.ListDaysHelp>
                  <div>
                    <p></p>
                    <span>Item desativado no dia</span>
                  </div>

                  <div>
                    <p className="available"></p>
                    <span>Item ativado no dia</span>
                  </div>
                </S.ListDaysHelp>
              </S.ListDays>

              <S.Days>
                {priceShiftsValue
                  .filter((item) => item.available)
                  .map((item) => {
                    return (
                      <S.PriceDay key={item.dayOfWeek}>
                        <h1>{item.weekday}</h1>

                        <div>
                          <S.Price>
                            <S.Col>
                              <S.Label htmlFor={`price_${item.dayOfWeek}`}>
                                Preço (obrigatório)
                              </S.Label>

                              <S.InputMoney
                                id={`price_${item.dayOfWeek}`}
                                money
                                currency="BRL"
                                config={{
                                  locale: 'pt-BR',
                                  formats: {
                                    number: {
                                      BRL: {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }
                                    }
                                  }
                                }}
                                value={item.value.value}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>,
                                  maskedValue: number
                                ) =>
                                  handleChangeShiftPriceOriginalValue(
                                    maskedValue,
                                    item.dayOfWeek
                                  )
                                }
                              />
                            </S.Col>
                          </S.Price>
                        </div>
                      </S.PriceDay>
                    )
                  })}
              </S.Days>
            </>
          ) : priceFromValue !== null ? (
            <S.Price>
              <S.Col>
                <S.Label htmlFor="priceFromValue">Preço a partir de</S.Label>

                <S.InputMoney
                  id="priceFromValue"
                  money
                  currency="BRL"
                  config={{
                    locale: 'pt-BR',
                    formats: {
                      number: {
                        BRL: {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      }
                    }
                  }}
                  value={priceFromValue}
                  onChange={handleChangePriceFromValue}
                />
              </S.Col>
            </S.Price>
          ) : (
            <>
              <S.Price>
                <S.Col>
                  <S.Label htmlFor="cost">Preço de custo</S.Label>

                  <S.InputMoney
                    id="cost"
                    money
                    currency="BRL"
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        }
                      }
                    }}
                    value={priceCostValue}
                    onChange={handleChangePriceCostValue}
                  />
                </S.Col>
              </S.Price>

              <S.Price>
                <S.Col>
                  <S.Label htmlFor="price">
                    {isDiscounted ? 'Preço atual' : 'Preço (obrigatório)'}
                  </S.Label>

                  <S.InputMoney
                    id="price"
                    money
                    currency="BRL"
                    disabled={isDiscounted}
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        }
                      }
                    }}
                    value={priceOriginalValue}
                    onChange={handleChangePriceOriginalValue}
                  />
                </S.Col>

                {isDiscounted && (
                  <S.Col>
                    <S.Label htmlFor="newPrice">Novo preço</S.Label>

                    <S.InputMoney
                      id="newPrice"
                      money
                      currency="BRL"
                      config={{
                        locale: 'pt-BR',
                        formats: {
                          number: {
                            BRL: {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          }
                        }
                      }}
                      value={priceDiscountValue}
                      onChange={handleChangePriceDiscountValue}
                    />
                  </S.Col>
                )}

                <S.ButtonDiscount type="button" onClick={handleDiscountActive}>
                  {isDiscounted ? 'Remover desconto' : 'Aplicar desconto'}
                </S.ButtonDiscount>
              </S.Price>
            </>
          )}
        </>
      ) : (
        <Sizes product={product} sizes={sizes} setSizes={setSizes} />
      )}
    </S.Container>
  )
}

export default Price
